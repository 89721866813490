import {
  IonCheckbox,
  IonItem,
  IonLabel,
  IonText,
  IonToggle,
} from '@ionic/react';
import React, { useState } from 'react';
import style from './build-item-select.module.scss';

interface Item {
  name: string;

  [key: string]: any;
}

interface BuildGenresProps {
  items: Item[];
  onSelectedItemsChange: (selected: Item[]) => void;
  selectionType: 'toggle' | 'checkbox';
  limit?: number;
}

export default function BuildItemSelect({
  items,
  onSelectedItemsChange,
  selectionType,
  limit,
}: BuildGenresProps) {
  const [selectedItems, setSelectedItems] = useState<Item[]>([]);

  const hasReachedLimit = limit && selectedItems.length >= limit;

  return (
    <div style={{ height: '400px', overflowX: 'scroll' }}>
      {hasReachedLimit && (
        <IonText color="danger">Max selections has been reached.</IonText>
      )}
      {items.map((item, index) => (
        <IonItem key={index}>
          {selectionType === 'toggle' ? (
            <IonToggle
              slot="start"
              disabled={
                !!(
                  hasReachedLimit &&
                  !selectedItems.some((si) => si.name === item.name)
                )
              }
              onIonChange={(e) => handleSelectionChange(item, e.detail.checked)}
              className={style['build-item-option']}
              aria-label={item.name}
            />
          ) : (
            <IonCheckbox
              slot="start"
              disabled={
                !!(
                  hasReachedLimit &&
                  !selectedItems.some((si) => si.name === item.name)
                )
              }
              onIonChange={(e) => handleSelectionChange(item, e.detail.checked)}
              className={style['build-item-option']}
              aria-label={item.name}
            />
          )}
          <IonLabel>{item.name}</IonLabel>
        </IonItem>
      ))}
    </div>
  );

  function handleSelectionChange(item: Item, isChecked: boolean) {
    let newSelectedItems;

    if (isChecked) {
      if (limit && selectedItems.length >= limit) {
        return;
      }
      newSelectedItems = [...selectedItems, item];
    } else {
      newSelectedItems = selectedItems.filter((i) => i.name !== item.name);
    }

    setSelectedItems(newSelectedItems);
    onSelectedItemsChange(newSelectedItems);
  }
}
