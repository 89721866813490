import React, { useCallback, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { iTrack } from '../../types/ITrack';
import { TrackControllerRecommendationSearchRequest } from '@cratehackers/api-client';
import ApiService from '../../services/Api.service';
import { GridReadyEvent } from 'ag-grid-community';
import { setSelectedTrack } from '../../store/slices/track.slice';
import { useDispatch } from '../../store/hooks';
import { IonButton } from '@ionic/react';

import './Recommendations.scss';

interface RecommendationsProps extends iTrack {
  showAddTrack?: boolean;
  addTrackHandler?: (trackId: string) => void;
}

export const Recommendations: React.FC<RecommendationsProps> = (props) => {
  const { showAddTrack = false, addTrackHandler, ...track } = props;
  const [recommendations, setRecommendations] = useState<iTrack[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  const dispatch = useDispatch();

  interface IAgGridProp {
    value: string;
  }

  const handleAddTrack = useCallback(
    (value: string) => {
      props.addTrackHandler?.(value);
    },
    [props.addTrackHandler],
  );

  const keyCategoryOrder: {
    // TODO: Figure out why it's forcing upper camel
    [key in
      | 'PerfectMatch'
      | 'EnergyBoost'
      | 'EnergyDrop'
      | 'MoodChange'
      | 'Compatible']: number;
  } = {
    PerfectMatch: 1,
    EnergyBoost: 2,
    EnergyDrop: 3,
    MoodChange: 4,
    Compatible: 5,
  };

  const getKeyCategory = (
    key: string,
  ): keyof typeof keyCategoryOrder | null => {
    for (const category in track.keyMatches) {
      if (
        Array.isArray(track.keyMatches[category]) &&
        track.keyMatches[category].includes(key)
      ) {
        return category as keyof typeof keyCategoryOrder;
      }
    }
    return null;
  };

  const formatKeyCategory = (category: string) => {
    return category
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const KeyMatchCellRenderer: React.FC<IAgGridProp> = ({ value }) => {
    if (!value) {
      return null;
    }
    const keyCategory = getKeyCategory(value);
    return (
      <div className={'key'}>
        {value} &nbsp; {value.length === 2 && <>&nbsp;</>}
        {keyCategory && (
          <span className={`key-${keyCategory}`}>
            ({formatKeyCategory(keyCategory)})
          </span>
        )}
      </div>
    );
  };

  const AddTrackCellRenderer: React.FC<IAgGridProp> = ({ value }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <IonButton
          fill="clear"
          size="small"
          onClick={() => handleAddTrack(value)}
        >
          + Add
        </IonButton>
      </div>
    );
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.refreshCells({ force: true });
    }
  }, [recommendations, gridApi, track]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: '',
      field: 'ID',
      hide: !showAddTrack,
      cellRenderer: AddTrackCellRenderer,
      filter: false,
      sortable: false,
    },

    {
      field: 'Title',
      headerName: 'Title',
      filter: false,
      sortable: false,
    },
    {
      field: 'Artist',
      headerName: 'Artist',
      filter: false,
      sortable: false,
    },
    {
      field: 'BPM',
      headerName: 'BPM',
      sortable: true,
      filter: false,
    },
    {
      field: 'Key_Camelot',
      headerName: 'Key',
      filter: false,
      sortable: false,
      comparator: (
        valueA: string,
        valueB: string,
        nodeA: any,
        nodeB: any,
        isDescending: boolean,
      ) => {
        const categoryA = getKeyCategory(valueA);
        const categoryB = getKeyCategory(valueB);

        const orderA = categoryA ? keyCategoryOrder[categoryA] : 5; // Treat null as the highest order value
        const orderB = categoryB ? keyCategoryOrder[categoryB] : 5;

        return orderA - orderB;
      },
      sort: 'asc' as 'asc' | 'desc' | undefined,
      cellRenderer: KeyMatchCellRenderer,
    },
  ]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);

    setTimeout(() => {
      gridApi?.showLoadingOverlay();
    });
  };

  const cellClickedListener = (event: any) => {
    if (!showAddTrack) {
      dispatch(setSelectedTrack(event.data));
    }
  };

  useEffect(() => {
    const chApi = ApiService.getApiClient();
    const requestParams: TrackControllerRecommendationSearchRequest = {
      spotifySearchDto: {
        term: `${track.Title} ${track.Artist}`,
      },
    };
    chApi
      .trackControllerRecommendationSearch(requestParams)
      .then(async (response) => {
        const tracks = response
          .map(
            (responseTrack: any): iTrack => ({
              Artist: responseTrack.artists
                .map((artist: any) => artist.name)
                .join(', '),
              Title: responseTrack.name,
              AlbumCover: responseTrack.album.imageUrl,
              Key_Camelot: responseTrack.keys.camelot,
              Key: responseTrack.keys.standard,
              PreviewURL: responseTrack.previewUrl,
              BPM: Math.round(responseTrack.bpm),
              ID: responseTrack.id,
              popularity: responseTrack.popularity,
              keyMatches: {
                energyBoost: responseTrack.keys.energyBoost,
                energyDrop: responseTrack.keys.energyDrop,
                moodChange: responseTrack.keys.moodChange,
                perfectMatch: responseTrack.keys.perfectMatch,
              },
            }),
          )
          .filter(
            (filterTrack) =>
              `${filterTrack.Artist} ${filterTrack.Title}` !==
              `${track.Artist} ${track.Title}`,
          );
        if (
          (track.keyMatches === undefined ||
            track.keyMatches.energyBoost === undefined ||
            track.keyMatches.moodChange === undefined ||
            track.keyMatches.perfectMatch === undefined ||
            track.keyMatches.energyDrop === undefined ||
            track.keyMatches.compatible === undefined) &&
          track.Key_Camelot
        ) {
          track.keyMatches = await chApi.keysControllerGetKeyMatches({
            keyDto: {
              camelot: track.Key_Camelot,
              standard: '',
            },
          });
        }
        setRecommendations(tracks);
        if (gridApi) {
          gridApi?.sizeColumnsToFit('Artist', 'Title');
          gridApi?.hideOverlay();
        }
      });
  }, [track.Artist, track.Title, gridApi]);

  return (
    <>
      <h2>Recommendations</h2>
      <AgGridReact
        rowData={recommendations}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        onCellClicked={cellClickedListener}
        rowStyle={{ cursor: 'pointer' }}
        localeText={{ noRowsToShow: 'No Recommendations Found!' }}
        defaultColDef={{ sortable: true }}
      />
    </>
  );
};
