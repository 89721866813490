/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CrateSearchDto interface.
 */
export function instanceOfCrateSearchDto(value) {
    if (!('term' in value) || value['term'] === undefined)
        return false;
    if (!('offset' in value) || value['offset'] === undefined)
        return false;
    return true;
}
export function CrateSearchDtoFromJSON(json) {
    return CrateSearchDtoFromJSONTyped(json, false);
}
export function CrateSearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'term': json['term'],
        'offset': json['offset'],
    };
}
export function CrateSearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'term': value['term'],
        'offset': value['offset'],
    };
}
