import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable as Droppable } from '../../utils/strictModeDroppable/StrictModeDroppable';
import { iTrack, iTracks } from '../../types/ITrack';
import './Tracks.scss';
import {
  TrackAvailabilityOptionDto,
  TrackAvailabilityOptionsDto,
} from '@cratehackers/api-client';
import ApiService from '../../services/Api.service';
import { Track } from './Track';
import { run } from '../../helper/electron';
import { sha256 } from '../../utils/cryptographicFunctions';

const reorderTracks = (
  list: iTrack[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface FilePathMap {
  [trackId: string]: string[];
}

const BATCH_SIZE = 1; // Process 2 tracks concurrently

interface iLocalFile {
  filePath: string;
  id: number;
  importSources?: string;
  tags?: any;
  isVideo?: boolean;
  isSelected?: boolean;
}

export const Tracks: React.FC<
  iTracks & {
    editable: boolean;
    showTrackMatcher?: boolean;
    toggleTrackExpansion: (index: number) => void;
    onSelectionChange: (selectedFiles: FilePathMap) => void;
  }
> = ({
  tracks,
  editable,
  showTrackMatcher = true,
  toggleTrackExpansion,
  onSelectionChange,
}) => {
  const [orderedTracks, setTracks] = useState(tracks || []);
  const [selectedFiles, setSelectedFiles] = useState<FilePathMap>({});
  const [loadingState, setLoadingState] = useState({
    currentIndex: 0,
    total: 0,
    isComplete: false,
    processingTracks: new Set<string>(),
    initialized: false, // Add this to track initial setup
  });

  const [purchaseOptions, setPurchaseOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);
  const [streamingOptions, setStreamingOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);
  const [cloudStorageOptions, setCloudStorageOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);
  const [resourceOptions, setResourceOptions] = useState<
    TrackAvailabilityOptionDto[]
  >([]);

  useEffect(() => {
    // TODO: Maybe return this data naturally with the tracks?
    const fetchTrackAvailabilityOptions = async () => {
      try {
        const apiClient = ApiService.getApiClient();
        const data: TrackAvailabilityOptionsDto =
          await apiClient.trackControllerGetTrackAvailabilityOptions();
        setPurchaseOptions(data.purchases);
        setStreamingOptions(data.streaming);
        setCloudStorageOptions(data.cloudStorage);
        setResourceOptions(data.resources);
      } catch (error) {
        console.error('Error fetching track dropdowns', error);
      }
    };

    fetchTrackAvailabilityOptions();
  }, []);

  useEffect(() => {
    setTracks(tracks || []);
  }, [tracks]);

  // Modify the initial setup effect
  useEffect(() => {
    if (showTrackMatcher && tracks && !loadingState.initialized) {
      setLoadingState({
        currentIndex: 0,
        total: tracks.length,
        isComplete: false,
        processingTracks: new Set(),
        initialized: true, // Mark as initialized
      });
    } else if (!showTrackMatcher) {
      // Reset when matcher is disabled
      setLoadingState({
        currentIndex: 0,
        total: 0,
        isComplete: false,
        processingTracks: new Set(),
        initialized: false,
      });
    }
  }, [showTrackMatcher, tracks]);

  const processTrack = useCallback(async (track: iTrack): Promise<void> => {
    const trackId =
      track.ID ||
      sha256(
        JSON.stringify({ artist: track.Artist, title: track.Title }),
      ).toString();

    // Only process if not already processing this track
    if (loadingState.processingTracks.has(trackId)) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const listener = (response: any) => {
        setLoadingState((prev) => ({
          ...prev,
          processingTracks: new Set(
            Array.from(prev.processingTracks).filter((id) => id !== trackId),
          ),
        }));
        resolve();
      };

      setLoadingState((prev) => ({
        ...prev,
        processingTracks: new Set([
          ...Array.from(prev.processingTracks),
          trackId,
        ]),
      }));

      window.api?.once(`getTrackMatches:${trackId}`, listener);

      run('getTrackMatches', {
        artist: track.Artist,
        title: track.Title,
        id: trackId,
      });
    });
  }, []);

  // const processNextBatch = useCallback(async () => {
  //   console.log(
  //     'processNextBatch:',
  //     loadingState.currentIndex,
  //     loadingState.initialized,
  //   );
  //
  //   if (
  //     !tracks ||
  //     loadingState.currentIndex >= tracks.length ||
  //     !loadingState.initialized
  //   ) {
  //     if (loadingState.currentIndex >= (tracks?.length || 0)) {
  //       setLoadingState((prev) => ({ ...prev, isComplete: true }));
  //     }
  //     return;
  //   }
  //
  //   const batchEnd = Math.min(
  //     loadingState.currentIndex + BATCH_SIZE,
  //     tracks.length,
  //   );
  //   const currentBatch = tracks.slice(loadingState.currentIndex, batchEnd);
  //
  //   for (let index = 0; index < currentBatch.length; index++) {
  //     const track = currentBatch[index];
  //     await processTrack(track); // Process each track sequentially
  //   }
  //
  //   setLoadingState((prev) => ({
  //     ...prev,
  //     currentIndex: batchEnd,
  //     isComplete: batchEnd >= tracks.length,
  //   }));
  // }, [
  //   tracks,
  //   loadingState.currentIndex,
  //   loadingState.initialized,
  //   processTrack,
  // ]);

  // Update the effect that watches for batch processing
  // useEffect(() => {
  //   if (
  //     showTrackMatcher &&
  //     tracks &&
  //     loadingState.initialized &&
  //     loadingState.currentIndex < tracks.length &&
  //     !loadingState.isComplete &&
  //     loadingState.processingTracks.size === 0 // Only process next batch when current batch is done
  //   ) {
  //     processNextBatch();
  //   }
  // }, [
  //   loadingState.currentIndex,
  //   loadingState.processingTracks,
  //   loadingState.initialized,
  //   tracks,
  //   showTrackMatcher,
  //   processNextBatch,
  // ]);

  // Pass loading state to Track component

  const handleSelectionChange = (trackId: string, selections: iLocalFile[]) => {
    setSelectedFiles((prev: FilePathMap) => {
      const newSelections = selections
        .filter((s) => s.isSelected)
        .map((s) => s.filePath);

      return {
        ...prev,
        [trackId]: newSelections,
      };
    });
  };

  useEffect(() => {
    onSelectionChange(selectedFiles);
  }, [selectedFiles]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedTracks = reorderTracks(
      orderedTracks,
      result.source.index,
      result.destination.index,
    );

    setTracks(reorderedTracks);
  };

  return (
    <>
      {orderedTracks.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {orderedTracks.map((track, index) => {
                  const trackId =
                    track.ID ||
                    sha256(
                      JSON.stringify({
                        artist: track.Artist,
                        title: track.Title,
                      }),
                    ).toString();

                  return (
                    <Draggable
                      key={trackId + index.toString()}
                      draggableId={trackId + index.toString()}
                      index={index}
                      isDragDisabled={!editable}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="crate-track"
                        >
                          <Track
                            onSelectionChange={handleSelectionChange}
                            trackNum={index + 1}
                            editable={editable}
                            initiallyExpanded={showTrackMatcher}
                            onExpandedChange={() => toggleTrackExpansion(index)}
                            dragHandleProps={provided.dragHandleProps}
                            resourceOptions={resourceOptions}
                            streamingOptions={streamingOptions}
                            purchaseOptions={purchaseOptions}
                            cloudStorageOptions={cloudStorageOptions}
                            {...track}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
