import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRouterLink,
  IonRow,
  IonSpinner,
  IonText,
  IonThumbnail,
  IonChip,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CrateService } from '../../../services/Crate.service';
import { DateUtilsService } from '../../../services/DateUtils.service';
import { iCrate } from '../../../types/ICrate';
import '../Crates.scss';
import { useHistory } from 'react-router-dom';

interface iCratesProps {
  categories: string[];
  limit?: number;
  sectionHeader?: string;
}

export const CrateGridMini: React.FC<iCratesProps> = ({
  categories = [],
  limit = 0,
  sectionHeader = '',
}) => {
  const [crates, setCrates] = useState<iCrate[]>();
  const history = useHistory();
  useEffect(() => {
    CrateService.fetchSelectedCrates(
      categories,
      (data) => {
        if (data.length > 0) {
          if (limit > 0) {
            const limitedCrates = [];
            for (let i = 0; i < limit; i++) {
              limitedCrates.push(data[i]);
            }
            setCrates(limitedCrates);
          } else {
            setCrates(data);
          }
        }
      },
      limit,
      history,
    );
  }, [limit]);

  return (
    <div className={'crate-grid-mini'}>
      <IonGrid>
        <IonRow>
          {!crates && (
            <IonCol size="12" size-md="12" size-lg="12">
              <IonCard className="crate-grid-card loading">
                <IonSpinner />
                <IonCardHeader>
                  <IonCardTitle>Loading...</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
          )}
          {crates &&
            crates.length > 0 &&
            crates.map((crate) => (
              <IonCol
                size-xl="2"
                size-lg="2"
                size-md="4"
                size-sm="6"
                size-xs="12"
                key={crate?._id}
              >
                <IonRouterLink routerLink={'/crate/' + crate?._id}>
                  <IonCard>
                    <img
                      className="crate-grid-mini-image"
                      alt={crate?.Title}
                      src={
                        'https:' + (crate?.Image || '').replace(/^https?:/, '')
                      }
                    />
                    <IonCardHeader className="no-wrap-header">
                      {crate?.Title}
                    </IonCardHeader>
                  </IonCard>
                </IonRouterLink>
              </IonCol>
            ))}
        </IonRow>
      </IonGrid>
      <br></br>
    </div>
  );
};
