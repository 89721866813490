/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the FeatureRequestDto interface.
 */
export function instanceOfFeatureRequestDto(value) {
    if (!('description' in value) || value['description'] === undefined)
        return false;
    return true;
}
export function FeatureRequestDtoFromJSON(json) {
    return FeatureRequestDtoFromJSONTyped(json, false);
}
export function FeatureRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'description': json['description'],
    };
}
export function FeatureRequestDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'description': value['description'],
    };
}
