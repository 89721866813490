/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SearchDto interface.
 */
export function instanceOfSearchDto(value) {
    if (!('term' in value) || value['term'] === undefined)
        return false;
    if (!('objectType' in value) || value['objectType'] === undefined)
        return false;
    return true;
}
export function SearchDtoFromJSON(json) {
    return SearchDtoFromJSONTyped(json, false);
}
export function SearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'term': json['term'],
        'source': json['source'] == null ? undefined : json['source'],
        'objectType': json['objectType'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
        'raw': json['raw'] == null ? undefined : json['raw'],
        'additionalParams': json['additionalParams'] == null ? undefined : json['additionalParams'],
    };
}
export function SearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'term': value['term'],
        'source': value['source'],
        'objectType': value['objectType'],
        'offset': value['offset'],
        'limit': value['limit'],
        'raw': value['raw'],
        'additionalParams': value['additionalParams'],
    };
}
