/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AccessTokenDto interface.
 */
export function instanceOfAccessTokenDto(value) {
    if (!('accessToken' in value) || value['accessToken'] === undefined)
        return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined)
        return false;
    if (!('expiresIn' in value) || value['expiresIn'] === undefined)
        return false;
    if (!('tokenType' in value) || value['tokenType'] === undefined)
        return false;
    return true;
}
export function AccessTokenDtoFromJSON(json) {
    return AccessTokenDtoFromJSONTyped(json, false);
}
export function AccessTokenDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'],
        'expiresIn': json['expires_in'],
        'tokenType': json['token_type'],
    };
}
export function AccessTokenDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'access_token': value['accessToken'],
        'refresh_token': value['refreshToken'],
        'expires_in': value['expiresIn'],
        'token_type': value['tokenType'],
    };
}
