import React, { useEffect, useState } from 'react';
import buildStyle from '../build-path.module.scss';
import './text.scss';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonInput,
  IonRow,
  IonText,
  IonTextarea,
} from '@ionic/react';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import { iTrack } from '../../../../types/ITrack';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { iCrate } from '../../../../types/ICrate';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../../../../store/hooks';

export default function TextPath() {
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [crateName, setCrateName] = useState('');
  const [tracks, setTracks] = useState<iTrack[]>([]);
  const [chosenDelimiter, setChosenDelimiter] = useState<string>('');
  const [gridApi, setGridApi] = useState<any>();
  const [showDelimiterChange, setShowDelimiterChange] = useState(false);
  const [pasteContent, setPasteContent] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const delimiters: { [key: string]: string } = {
    Comma: ',',
    Hyphen: '-',
    Tab: '\t',
    Pipe: '|',
    Semicolon: ';',
  };

  const buildCrate = () => {
    const newCrate: iCrate = {
      _id: '',
      Title: crateName,
      Description: 'Created from Crate Hackers Crate Builder (text import)',
      Tracks: tracks,
      createdFrom: 'Text',
    };

    return newCrate;
  };

  const handlePasteContent = (e: CustomEvent) => {
    setPasteContent(e.detail.value);
    parseContent(pasteContent);
  };

  const parseContent = (content: string) => {
    const lines = content.split('\n').filter((line) => line);
    if (lines.length) {
      const firstLine = lines[0];
      const delimiter = detectDelimiter(firstLine);
      setChosenDelimiter(delimiter);
      const newTracks = lines.map((line) => parseTrack(line, delimiter));
      setTracks(newTracks);
    }
  };

  const detectDelimiter = (line: string): string => {
    const delimiterValues = Object.keys(delimiters).map(
      (delimiterName: string) => {
        return delimiters[delimiterName];
      },
    );
    return delimiterValues.find((d) => line.includes(d)) || ',';
  };

  const handleDelimiterChange = (newDelimiter: string) => {
    setChosenDelimiter(newDelimiter);
    if (pasteContent) {
      parseContent(pasteContent);
    }
  };

  useEffect(() => {
    setChosenDelimiter('');
    setTracks([]);
    if (pasteContent) {
      parseContent(pasteContent);
    } else {
      setTracks([]);
    }
  }, [chosenDelimiter, pasteContent]);

  const parseTrack = (line: string, delimiter: string): iTrack => {
    const parts = line.split(delimiter).map((part) => part.trim());
    return { ID: '', Artist: parts[0], Title: parts[1] };
  };

  const flipArtistTitle = () => {
    const flippedTracks = tracks.map((track) => ({
      ID: '',
      Artist: track.Title,
      Title: track.Artist,
    }));
    setTracks(flippedTracks);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const [columnDefs] = useState([
    { field: 'id', hide: true },
    {
      field: 'Artist',
      headerName: 'Artist',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: false,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'Title',
      headerName: 'Title',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: false,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
  ]);

  return (
    <>
      <IonRow>
        <IonCol size="12">
          <div className={buildStyle.buildHeader}>
            <div>What should we name your crate?</div>
          </div>
          <IonInput
            className={buildStyle.buildInput}
            placeholder="Crate Name"
            labelPlacement="floating"
            fill="outline"
            value={crateName}
            onIonInput={(e: any) => setCrateName(e.target.value)}
          >
            <div slot="label">Crate Name</div>
          </IonInput>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonTextarea
            label="Paste text here"
            labelPlacement="floating"
            fill="solid"
            className={buildStyle.buildInput}
            autoGrow={false}
            value={pasteContent}
            onIonInput={handlePasteContent}
          />
        </IonCol>
      </IonRow>

      <IonAlert
        cssClass={'delimiterAlert'}
        isOpen={showDelimiterChange}
        onDidDismiss={() => setShowDelimiterChange(false)}
        header="Change Delimiter"
        message={`Current delimiter: ${chosenDelimiter} (${Object.keys(
          delimiters,
        ).find((key) => delimiters[key] === chosenDelimiter)})`}
        buttons={Object.keys(delimiters).map((key) => ({
          text: `${key}`,
          handler: () => handleDelimiterChange(delimiters[key]),
        }))}
      />

      {pasteContent && (
        <>
          <IonRow style={{ padding: '20px' }}>
            <IonCol size="12" sizeMd="6">
              {chosenDelimiter && (
                <IonText color="primary">
                  <h3>
                    Detected Delimiter:{' '}
                    {Object.keys(delimiters).find(
                      (key) => delimiters[key] === chosenDelimiter,
                    ) || 'None'}
                  </h3>
                </IonText>
              )}
            </IonCol>
            <IonCol>
              {/*{chosenDelimiter && (*/}
              {/*  <IonButton onClick={() => setShowDelimiterChange(true)}>*/}
              {/*    Change Delimiter*/}
              {/*  </IonButton>*/}
              {/*)}*/}
              {/*TODO: Add this back in later to get it working */}
            </IonCol>
            <IonCol>
              {tracks.length > 0 && (
                <IonButton
                  className="text-primary-buttons"
                  onClick={flipArtistTitle}
                >
                  Flip Artist and Title
                </IonButton>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div
                className="ag-theme-alpine"
                style={{ height: 344, width: '100%' }}
              >
                {tracks.length && (
                  <AgGridReact
                    className={`ag-theme-alpine${
                      theme === 'dark' ? '-dark' : ''
                    }`}
                    rowData={tracks}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                  />
                )}
              </div>
            </IonCol>
          </IonRow>
        </>
      )}

      <BuildGenerateCrate
        buildCrateFn={buildCrate}
        isBtnDisabled={crateName === '' || tracks.length === 0}
      />
    </>
  );
}
