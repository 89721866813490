import { IonFooter } from '@ionic/react';
import React from 'react';
import './Footer.scss';

const Footer: React.FC = () => {
  return (
    <IonFooter>
      <div className="waveform"></div>
    </IonFooter>
  );
};

export default Footer;
