import React from 'react';
import { IonButton, IonCol, IonRow, IonToggle, IonText } from '@ionic/react';
import isElectron from '../../utils/isElectron';

interface TrackMatcherControlsProps {
  showTrackMatcher: boolean;
  onToggleTrackMatcher: (e: CustomEvent) => void;
  onSelectFirstMatches: () => void;
  onSelectAllMatches: () => void;
  totalMatches: number;
}

const TrackMatcherControls: React.FC<TrackMatcherControlsProps> = ({
  showTrackMatcher,
  onToggleTrackMatcher,
  onSelectFirstMatches,
  onSelectAllMatches,
  totalMatches,
}) => {
  if (!isElectron()) {
    return (
      <IonCol size="12" sizeMd="5" sizeLg="8">
        <IonText>Match your tracks on the desktop app</IonText>
      </IonCol>
    );
  }

  return (
    <IonRow className="ion-align-items-center">
      <IonCol size="12" sizeMd="5" sizeLg="4">
        <IonToggle
          labelPlacement="start"
          onIonChange={onToggleTrackMatcher}
          checked={showTrackMatcher}
          className="show-track-matcher-toggle"
        >
          Show Track Matcher
        </IonToggle>
      </IonCol>

      {/*{showTrackMatcher && (*/}
      {/*  <IonCol size="12" sizeMd="7" sizeLg="8" className="ion-text-end">*/}
      {/*    <IonButton fill="clear" onClick={onSelectFirstMatches}>*/}
      {/*      Select First Matches*/}
      {/*    </IonButton>*/}
      {/*    <IonButton fill="clear" onClick={onSelectAllMatches}>*/}
      {/*      Select All Matches ({totalMatches})*/}
      {/*    </IonButton>*/}
      {/*  </IonCol>*/}
      {/*)}*/}
    </IonRow>
  );
};

export default TrackMatcherControls;
