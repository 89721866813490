import { IonIcon, IonInput, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import SpotifyService from '../../../../services/Spotify.service';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import buildStyle from '../build-path.module.scss';
import style from './spotify.module.scss';
import LoginButton from '../../../spotify/login-button';
import { informationCircleOutline } from 'ionicons/icons';
import { iCrate } from '../../../../types/ICrate';
import { useDispatch } from '../../../../store/hooks';
import { useHistory } from 'react-router-dom';

export default function SpotifyPath() {
  const [playlistLink, setPlaylistLink] = useState<string>('');
  const [crateName, setCrateName] = useState('');
  const [crateDescription, setCrateDescription] = useState('');
  const [playlist, setPlaylist] = useState<any>();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (playlistLink && playlistLink.length > 8) {
      getSpotifyPlaylist();
    } else {
      setPlaylist(null);
    }
  }, [playlistLink]);

  useEffect(() => {
    setIsBtnDisabled(!crateName || playlist?.tracks?.length === 0);
  }, [crateName, playlist]);

  const buildCrate = () => {
    const newCrate: iCrate = {
      _id: '',
      Title: crateName,
      Description: crateDescription,
      Tracks: playlist.tracks.tracks,
      createdFrom: 'Spotify',
    };

    return newCrate;
  };

  return (
    <>
      <div className={buildStyle.loginContainer}>
        <div className={buildStyle.sideBySide}>
          <IonIcon
            icon={informationCircleOutline}
            className={buildStyle.noticeIcon}
          />
          <p className={buildStyle.message}>
            You can import private playlists after you Login to Spotify.
          </p>
          <LoginButton redirectPath={'/my-crates/spotify'} />
        </div>
      </div>

      <div className={buildStyle.buildHeader}>
        <div>What should we name your crate?</div>
      </div>

      <div>
        <IonInput
          className={buildStyle['build-input']}
          labelPlacement="floating"
          fill="outline"
          value={crateName}
          onIonInput={(e: any) => {
            setCrateName(e.target.value);
          }}
        >
          <div slot="label">Crate Name</div>
        </IonInput>
      </div>

      <div className={buildStyle.linkContainer}>
        <IonLabel className="link-label">
          Enter a playlist link from Spotify
        </IonLabel>
        <div className={buildStyle['build-input-wrapper']}>
          <IonInput
            className={buildStyle['build-input']}
            placeholder="https://open.spotify.com/album/... | https://open.spotify.com/playlist/..."
            labelPlacement="floating"
            fill="outline"
            value={playlistLink}
            onIonInput={(e: any) => setPlaylistLink(e.detail.value!)}
          >
            <div slot="label">Spotify Album or Playlist Link</div>
          </IonInput>
        </div>

        {playlist && (
          <div className={style['result-item']}>
            <img src={playlist?.info.images[0].url} alt={playlist.info.Title} />
            <div>
              <div>{playlist.info.name}</div>
              {playlist &&
              playlist.info &&
              playlist.info.owner &&
              playlist.info.owner.display_name ? (
                <small>By {playlist.info.owner.display_name}</small>
              ) : (
                <small>
                  By{' '}
                  {playlist.info.artists
                    .map((artist: any) => artist.name)
                    .join(', ')}
                </small>
              )}
              <br />
              <small>{playlist.info.tracks.items.length} tracks</small>
            </div>
          </div>
        )}
      </div>

      <BuildGenerateCrate
        buildCrateFn={buildCrate}
        isBtnDisabled={isBtnDisabled}
      />
    </>
  );

  async function getSpotifyPlaylist() {
    try {
      const playListId: string = extractSpotifyPlaylistId(playlistLink);
      if (playListId) {
        const playlist = await SpotifyService.GetPlaylistById(playListId);
        setPlaylist(playlist);

        if (crateName.length == 0) {
          setCrateName(playlist.info.name);
        }
        return;
      }

      const albumId: string = extractSpotifyAlbumId(playlistLink);
      if (albumId) {
        const album = await SpotifyService.GetAlbumById(albumId);
        setPlaylist(album);

        if (crateName.length == 0) {
          setCrateName(album.info.name);
        }
        return;
      }
    } catch (error) {
      console.error('getSpotifyPlaylist:', error);
    }
  }

  function extractSpotifyPlaylistId(url: string): string {
    const regex = /playlist\/(\w+)(?:\?|$)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }

  function extractSpotifyAlbumId(url: string): string {
    const regex = /album\/(\w+)(?:\?|$)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }
}
