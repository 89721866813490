import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { iCategory } from '../../types/ICategory';
import './Categories.scss';

export interface iCategoryInlineProps {
  categories?: iCategory[];
}

export const CategoriesInline: React.FC<iCategoryInlineProps> = ({
  categories = [],
}) => {
  return (
    <>
      {categories &&
        categories.length > 0 &&
        categories.map((category: iCategory, index: number) => (
          <IonRouterLink
            key={'category-' + category.id}
            href={'/category/' + category.id}
          >
            {category.Category}

            {index < categories.length - 1 && ', '}
          </IonRouterLink>
        ))}
    </>
  );
};
