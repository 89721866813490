// ProgressIndicator.tsx
import React from 'react';
import ProgressIndicator from '../../progress-indicator/progress-indicator';

interface IProgressIndicatorProps {
  currentStep: number;
  firstStepName: string;
}

export default function BuildProgressIndicator({
  currentStep,
  firstStepName,
}: IProgressIndicatorProps) {
  const steps = [
    {
      label: firstStepName,
      callback: () => console.log('firstStepName clicked'),
    },
    {
      label: 'Settings',
      callback: () => console.log('Settings clicked'),
    },
    {
      label: 'Generate',
      callback: () => console.log('Generate clicked'),
    },
    { label: 'Export', callback: () => console.log('Export clicked') },
  ];

  return <ProgressIndicator currentStep={currentStep} steps={steps} />;
}
