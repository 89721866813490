import { IonCol, IonIcon, IonLabel, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './serato.scss';
import { cubeOutline } from 'ionicons/icons';
import { run } from '../../../../helper/electron';
import TreeView, { flattenTree, INode } from 'react-accessible-treeview';
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { GridReadyEvent } from 'ag-grid-community';
import { iTrack } from '../../../../types/ITrack';

type SeratoCrate = {
  path: string[];
  file: string;
  last_modified_at: string;
};

interface ImportCrateProps {
  setCrateDescription: (description: string) => void;
  setTrackList: (trackList: iTrack[]) => void;
}

type TrackType = {
  filepath: string;
  artist: string[];
  title: string;
  bpm: number;
  key: string;
};

const ImportCrates: React.FC<ImportCrateProps> = ({
  setCrateDescription,
  setTrackList,
}) => {
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [crates, setCrates] = useState<SeratoCrate[]>([]);
  const [crateTree, setCrateTree] = useState<ReturnType<typeof flattenTree>>();
  const [selectedCrateIds, setSelectedCrateIds] = useState<number[]>([]);
  const [selectedCrates, setSelectedCrates] = useState<INode<IFlatMetadata>[]>(
    [],
  );
  const [flattedTree, setFlattedTree] = useState<INode<IFlatMetadata>[]>();
  const [tracks, setTracks] = useState<TrackType[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  const buildTree = (seratoCrates: SeratoCrate[]) => {
    type TreeNode = {
      name: string;
      children?: TreeNode[];
      metadata?: IFlatMetadata;
    };

    const tree: TreeNode = { name: '' };

    for (const seratoCrate of seratoCrates) {
      let currentNode = tree;

      for (const part of seratoCrate.path) {
        let childNode =
          currentNode.children &&
          currentNode.children.find((thus) => thus.name === part);

        if (!childNode) {
          childNode = { name: part };
          if (!currentNode.children) currentNode.children = [];
          childNode.metadata = { file: seratoCrate.file };
          currentNode.children.push(childNode);
        }

        currentNode = childNode;
      }
    }

    setFlattedTree(flattenTree(tree));
  };

  const getSeratoCrateTracks = (filename: string): Promise<any[]> => {
    return new Promise((resolve) => {
      const listener = window.api?.on('serato/get-crate-tracks', (data) => {
        window.api?.removeListener('serato/get-crate-tracks', listener);
        resolve(data);
      });

      run('serato/get-crate-tracks', filename);
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const [columnDefs] = useState([
    { field: 'id', hide: true },
    {
      field: 'artist',
      headerName: 'Artist',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'bpm',
      headerName: 'BPM',
      width: 70,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'key',
      headerName: 'Key',
      width: 70,
      resizable: true,
      filter: false,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
  ]);

  useEffect(() => {
    const crateListener = window.api?.on(`serato/get-crates`, (data) => {
      setCrates(data);
    });
    run('serato/get-crates');
    return () => {
      window.api?.removeListener('serato/get-crates', crateListener);
    };
  }, []);

  useEffect(() => {
    buildTree(crates);
  }, [crates]);

  useEffect(() => {
    setCrateTree(flattedTree);
  }, [flattedTree]);

  useEffect(() => {
    if (flattedTree) {
      const selectedCratesPopulated = flattedTree.filter((item) =>
        selectedCrateIds.includes(Number(item.id)),
      );
      setSelectedCrates(selectedCratesPopulated);

      const getSelectedCratesTracks = async () => {
        let allTracks: TrackType[] = [];
        await Promise.all(
          selectedCratesPopulated.map(async (item) => {
            if (item.metadata) {
              const tracks = await getSeratoCrateTracks(
                String(item.metadata.file),
              );
              allTracks = allTracks.concat(tracks);
            }
          }),
        );

        const filenameMap = new Map();
        allTracks = allTracks.filter((track) => {
          if (!track || filenameMap.has(track.filepath)) return false;
          filenameMap.set(track.filepath, true);
          return true;
        });

        return allTracks;
      };

      getSelectedCratesTracks().then((tracks) => {
        // BUG: This has data, but it's missing when called in BuildFunction. Why?
        setTracks(tracks);
      });
    }
  }, [selectedCrateIds]);

  useEffect(() => {
    const trackList = tracks.map((data, index) => {
      return {
        ID: '', // This is placeholder, these tracks don't have an id
        Artist: Array.isArray(data.artist)
          ? data.artist.join(', ')
          : data.artist || '',
        Title: data.title ? data.title : '',
        Preview: data.filepath ? data.filepath : '',
        // TODO: Try to get down to one of these definitions
        // PreviewURL: data.filepath ? data.filepath : '',
        duration: 0,
        BPM: data.bpm ? data.bpm : 0,
        energy: 0,
        danceability: 0,
        valence: 0,
        Key: data.key ? data.key : '',
        // TODO: Probably transform key on api side
        // ArtistID: '',
        // AlbumCover: '',
        // PreviewURL: '',
        // SpotifyID: '',
      };
    });
    setTrackList(trackList);

    const crateExportNameString =
      Array.isArray(selectedCrates) && selectedCrates.length
        ? selectedCrates.map((crate) => crate.name).join(', ')
        : '';

    setCrateDescription(crateExportNameString);
  }, [tracks]);

  return (
    <>
      <div className="serato-crates-ctn">
        {crateTree && (
          <IonRow>
            <IonCol size="12" sizeMd="6">
              <TreeView
                data={crateTree}
                aria-label="crate tree"
                togglableSelect
                clickAction="EXCLUSIVE_SELECT"
                multiSelect
                nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  level,
                  handleSelect,
                }) => (
                  <div
                    {...getNodeProps()}
                    style={{
                      paddingLeft: 20 * level,
                    }}
                  >
                    <IonIcon icon={cubeOutline} />
                    <IonLabel>{element.name}</IonLabel>
                  </div>
                )}
                onNodeSelect={(event) => {
                  if (
                    event.treeState?.selectedIds &&
                    event.treeState?.selectedIds.size > 0
                  ) {
                    setSelectedCrateIds(
                      Array.from(event.treeState?.selectedIds ?? []).map(
                        Number,
                      ),
                    );
                  } else {
                    setSelectedCrateIds([]);
                  }
                }}
              />
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <div
                className="ag-theme-alpine"
                style={{ height: 400, width: '100%' }}
              >
                <AgGridReact
                  className={`ag-theme-alpine${
                    theme === 'dark' ? '-dark' : ''
                  }`}
                  rowData={tracks}
                  columnDefs={columnDefs}
                  onGridReady={onGridReady}
                />
              </div>
            </IonCol>
          </IonRow>
        )}
      </div>
    </>
  );
};

export default ImportCrates;
