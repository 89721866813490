/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the BugReportDto interface.
 */
export function instanceOfBugReportDto(value) {
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('os' in value) || value['os'] === undefined)
        return false;
    if (!('osVersion' in value) || value['osVersion'] === undefined)
        return false;
    if (!('osArch' in value) || value['osArch'] === undefined)
        return false;
    if (!('appVersion' in value) || value['appVersion'] === undefined)
        return false;
    return true;
}
export function BugReportDtoFromJSON(json) {
    return BugReportDtoFromJSONTyped(json, false);
}
export function BugReportDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'description': json['description'],
        'os': json['os'],
        'osVersion': json['osVersion'],
        'osArch': json['osArch'],
        'appVersion': json['appVersion'],
    };
}
export function BugReportDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'description': value['description'],
        'os': value['os'],
        'osVersion': value['osVersion'],
        'osArch': value['osArch'],
        'appVersion': value['appVersion'],
    };
}
