import React, { ChangeEvent, useEffect, useState } from 'react';
import { FolderData } from './folders';
import style from './folder-flattener.module.scss';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react';
import {
  chevronForward,
  createOutline,
  openOutline,
  refreshOutline,
  trashOutline,
} from 'ionicons/icons';
import Waveform from '../../components/waveform/Waveform';
import { run } from '../../helper/electron';
import ProgressButton from '../../components/buttons/ProgressButton';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { FlattenedFolders } from './folder-flattener';

interface VerifyProps {
  folders: FolderData[];
  destination: FolderData;
  reloadFolders: any;
  isReloadingFolders: boolean;
  callback: (data: any) => void;
}

const Verify: React.FC<VerifyProps> = ({
  folders,
  destination,
  reloadFolders,
  isReloadingFolders,
  callback,
}) => {
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [filePaths, setFilePaths] = useState<Record<string, string[]>>({});
  const [conflicts, setConflicts] = useState<Record<string, string[]>>({});
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [currentConflictPath, setCurrentConflictPath] = useState<string>('');
  const [newFilename, setNewFilename] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [updateKey, setUpdateKey] = useState<number>(0);
  const [gridApi, setGridApi] = useState<any>();
  const [rowData, setRowData] = useState<FlattenedFolders[]>([]);

  const handleConflictPath = async (conflictPath: string) => {
    run('open-directory', { filepath: conflictPath });
  };

  const handleRename = async (conflictPath: string) => {
    const filename = conflictPath.split(/[\\/]/).pop() || ''; // Extract filename from path
    setNewFilename(filename); // Pre-populate filename
    setCurrentConflictPath(conflictPath);
    setShowRenameModal(true);
  };

  const handleDelete = async (conflictPath: string) => {
    setCurrentConflictPath(conflictPath);
    setShowDeleteAlert(true);
  };

  const handleRenameConfirm = async () => {
    const renameFileEvent = async (response: any) => {
      console.log('renameFileEvent:', response);
      await reloadFolders();
      setNewFilename('');
      setShowRenameModal(false);
    };

    window.api?.once('rename-files', renameFileEvent);
    run('rename-files', [
      {
        currentPath: currentConflictPath,
        newFilename: newFilename,
      },
    ]);
  };

  const handleRenameCancel = async () => {
    setShowRenameModal(false);
    setNewFilename('');
  };

  const handleDeleteCancel = async () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteConfirm = async () => {
    console.log('handleDelete');
    console.log('currentConflictPath:', currentConflictPath);
    const deleteFileEvent = (response: FolderData[]) => {
      console.log('handleResponse:', response);
      setShowDeleteAlert(false);
      setUpdateKey((updateKey) => updateKey + 1);
    };

    window.api?.once('delete-files', deleteFileEvent);
    run('delete-files', [currentConflictPath]);

    await reloadFolders();
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const callbackButton = async () => {
    callback(rowData);
  };

  type ColDefTyped = ColDef | (ColDef & { cellRendererFramework?: any });
  const [columnDefs] = useState<ColDefTyped[]>([
    {
      field: 'current_path',
      headerName: 'Current Path',
      flex: 1,
      filter: true,
      sortable: true,
      editable: false,
      resizable: true,
    },
    {
      field: 'new_path',
      headerName: 'New Path',
      flex: 1,
      filter: true,
      sortable: true,
      editable: false,
      resizable: true,
    },
  ]);

  useEffect(() => {
    console.log('-------');
    console.log('updateKey', updateKey);
    console.log('folders', folders);
    console.log('destination', destination);
    setFilePaths({});
    setConflicts({});

    const newFilePaths: Record<string, string[]> = {};
    const newConflicts: Record<string, string[]> = {};
    folders.forEach((folder) => {
      folder.files.forEach((file: string) => {
        const fileName = file.split(/[\\\/]/).pop() as string;
        const newPath = `${destination?.path}/${fileName}`;
        if (!newFilePaths[newPath]) {
          newFilePaths[newPath] = [];
        }
        newFilePaths[newPath].push(file);
      });
    });

    Object.keys(newFilePaths).forEach((path) => {
      const conflictFiles = newFilePaths[path];
      if (conflictFiles.length > 1) {
        newConflicts[path] = conflictFiles;
      }
    });

    console.log('New File Paths:', newFilePaths);
    console.log('Conflicts:', newConflicts);
    setFilePaths(newFilePaths);
    setConflicts(newConflicts);

    if (Object.keys(newConflicts).length > 0) {
      return;
    }

    const pathMap: FlattenedFolders[] = [];
    folders.forEach((folder) => {
      folder.files.forEach((file: string) => {
        const fileName = file.split(/[\\\/]/).pop() as string;
        const newPath = `${destination?.path}/${fileName}`;
        pathMap.push({
          current_path: file,
          new_path: newPath,
        });
      });
    });

    setRowData(pathMap);
  }, [updateKey, folders, destination]);

  return (
    <>
      <div className={style['select-folder']}>
        <div className={'page-header'}>
          <h1>Verify</h1>
          <div id="actions" className="button-group">
            {Object.keys(conflicts).length > 0 && (
              <>
                {/*TODO: Should be a Progress Button? How's this work on large libraries?*/}
                <ProgressButton
                  id="reload-folders-btn"
                  className="primary"
                  isLoading={isReloadingFolders}
                  progress={0}
                  icon={refreshOutline}
                  onClick={reloadFolders}
                >
                  Reload Files
                </ProgressButton>
              </>
            )}
          </div>
        </div>
        {conflicts && Object.keys(conflicts).length > 0 ? (
          <>
            <p>
              There are files with the same name. Please rename or delete files
              to move forward with merging.
            </p>
            <IonModal
              isOpen={showRenameModal}
              className={`modal ${style['verify-modal']}`}
              onDidDismiss={handleRenameCancel}
            >
              <IonContent>
                <h2>Enter new filename</h2>
                <p>
                  It's important that you back up your files before you do
                  important changes like this.
                </p>
                <IonInput
                  value={newFilename}
                  placeholder="Enter new filename"
                  onIonChange={(e) => setNewFilename(e.detail.value!)}
                  clearInput
                  labelPlacement="floating"
                  fill="outline"
                >
                  <div slot="label">Enter new filename</div>
                </IonInput>
                <div className={`${style['action-container']}`}>
                  <button className={'primary'} onClick={handleRenameCancel}>
                    Cancel
                  </button>
                  <IonButton onClick={handleRenameConfirm}>Save</IonButton>
                </div>
              </IonContent>
            </IonModal>

            <IonAlert
              isOpen={showDeleteAlert}
              onDidDismiss={handleDeleteCancel}
              cssClass="delete-confirmation"
              header={'Confirm'}
              message={'Are you sure you want to delete this file?'}
              buttons={[
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: (blah) => {
                    handleDeleteCancel();
                  },
                },
                {
                  text: 'Delete',
                  handler: () => {
                    handleDeleteConfirm();
                  },
                },
              ]}
            />
            <IonRow key={'header'} className={style['table-header-container']}>
              <IonCol className={style['table-header']} size={'6'}>
                File
              </IonCol>
              <IonCol className={style['table-header-preview']}>Preview</IonCol>
              <IonCol className={style['table-header-actions']}>Actions</IonCol>
            </IonRow>
            <div className={style['select-folder-list']}>
              {Object.keys(conflicts).map((path: string, index: number) => {
                return (
                  <>
                    <IonRow key={index} className={style['table-container']}>
                      <IonCol
                        className={style['conflict-container']}
                        size={'12'}
                      >
                        <h3 className={style['conflict-path']}>{path}</h3>
                      </IonCol>
                    </IonRow>
                    {conflicts[path].map((conflictPath, conflictIndex) => (
                      <IonRow
                        key={conflictIndex}
                        className={style['table-container']}
                      >
                        <IonCol
                          className={style['conflict-file-path']}
                          size={'6'}
                        >
                          <p
                            className={style['path']}
                            onClick={() => handleConflictPath(conflictPath)}
                          >
                            {conflictPath} <IonIcon icon={openOutline} />
                          </p>
                        </IonCol>
                        <IonCol className={style['table-column-files']}>
                          <Waveform height={35} url={conflictPath} />
                        </IonCol>
                        <IonCol className={style['table-column-action']}>
                          {/*<button*/}
                          {/*  className={'primary ' + style['skip-button']}*/}
                          {/*  onClick={() => handleRename(conflictPath)}*/}
                          {/*>*/}
                          {/*  <IonIcon icon={arrowRedoOutline} />{' '}*/}
                          {/*  <IonLabel>Skip</IonLabel>*/}
                          {/*</button>*/}
                          <button
                            className={'primary ' + style['rename-button']}
                            onClick={() => handleRename(conflictPath)}
                          >
                            <IonIcon icon={createOutline} />{' '}
                            <IonLabel>Rename</IonLabel>
                          </button>
                          <button
                            className={'primary ' + style['delete-button']}
                            onClick={() => handleDelete(conflictPath)}
                          >
                            <IonIcon icon={trashOutline} />{' '}
                            <IonLabel>Delete</IonLabel>
                          </button>
                        </IonCol>
                      </IonRow>
                    ))}
                    <hr />
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <p className={`${style['page-copy']}`}>
              This is a preview of changes to your files. Please verify the new
              locations.
            </p>
            <div
              className={`ag-theme-alpine${theme === 'dark' ? '-dark' : ''} ${
                style['flatten-grid']
              }`}
            >
              <AgGridReact
                className={`ag-theme-alpine${theme === 'dark' ? '-dark' : ''} ${
                  style['grid']
                }`}
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                rowSelection="single" // Options - allows click selection of rows
                onGridReady={onGridReady}
                suppressScrollOnNewData={true}
              />
            </div>
            <IonRow className={style['action-container']}>
              <IonCol
                size="12"
                sizeLg="6"
                offsetLg="6"
                className={`${style['align-center']} ${style['full-width']}`}
              >
                {rowData.length > 0 && (
                  <IonButton
                    style={{ marginTop: '20px' }}
                    onClick={callbackButton}
                  >
                    Next
                    <IonIcon icon={chevronForward} slot="end" />
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
          </>
        )}
      </div>
    </>
  );
};

export default Verify;
