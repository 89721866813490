import { TrackAvailabilityOptionDto } from '@cratehackers/api-client';
import React from 'react';
import { IonItem, IonList } from '@ionic/react';
import isElectron from '../../utils/isElectron';
import { run } from '../../helper/electron';

interface TrackDropdownItemProps {
  artist: string;
  title: string;
  dropdownItem: TrackAvailabilityOptionDto;
}

export const TrackDropdownItem: React.FC<TrackDropdownItemProps> = ({
  artist,
  title,
  dropdownItem,
}) => {
  const link = `${dropdownItem.url}${title} ${artist}`;

  return (
    <IonList lines="none">
      {dropdownItem.url && (
        <IonItem
          className="resource-name-text"
          onClick={() =>
            isElectron()
              ? run('openExternalLink', link)
              : window.open(link, '_blank')
          }
        >
          {dropdownItem.name}
        </IonItem>
      )}
    </IonList>
  );
};
