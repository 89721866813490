import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import { iTrack } from '../../../types/ITrack';

interface MagicSorterProps {
  tracks: iTrack[];
  linkedTracks: iTrack[][];
  onSortChange: (sortedTracks: iTrack[]) => void;
}

const MagicSorter: React.FC<MagicSorterProps> = ({
  tracks,
  linkedTracks,
  onSortChange,
}) => {
  const [sorting, setSorting] = useState(false);
  const keys = [
    {
      Camelot: '8B',
      ID: '0',
      Mode: '1',
      Standard: 'C',
      Compatible: ['8B', '8A', '7B', '9B', '10B', '3B', '1B', '6B'],
    },
    {
      Camelot: '5A',
      ID: '0',
      Mode: '0',
      Standard: 'Cm',
      Compatible: ['5A', '5B', '4A', '6A', '7A', '12A', '10A', '3A'],
    },
    {
      Camelot: '3B',
      ID: '1',
      Mode: '1',
      Standard: 'C#',
      Compatible: ['3B', '3A', '2B', '4B', '5B', '10B', '8B', '1B'],
    },
    {
      Camelot: '12A',
      ID: '1',
      Mode: '0',
      Standard: 'C#m',
      Compatible: ['12A', '12B', '11A', '1A', '2A', '7A', '5A', '10A'],
    },
    {
      Camelot: '10B',
      ID: '2',
      Mode: '1',
      Standard: 'D',
      Compatible: ['10B', '10A', '9B', '11B', '12B', '5B', '3B', '8B'],
    },
    {
      Camelot: '7A',
      ID: '2',
      Mode: '0',
      Standard: 'Dm',
      Compatible: ['7A', '7B', '6A', '8A', '9A', '2A', '12A', '5A'],
    },
    {
      Camelot: '5B',
      ID: '3',
      Mode: '1',
      Standard: 'D#',
      Compatible: ['5B', '5A', '4B', '6B', '7B', '12B', '10B', '3B'],
    },
    {
      Camelot: '2A',
      ID: '3',
      Mode: '0',
      Standard: 'D#m',
      Compatible: ['2A', '2B', '1A', '3A', '4A', '9A', '7A', '12A'],
    },
    {
      Camelot: '12B',
      ID: '4',
      Mode: '1',
      Standard: 'E',
      Compatible: ['12B', '12A', '11B', '1B', '2B', '7B', '5B', '10B'],
    },
    {
      Camelot: '9A',
      ID: '4',
      Mode: '0',
      Standard: 'Em',
      Compatible: ['9A', '9B', '8A', '10A', '11A', '4A', '2A', '7A'],
    },
    {
      Camelot: '7B',
      ID: '5',
      Mode: '1',
      Standard: 'F',
      Compatible: ['7B', '7A', '6B', '8B', '9B', '2B', '12B', '5B'],
    },
    {
      Camelot: '4A',
      ID: '5',
      Mode: '0',
      Standard: 'Fm',
      Compatible: ['4A', '4B', '3A', '5A', '6A', '11A', '9A', '2A'],
    },
    {
      Camelot: '2B',
      ID: '6',
      Mode: '1',
      Standard: 'F#',
      Compatible: ['2B', '2A', '1B', '3B', '4B', '9B', '7B', '12B'],
    },
    {
      Camelot: '11A',
      ID: '6',
      Mode: '0',
      Standard: 'F#m',
      Compatible: ['11A', '11B', '10A', '12A', '1A', '6A', '4A', '9A'],
    },
    {
      Camelot: '9B',
      ID: '7',
      Mode: '1',
      Standard: 'G',
      Compatible: ['9B', '9A', '8B', '10B', '11B', '4B', '2B', '7B'],
    },
    {
      Camelot: '6A',
      ID: '7',
      Mode: '0',
      Standard: 'Gm',
      Compatible: ['6A', '6B', '5A', '7A', '8A', '1A', '11A', '4A'],
    },
    {
      Camelot: '4B',
      ID: '8',
      Mode: '1',
      Standard: 'G#',
      Compatible: ['4B', '4A', '3B', '5B', '6B', '11B', '9B', '2B'],
    },
    {
      Camelot: '1A',
      ID: '8',
      Mode: '0',
      Standard: 'G#m',
      Compatible: ['1A', '1B', '12A', '2A', '3A', '8A', '6A', '11A'],
    },
    {
      Camelot: '11B',
      ID: '9',
      Mode: '1',
      Standard: 'A',
      Compatible: ['11B', '11A', '10B', '12B', '1B', '6B', '4B', '9B'],
    },
    {
      Camelot: '8A',
      ID: '9',
      Mode: '0',
      Standard: 'Am',
      Compatible: ['8A', '8B', '7A', '9A', '10A', '3A', '1A', '6A'],
    },
    {
      Camelot: '6B',
      ID: '10',
      Mode: '1',
      Standard: 'A#',
      Compatible: ['6B', '6A', '5B', '7B', '8B', '1B', '11B', '4B'],
    },
    {
      Camelot: '3A',
      ID: '10',
      Mode: '0',
      Standard: 'A#m',
      Compatible: ['3A', '3B', '2A', '4A', '5A', '10A', '8A', '1A'],
    },
    {
      Camelot: '1B',
      ID: '11',
      Mode: '1',
      Standard: 'B',
      Compatible: ['1A', '1B', '12B', '2B', '3B', '8B', '6B', '11B'],
    },
    {
      Camelot: '10A',
      ID: '11',
      Mode: '0',
      Standard: 'Bm',
      Compatible: ['10A', '10B', '9A', '11A', '12A', '5A', '3A', '8A'],
    },
  ];

  const findCompatibleKeys = (givenKey: string) => {
    const foundKey = keys.find((key) => key.Camelot === givenKey);
    return foundKey ? foundKey.Compatible : null;
  };

  // Function to calculate edge weight for mixability
  const calculateTrackEdgeWeight = (trackA: iTrack, trackB: iTrack) => {
    let weight = 0;
    if (trackA.Key_Camelot && trackB.Key_Camelot) {
      const compatibleKeys = findCompatibleKeys(trackB.Key_Camelot);
      if (!compatibleKeys) {
        weight += Infinity;
      } else {
        const compatibleIndex = compatibleKeys.indexOf(trackA.Key_Camelot);
        if (compatibleIndex === -1) {
          weight += Infinity; // Not mixable
        } else if (compatibleIndex > 4) {
          weight += ((compatibleIndex + 1) / 8) * 2; // Penalize distant keys
        } else {
          weight += (compatibleIndex + 1) / 8; // Lower weight for close keys
        }
      }
    } else {
      weight += Infinity;
    }

    if (trackA.BPM && trackB.BPM) {
      const bpmDifference = Math.abs(trackA.BPM - trackB.BPM);
      const halfBpmDifference = Math.abs(trackA.BPM - trackB.BPM * 2);
      const doubleBpmDifference = Math.abs(trackA.BPM * 2 - trackB.BPM);

      // Check if bpm is within 10 if half or double
      if (
        bpmDifference <= 10 ||
        halfBpmDifference <= 10 ||
        doubleBpmDifference <= 10
      ) {
        let smallestDifference = bpmDifference;
        if (halfBpmDifference < smallestDifference) {
          smallestDifference = halfBpmDifference;
        }
        if (doubleBpmDifference < smallestDifference) {
          smallestDifference = doubleBpmDifference;
        }
        weight += (smallestDifference / 10) * 1.3; // Penalize large BPM jumps
      } else {
        weight += Infinity;
      }
    } else {
      weight += Infinity;
    }
    return weight;
  };

  // Find the best mixable sequence
  const findBestMixableSequence = (tracks: iTrack[]) => {
    const graph = tracks.reduce(
      (acc, trackA) => {
        acc[trackA.ID] = tracks
          .filter((trackB) => trackA.ID !== trackB.ID)
          .map((trackB) => ({
            target: trackB.ID,
            weight: calculateTrackEdgeWeight(trackA, trackB),
          }));
        return acc;
      },
      {} as Record<string, { target: string; weight: number }[]>,
    );

    const bestPath = findApproximateOptimalPath(tracks, graph);
    return bestPath.map((id) => tracks.find((track) => track.ID === id)!);
  };

  // Approximate optimal path finder
  const findApproximateOptimalPath = (
    tracks: iTrack[],
    graph: Record<string, { target: string; weight: number }[]>,
  ) => {
    let bestPath: string[] = [];
    let bestScore = -Infinity;

    // Try to find the best path starting from any track
    tracks.forEach((startingTrack) => {
      const currentPath = [startingTrack.ID];
      let currentWeight = 0;
      const visited = new Set([startingTrack.ID]);
      let currentTrackId = startingTrack.ID;

      while (true) {
        const edges = graph[currentTrackId];
        if (!edges || edges.length === 0) break;

        const sortedEdges = edges
          .filter(
            (edge) => !visited.has(edge.target) && edge.weight !== Infinity,
          )
          .sort((a, b) => a.weight - b.weight);

        if (sortedEdges.length === 0) break;

        const nextEdge = sortedEdges[0];
        currentPath.push(nextEdge.target);
        currentWeight += nextEdge.weight;
        visited.add(nextEdge.target);

        currentTrackId = nextEdge.target;
      }

      const score = evaluatePath(currentWeight, currentPath.length);
      if (score > bestScore) {
        bestScore = score;
        bestPath = currentPath;
      }
    });

    tracks.forEach((track) => {
      track.unmixable = true;
    });

    bestPath.forEach((trackId) => {
      const track = tracks.find((t) => t.ID === trackId);
      if (track) {
        track.unmixable = false;
      }
    });

    // After finding the best path, append any unvisited tracks to the end
    const visitedSet = new Set(bestPath);

    const unvisitedTracks = tracks
      .map((track) => track.ID)
      .filter((trackId) => !visitedSet.has(trackId));

    // Append unvisited tracks to the best path
    return [...bestPath, ...unvisitedTracks];
  };

  const evaluatePath = (cumulativeWeight: number, length: number) => {
    return length - cumulativeWeight * 0.5;
  };

  // Memoized sorted tracks
  const sortTracks = () => {
    setSorting(true);
    console.log('start');
    const cleanedTracks = tracks.map((track: any) => {
      return {
        ...track,
        ID: track.ID ? track.ID : track._id || track.SpotifyID,
      };
    });
    const newSequence = findBestMixableSequence(cleanedTracks);
    if (newSequence !== tracks) {
      onSortChange(newSequence);
    }
    setSorting(false);
    console.log('done');
  };

  return (
    <div>
      <IonButton disabled={sorting} onClick={sortTracks}>
        {sorting ? `Sorting tracks...` : `Magic Sort`}
      </IonButton>
    </div>
  );
};

export default MagicSorter;
