import React, { useState, useRef } from 'react';
import {
  IonRouterLink,
  IonGrid,
  IonCol,
  IonIcon,
  IonRow,
  IonButton,
} from '@ionic/react';
import { CrateGridMini } from '../crates/crateGridMini/CrateGridMini';
import { CrateService } from '../../services/Crate.service';
import { useHistory } from 'react-router-dom';
import {
  colorWandOutline,
  scan,
  search,
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
} from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper';
import './Slide.scss';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import Slide from './Slide';
import videos from './videos.json';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import './Feed.scss';
import isElectron from '../../utils/isElectron';

const Feed: React.FC<{ scrollPosition: number }> = ({ scrollPosition }) => {
  const [selectedSegment, setSelectedSegment] = useState<string>('feed');
  const history = useHistory();
  const sliderRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <div className={'crate-grid-mini'}>
      {isElectron() && (
        <>
          <div className="header">
            <p>What do you want to do today?</p>
          </div>

          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="6" sizeLg="4">
                <IonRouterLink routerLink="/my-music">
                  <div className="card">
                    <div className="overlap-group">
                      <IonIcon icon={scan} size="large" color="primary" />
                      <div className="title">Scan</div>
                      <p className="text-wrapper">
                        To get the most out of Crate Hackers, you need to scan
                        your music library.
                      </p>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>

              <IonCol size="12" sizeMd="6" sizeLg="4">
                <IonRouterLink routerLink={'/crates'}>
                  <div className="card">
                    <div className="overlap-group">
                      <IonIcon icon={search} size="large" color="primary" />
                      <div className="title">Find</div>
                      <p className="text-wrapper">
                        Trending Crates, Charts, and Track Recommendations
                      </p>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>

              <IonCol size="12" sizeMd="12" sizeLg="4">
                <IonRouterLink routerLink={'/my-crates/home'}>
                  <div className="card">
                    <div className="overlap-group">
                      <IonIcon
                        icon={colorWandOutline}
                        size="large"
                        color="primary"
                      />
                      <div className="title">Build</div>
                      <p className="text-wrapper">
                        Build your own crate using our Recommendations
                      </p>
                    </div>
                  </div>
                </IonRouterLink>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      )}

      <div className="header">
        <p>For You</p>
        <IonGrid>
          <IonRow>
            <Swiper
              ref={sliderRef}
              modules={[Navigation]}
              slidesPerView={3}
              spaceBetween={20}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onBeforeInit={(swiper: SwiperType) => {
                const navigation = swiper.params
                  .navigation as NavigationOptions;
                navigation.prevEl = navigationPrevRef.current;
                navigation.nextEl = navigationNextRef.current;
              }}
            >
              {videos.map((item, index) => (
                <SwiperSlide key={index}>
                  <Slide
                    link={item.link}
                    videoId={item.videoId}
                    imgAlt={item.imgAlt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </IonRow>
          <IonRow>
            <div className="swiper-buttons">
              <IonButton ref={navigationPrevRef}>
                <IonIcon slot="icon-only" icon={arrowBackCircleOutline} />
              </IonButton>
              <IonButton ref={navigationNextRef}>
                <IonIcon slot="icon-only" icon={arrowForwardCircleOutline} />
              </IonButton>
            </div>
          </IonRow>
        </IonGrid>
      </div>

      <div className="header">
        <p>Latest Crates</p>
      </div>
      <CrateGridMini categories={[CrateService.CRATE_STANDARD]} limit={6} />
      <div className="header">
        <p>Latest Hackathons</p>
      </div>
      <CrateGridMini categories={[CrateService.CRATE_HACKATHON]} limit={6} />
    </div>
  );
};

export default Feed;
