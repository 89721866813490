import React, { useEffect, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import { iTrack } from '../../types/ITrack';
import { SearchControllerSearchRequest } from '@cratehackers/api-client';
import ApiService from '../../services/Api.service';

interface SearchComponentProps {
  handleClick: (value: string) => void;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ handleClick }) => {
  const [songData, setSongData] = useState<iTrack[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');

  useEffect(() => {
    if (searchQuery && searchQuery.length > 3) {
      const requestSongs: SearchControllerSearchRequest = {
        searchDto: {
          term: searchQuery,
          objectType: 'track',
          offset: 0,
          additionalParams: {
            markets: [],
          },
          raw: false,
          limit: 8,
        },
      };

      const apiClient = ApiService.getApiClient();
      apiClient.searchControllerSearch(requestSongs).then((results: any) => {
        if (results.items.length > 0) {
          const tracks = results.items.map(
            (track: any): iTrack => ({
              Artist: track?.artist?.name,
              Title: track?.title,
              AlbumCover: track?.album?.picture,
              Key_Camelot: track?.key,
              Key: track?.key,
              PreviewURL: track?.previewUrl,
              BPM: track?.bpm,
              ID: track?.uuid,
              keyMatches: {
                energyBoost: track?.keys?.EnergyBoost,
                energyDrop: track?.keys?.EnergyDrop,
                moodChange: track?.keys?.MoodChange,
                perfectMatch: track?.keys?.PerfectMatch,
              },
            }),
          );
          setSongData(tracks);
        } else {
          setSongData([]);
        }
      });
    } else {
      setSongData([]);
    }
  }, [searchQuery]);

  return (
    <IonRow>
      <IonCol>
        <IonInput
          value={searchQuery}
          onIonInput={(event) =>
            setSearchQuery(
              event.target.value ? event.target.value.toString() : '',
            )
          }
          placeholder="Search for a track"
        ></IonInput>
        {songData.map((track: iTrack) => (
          <IonItem
            onClick={() => {
              handleClick(track.ID);
              setSongData([]);
              setSearchQuery('');
            }}
          >
            <IonGrid>
              <IonRow>
                <IonCol size="8">
                  <IonText color="dark">
                    + {track.Title} - {track.Artist}
                  </IonText>
                </IonCol>
                <IonCol size="2">
                  <IonText color="medium">
                    BPM: {track.BPM && Math.round(track.BPM)}
                  </IonText>
                </IonCol>
                <IonCol size="2">
                  <IonText color="medium">Key: {track.Key_Camelot}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        ))}
      </IonCol>
    </IonRow>
  );
};

export default SearchComponent;
