import React, { useState, useEffect } from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
} from '@ionic/react';
import ReactMarkdown from 'react-markdown';
import './ChangeLog.scss';
import { config } from '../../config';

const ChangeLog: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [markdownData, setMarkdownData] = useState<string>('');

  useEffect(() => {
    fetch('./changelog.md')
      .then((response) => response.text())
      .then((data) => setMarkdownData(data))
      .catch((error) => console.error(error));
    if (localStorage.getItem('app-version') !== config.VERSION) {
      setShowModal(true);
    }
  }, []);

  const onClose = () => {
    localStorage.setItem('app-version', config.VERSION || '');
    setShowModal(false);
  };

  if (!showModal) {
    return null;
  }

  return (
    <IonModal
      isOpen={showModal}
      className="modal markdown-modal"
      onDidDismiss={onClose}
    >
      <IonContent>
        <IonToolbar>
          <IonTitle>Latest Updates</IonTitle>
          <IonButtons slot="end">
            <IonButton color="light" onClick={onClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <div className="links">
          <a
            href="https://cratehackers.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Website
          </a>
          |
          <a
            href="https://www.facebook.com/CrateHackers"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          |
          <a
            href="https://www.instagram.com/CrateHackers"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </div>
        <ReactMarkdown>{markdownData}</ReactMarkdown>
      </IonContent>
    </IonModal>
  );
};

export default ChangeLog;
