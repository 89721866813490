import { IonInput, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import buildStyle from '../build-path.module.scss';
import './serato.scss';
import ImportCrates from './import-crates';
import ImportHistory from './import-history';
import { iCrate } from '../../../../types/ICrate';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../../../../store/hooks';
import { iTrack } from '../../../../types/ITrack';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';

export default function SeratoPath() {
  const [activeSegment, setActiveSegment] = useState('history');
  const [crateName, setCrateName] = useState('');
  const [crateDescription, setCrateDescription] = useState('');
  const [trackList, setTrackList] = useState<iTrack[]>([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const buildCrate = () => {
    const newCrate: iCrate = {
      _id: '',
      Title: crateName,
      Description: crateDescription,
      Tracks: trackList,
      createdFrom: 'Serato',
    };

    return newCrate;
  };

  useEffect(() => {
    setCrateDescription('');
    setTrackList([]);
  }, [activeSegment]);

  useEffect(() => {
    setIsBtnDisabled(!crateName || trackList.length === 0);
  }, [crateName, trackList]);

  return (
    <>
      <div className={buildStyle.buildHeader}>
        <div>What should we name your crate?</div>
      </div>

      <IonInput
        className={buildStyle['build-input']}
        labelPlacement="floating"
        fill="outline"
        value={crateName}
        onIonInput={(e: any) => {
          setCrateName(e.target.value);
        }}
      >
        <div slot="label">Crate Name</div>
      </IonInput>

      <IonSegment value={activeSegment} style={{ marginBottom: '25px' }}>
        <IonSegmentButton
          value="history"
          onClick={() => setActiveSegment('history')}
        >
          <IonLabel>Import from Serato History</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton
          value="crates"
          onClick={() => setActiveSegment('crates')}
        >
          <IonLabel>Import from Serato Crate</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      {activeSegment === 'history' && (
        <ImportHistory
          setCrateDescription={setCrateDescription}
          setTrackList={setTrackList}
        />
      )}
      {activeSegment === 'crates' && (
        <ImportCrates
          setCrateDescription={setCrateDescription}
          setTrackList={setTrackList}
        />
      )}

      <BuildGenerateCrate
        buildCrateFn={buildCrate}
        isBtnDisabled={isBtnDisabled}
      />
    </>
  );
}
