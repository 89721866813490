import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AudioPlayerState {
  url: string;
  isPlaying: boolean;
}

const initialState: AudioPlayerState = {
  url: '',
  isPlaying: false,
};

export const AudioPlayerSlice = createSlice({
  name: 'audioPlayer',
  initialState: initialState,
  reducers: {
    setURL: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
    },
  },
});

export const { setURL, setPlaying } = AudioPlayerSlice.actions;

export default AudioPlayerSlice.reducer;
