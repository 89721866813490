import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { iLocalFile } from '../../types/ILocalFile';

interface TrackMatcherState {
  showMatcher: boolean;
  trackMatches: {
    [trackId: string]: iLocalFile[];
  };
  selectedMatches: {
    [trackId: string]: string[]; // trackId -> selected file paths
  };
}

const initialState: TrackMatcherState = {
  showMatcher: true,
  trackMatches: {},
  selectedMatches: {},
};

export const trackMatcherSlice = createSlice({
  name: 'trackMatcher',
  initialState,
  reducers: {
    setShowMatcher: (state, action: PayloadAction<boolean>) => {
      state.showMatcher = action.payload;
    },
    setTrackMatches: (
      state,
      action: PayloadAction<{
        trackId: string;
        files: {
          tags: any;
          isSelected: boolean;
          filePath: string;
          id: number;
          importSources?: string | undefined;
          isVideo?: boolean | undefined;
        }[];
      }>,
    ) => {
      // TODO: Implement on Track Matcher IPC response
      const { trackId, files } = action.payload;
      state.trackMatches[trackId] = files;
    },
    setSelectedTrackMatches: (
      state,
      action: PayloadAction<{ trackId: string; filePaths: string[] }>,
    ) => {
      const { trackId, filePaths } = action.payload;
      state.selectedMatches[trackId] = filePaths;
    },
    selectFirstMatches: (state) => {
      console.log('selectFirstMatches:', Object.entries(state.trackMatches));
      Object.entries(state.trackMatches).forEach(([trackId, files]) => {
        if (files.length > 0) {
          state.selectedMatches[trackId] = [files[0].filePath];
        }
      });
    },
    selectAllMatches: (state) => {
      Object.entries(state.trackMatches).forEach(([trackId, files]) => {
        if (files.length > 0) {
          state.selectedMatches[trackId] = files.map((f) => f.filePath);
        }
      });
    },
  },
});

export const {
  setShowMatcher,
  setTrackMatches,
  setSelectedTrackMatches,
  selectFirstMatches,
  selectAllMatches,
} = trackMatcherSlice.actions;

// Memoized selectors
export const selectShowMatcher = (state: RootState) =>
  state.trackMatcherState.showMatcher;

const selectTrackMatcherState = (state: RootState) => state.trackMatcherState;
const selectTrackId = (_state: RootState, trackId: string) => trackId;

// Memoized selectors
export const selectSelectedMatches = () => {
  return createSelector(
    [selectTrackMatcherState],
    (state: TrackMatcherState) => state.selectedMatches,
  );
};

export const selectTrackMatches = () => {
  return createSelector(
    [selectTrackMatcherState],
    (state: TrackMatcherState) => state.trackMatches,
  );
};

export default trackMatcherSlice.reducer;
