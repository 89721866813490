import React from 'react';
import ReactPlayer from 'react-player';
import style from './home.module.scss';

export default function HomePath() {
  return (
    <>
      <h1 className={style['build-path-home-title']}>Building A Crate</h1>
      <ReactPlayer
        url="./video/intro.mp4"
        playing={false}
        controls
        light="./video/intro.png"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              disablePictureInPicture: true,
            },
          },
        }}
        onContextMenu={(e: any) => e.preventDefault()}
        pip={false}
        height="80%"
        width="100%"
      />
    </>
  );
}
