import React, { useEffect } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { megaphoneOutline } from 'ionicons/icons';

interface BeamerTriggerProps {
  productId?: string;
  className?: string;
}

declare global {
  interface Window {
    Beamer?: {
      show: () => void;
      init: (config: any) => void;
    };
  }
}

const BeamerTrigger: React.FC<BeamerTriggerProps> = ({
  productId = process.env.REACT_APP_BEAMER_PRODUCT_ID,
  className,
}) => {
  useEffect(() => {
    // Initialize Beamer
    const script = document.createElement('script');
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.defer = true;
    script.onload = () => {
      if (window.Beamer && productId) {
        window.Beamer.init({
          product_id: productId,
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, [productId]);

  const openBeamer = () => {
    if (window.Beamer) {
      window.Beamer.show();
    } else {
      console.warn('Beamer is not initialized');
    }
  };

  return (
    <IonButton fill="clear" onClick={openBeamer} className={className}>
      <IonIcon icon={megaphoneOutline} />
    </IonButton>
  );
};

export default BeamerTrigger;
