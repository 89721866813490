/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UpdateCrateDto interface.
 */
export function instanceOfUpdateCrateDto(value) {
    return true;
}
export function UpdateCrateDtoFromJSON(json) {
    return UpdateCrateDtoFromJSONTyped(json, false);
}
export function UpdateCrateDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['Title'] == null ? undefined : json['Title'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'published': json['Published'] == null ? undefined : (new Date(json['Published'])),
        'hacker': json['Hacker'] == null ? undefined : json['Hacker'],
        'status': json['Status'] == null ? undefined : json['Status'],
        'category': json['Category'] == null ? undefined : json['Category'],
        'live': json['Live'] == null ? undefined : json['Live'],
        'image': json['Image'] == null ? undefined : json['Image'],
        'playlistID': json['PlaylistID'] == null ? undefined : json['PlaylistID'],
        'tracks': json['Tracks'] == null ? undefined : json['Tracks'],
        'type': json['Type'] == null ? undefined : json['Type'],
        'rekordcloud': json['Rekordcloud'] == null ? undefined : json['Rekordcloud'],
        'facebookLiveEmbed': json['FacebookLiveEmbed'] == null ? undefined : json['FacebookLiveEmbed'],
        'vDJ': json['VDJ'] == null ? undefined : json['VDJ'],
        'streamAppleMusic': json['StreamAppleMusic'] == null ? undefined : json['StreamAppleMusic'],
        'streamBeatsource': json['StreamBeatsource'] == null ? undefined : json['StreamBeatsource'],
        'streamDeezer': json['StreamDeezer'] == null ? undefined : json['StreamDeezer'],
        'streamSoundcloud': json['StreamSoundcloud'] == null ? undefined : json['StreamSoundcloud'],
        'streamSpotify': json['StreamSpotify'] == null ? undefined : json['StreamSpotify'],
        'streamTidal': json['StreamTidal'] == null ? undefined : json['StreamTidal'],
    };
}
export function UpdateCrateDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'Title': value['title'],
        'Description': value['description'],
        'Published': value['published'] == null ? undefined : ((value['published']).toISOString()),
        'Hacker': value['hacker'],
        'Status': value['status'],
        'Category': value['category'],
        'Live': value['live'],
        'Image': value['image'],
        'PlaylistID': value['playlistID'],
        'Tracks': value['tracks'],
        'Type': value['type'],
        'Rekordcloud': value['rekordcloud'],
        'FacebookLiveEmbed': value['facebookLiveEmbed'],
        'VDJ': value['vDJ'],
        'StreamAppleMusic': value['streamAppleMusic'],
        'StreamBeatsource': value['streamBeatsource'],
        'StreamDeezer': value['streamDeezer'],
        'StreamSoundcloud': value['streamSoundcloud'],
        'StreamSpotify': value['streamSpotify'],
        'StreamTidal': value['streamTidal'],
    };
}
