// LoginButton.tsx
import React, { useEffect, useState } from 'react';
import { IonButton, IonImg } from '@ionic/react';
import buttonStyle from './login-button.module.scss';
import { spotifyIcon } from '../Icons';
import {
  AppIdentifiers,
  unsetToken,
} from '../../store/slices/token-state.slice';
import { useDispatch } from '../../store/hooks';
import SpotifyService from '../../services/Spotify.service';
import isElectron from '../../utils/isElectron';
import { run } from '../../helper/electron';
import { useSelector } from 'react-redux';

interface LoginButtonProps {
  redirectPath?: string; // Optional prop to define the redirect path
  small?: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({
  redirectPath = '/',
  small = false,
}) => {
  const token = useSelector((state) => SpotifyService.getAccessToken());
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [profile, setProfile] = useState<{ display_name: string } | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      setProfile(null);
      setIsLoggedIn(false);
      return;
    }

    setIsLoggedIn(true);
    SpotifyService.getProfile().then((value: any) => {
      setProfile({ display_name: value.display_name });
    });
  }, [token]);

  const handleClick = async () => {
    if (isLoggedIn) {
      dispatch(unsetToken(AppIdentifiers.Spotify));
    } else {
      const authorizeUrl =
        await SpotifyService.getSpotifyAuthorizeUrl(redirectPath);

      if (isElectron()) {
        run('openExternalLink', authorizeUrl);
      } else {
        window.location.href = authorizeUrl;
      }
    }
  };

  return (
    <IonButton
      className={`${buttonStyle.spotifyLoginButton} ${small ? buttonStyle.small : ''}`}
      onClick={handleClick}
      fill="outline"
      expand="block"
    >
      <IonImg src={spotifyIcon} className={buttonStyle.spotifyIcon} />
      {isLoggedIn ? (
        <>
          <div>Log out as {profile?.display_name}</div>
        </>
      ) : (
        <div>Log in with Spotify</div>
      )}
    </IonButton>
  );
};

export default LoginButton;
