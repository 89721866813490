/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccessTokenDtoFromJSON, AccessTokenDtoToJSON, } from './AccessTokenDto';
/**
 * Check if a given object implements the MyPlaylistIdRequestDto interface.
 */
export function instanceOfMyPlaylistIdRequestDto(value) {
    if (!('playlistId' in value) || value['playlistId'] === undefined)
        return false;
    return true;
}
export function MyPlaylistIdRequestDtoFromJSON(json) {
    return MyPlaylistIdRequestDtoFromJSONTyped(json, false);
}
export function MyPlaylistIdRequestDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'playlistId': json['playlist_id'],
        'token': json['token'] == null ? undefined : AccessTokenDtoFromJSON(json['token']),
    };
}
export function MyPlaylistIdRequestDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'playlist_id': value['playlistId'],
        'token': AccessTokenDtoToJSON(value['token']),
    };
}
