// header
export const profileCircle = './assets/icon/profile-circle.svg';
export const playlist = './assets/icon/playlist.svg';
export const arrowCircleLeft = './assets/icon/arrow-circle-left.svg';
export const arrowCircleRight = './assets/icon/arrow-circle-right.svg';

export const arrowLeft = './assets/icon/arrow-left.svg';
// Sidebar
export const home = './assets/icon/home.svg';
export const allCrates = './assets/icon/all-crates.svg';
export const charts = './assets/icon/charts.svg';
export const myCrates = './assets/icon/my-crates.svg';
export const myMusic = './assets/icon/my-music.svg';
export const genres = './assets/icon/genres.svg';
export const hacks = './assets/icon/hacks.svg';
export const vipCrates = './assets/icon/vip-crates.svg';
export const spotify = './assets/icon/spotify.svg';
export const spotifyIcon = './assets/icon/spotify-icon-green.png';
export const spotifyLogo = './assets/icon/spotify-logo-green.png';
export const textImport = './assets/icon/text-import.svg';
export const shazamToCrate = './assets/icon/shazam-to-crate.svg';
export const folderFlattener = './assets/icon/folder-flattener.svg';
export const marketplace = './assets/icon/text-import.svg';

// Cloud crates
export const cloudCrates = './assets/icon/cloud-crates.svg';
export const spotifyFull = './assets/icon/spotify-full.svg';
export const apple = './assets/icon/apple.svg';
export const soundcloud = './assets/icon/soundcloud.svg';
export const tidal = './assets/icon/tidal.svg';
export const deezer = './assets/icon/deezer.svg';
export const beatsource = './assets/icon/beatsource.svg';

export const avatar = './assets/icon/avatar.svg';
export const whiteTrash = './assets/icon/white-trash.svg';
export const redTrash = './assets/icon/red-trash.svg';

// Media
export const arrowForward = './assets/icon/arrow-forward-outline';
export const arrowBackward = './assets/icon/arrow-back-outline';
export const playButton = './assets/icon/play-button.svg';
export const pauseButton = './assets/icon/pause-button.svg';
export const flameLogo = './assets/icon/icon.png';
export const seratoDJPro = './assets/icon/serato-dj-pro.svg';
export const rotateLeft = './assets/icon/rotate-left.svg';
export const uploadCloud = './assets/icon/upload-cloud.svg';
export const search = './assets/icon/search.svg';
export const plus = './assets/icon/plus.svg';
export const folders = './assets/icon/folders.svg';
export const bangerButtonLogo = './assets/icon/banger-button-logo.svg';
export const magicPlaylistLogo = './assets/icon/magic-playlist-logo.svg';
export const djsVaultLogo = './assets/icon/djs-vault-logo.svg';
export const dangerousDJsLogo = './assets/icon/dangerous-djs-logo.svg';
export const chLogoFull = './assets/icon/logo.png';

// PDF Pngs
export const pdfApple = './assets/icon/pdfCloudCratePNGS/Apple.png';
export const pdfBeatsource = './assets/icon/pdfCloudCratePNGS/Beatsource.png';
export const pdfDeezer = './assets/icon/pdfCloudCratePNGS/Deezer.png';
export const pdfSoundcloud = './assets/icon/pdfCloudCratePNGS/Soundcloud.png';
export const pdfSpotify = './assets/icon/pdfCloudCratePNGS/Spotify.png';
export const pdfTidal = './assets/icon/pdfCloudCratePNGS/Tidal.png';
export const pdfCloudCrates = './assets/icon/pdfCloudCratePNGS/CloudCrates.png';
