/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetSimpleCrateDto interface.
 */
export function instanceOfGetSimpleCrateDto(value) {
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('image' in value) || value['image'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('published' in value) || value['published'] === undefined)
        return false;
    if (!('hacker' in value) || value['hacker'] === undefined)
        return false;
    if (!('category' in value) || value['category'] === undefined)
        return false;
    if (!('trackCount' in value) || value['trackCount'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
export function GetSimpleCrateDtoFromJSON(json) {
    return GetSimpleCrateDtoFromJSONTyped(json, false);
}
export function GetSimpleCrateDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'type': json['Type'],
        'image': json['Image'],
        'title': json['Title'],
        'published': (new Date(json['Published'])),
        'hacker': json['Hacker'],
        'category': json['Category'],
        'trackCount': json['trackCount'],
        'id': json['_id'],
    };
}
export function GetSimpleCrateDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'Type': value['type'],
        'Image': value['image'],
        'Title': value['title'],
        'Published': ((value['published']).toISOString()),
        'Hacker': value['hacker'],
        'Category': value['category'],
        'trackCount': value['trackCount'],
        '_id': value['id'],
    };
}
