import React, { useState, useEffect } from 'react';
import {
  IonModal,
  IonButton,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonProgressBar,
  IonText,
} from '@ionic/react';
import './update.scss';
import { run } from '../../helper/electron';
import { HTMLIonOverlayElement } from '@ionic/core';
import isElectron from '../../utils/isElectron';

const Update: React.FC = () => {
  if (!isElectron()) {
    return <></>;
  }

  const [showModal, setShowModal] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [updateReady, setUpdateReady] = useState<boolean>(false);

  const closeAll = () => {
    // adjust selector to fit your needs
    const overlays = document.querySelectorAll(
      'ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast',
    );
    const overlaysArr = Array.from(overlays) as HTMLIonOverlayElement[];
    overlaysArr.forEach((o) => o.dismiss());
  };

  useEffect(() => {
    window.api?.on('update-available', (response: string) => {
      closeAll();
      setShowModal(true);
    });

    window.api?.on('update-progress', (response: string) => {
      // Parse the progress from response and update progress state
      const progressValue = parseFloat(response);
      setProgress(Math.round(progressValue));
    });

    window.api?.on('update-downloaded', (response: string) => {
      setUpdateReady(true);
    });

    run('update-check');
  }, []);

  const onClose = () => {
    setShowModal(false);
    setProgress(0);
    setUpdateReady(false);
  };

  const onInstall = () => {
    run('update-install');
  };

  if (!showModal) {
    return null;
  }

  return (
    <IonModal
      isOpen={showModal}
      className="modal update-modal"
      onDidDismiss={onClose}
    >
      <IonContent>
        <IonToolbar>
          <IonTitle>Update Available</IonTitle>
          <IonButtons slot="end">
            <IonButton color="light" onClick={onClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {updateReady ? (
          <div className="update-ready">
            <IonText className="update-message">
              A new version of CrateHackers X is ready!
            </IonText>
            <IonButton
              expand="block"
              onClick={onInstall}
              className="restart-button"
            >
              Update Now
            </IonButton>
          </div>
        ) : (
          <div className="update-progress">
            <IonText className="update-message">
              Downloading update: {progress}%
            </IonText>
            <IonProgressBar
              value={progress / 100}
              className="progress-bar"
            ></IonProgressBar>
          </div>
        )}
      </IonContent>
    </IonModal>
  );
};

export default Update;
