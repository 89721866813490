import { IonCol, IonGrid, IonItem, IonRow, IonText } from '@ionic/react';
import React from 'react';

import './Tracks.scss';
import { iTrack } from '../../types/ITrack';
import { useDispatch } from '../../store/hooks';
import { useHistory } from 'react-router-dom';
import { setSelectedTrack } from '../../store/slices/track.slice';

interface CondensedTrackViewProps extends iTrack {
  handleClose: () => void;
}

export const CondensedTrackView: React.FC<CondensedTrackViewProps> = ({
  handleClose,
  ...track
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onTrackClicked = () => {
    dispatch(setSelectedTrack(track));
    history.push('/track');
    handleClose();
  };

  return (
    <IonItem
      lines="none"
      className="condensed-track-view"
      onClick={onTrackClicked}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="3" className="ion-col-condensed">
            <img
              className="condensed-track-image"
              src={track.AlbumCover}
              alt={track.Title}
            />
          </IonCol>
          <IonCol size="8">
            <IonGrid>
              <IonCol className="condensed-track-text-container">
                <IonRow>
                  <IonText className="condensed-track-title">
                    {track.Title}
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonText className="condensed-track-artist">
                    {track.Artist}
                  </IonText>
                </IonRow>
              </IonCol>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};
