import { useEffect, useState } from 'react';
import FeatureInProgress from '../../../featureInProgress/FeatureInProgress';

export default function GenrePath() {
  const [selectedGenres, setSelectedGenres] = useState<any[]>([]);
  const [saveToMyCrates, setSaveToMyCrates] = useState(false);
  const [crateName, setCrateName] = useState('');
  const [genres, setGenres] = useState([
    { name: 'Rap' },
    { name: 'Hip Hop' },
    { name: 'Pop' },
    { name: 'Rock' },
  ]);

  useEffect(() => {
    console.log('selectedGenres', selectedGenres);
  }, [selectedGenres]);

  return (
    <>
      <FeatureInProgress />
      {/*<div className={buildStyle['build-header']}>*/}
      {/*  <div>What should we name your crate?</div>*/}
      {/*  <small>You can change this later</small>*/}
      {/*</div>*/}

      {/*<div className={style['crate-name-input']}>*/}
      {/*  <IonInput*/}
      {/*    onChange={(e: any) => setCrateName(e.target.value)}*/}
      {/*    className={buildStyle['build-input']}*/}
      {/*    placeholder="Enter crate name here"*/}
      {/*    value={crateName}*/}
      {/*    fill="outline"*/}
      {/*  />*/}
      {/*</div>*/}

      {/*<IonLabel className="link-label">Select a few genres</IonLabel>*/}

      {/*/!*THIS CAN BE A GRID WITH 3 COLS*!/*/}
      {/*<BuildItemSelect*/}
      {/*  items={genres}*/}
      {/*  onSelectedItemsChange={handleSelectedGenresChange}*/}
      {/*  selectionType="checkbox"*/}
      {/*  limit={3}*/}
      {/*/>*/}

      {/*<BuildGenerateCrate />*/}
    </>
  );

  function handleSelectedGenresChange(selectedGenres: any[]) {
    setSelectedGenres(selectedGenres);
  }
}
