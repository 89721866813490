/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TrackSearchDto interface.
 */
export function instanceOfTrackSearchDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('uuid' in value) || value['uuid'] === undefined)
        return false;
    if (!('artist' in value) || value['artist'] === undefined)
        return false;
    if (!('album' in value) || value['album'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('bpm' in value) || value['bpm'] === undefined)
        return false;
    if (!('key' in value) || value['key'] === undefined)
        return false;
    if (!('mode' in value) || value['mode'] === undefined)
        return false;
    if (!('popularity' in value) || value['popularity'] === undefined)
        return false;
    if (!('acousticness' in value) || value['acousticness'] === undefined)
        return false;
    if (!('danceability' in value) || value['danceability'] === undefined)
        return false;
    if (!('energy' in value) || value['energy'] === undefined)
        return false;
    if (!('instrumentalness' in value) || value['instrumentalness'] === undefined)
        return false;
    if (!('liveness' in value) || value['liveness'] === undefined)
        return false;
    if (!('loudness' in value) || value['loudness'] === undefined)
        return false;
    if (!('speechiness' in value) || value['speechiness'] === undefined)
        return false;
    if (!('valence' in value) || value['valence'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined)
        return false;
    if (!('deletedAt' in value) || value['deletedAt'] === undefined)
        return false;
    return true;
}
export function TrackSearchDtoFromJSON(json) {
    return TrackSearchDtoFromJSONTyped(json, false);
}
export function TrackSearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'uuid': json['uuid'],
        'artist': json['artist'],
        'album': json['album'],
        'title': json['title'],
        'bpm': json['bpm'],
        'key': json['key'],
        'mode': json['mode'],
        'keys': json['keys'] == null ? undefined : json['keys'],
        'popularity': json['popularity'],
        'acousticness': json['acousticness'],
        'danceability': json['danceability'],
        'energy': json['energy'],
        'instrumentalness': json['instrumentalness'],
        'liveness': json['liveness'],
        'loudness': json['loudness'],
        'speechiness': json['speechiness'],
        'valence': json['valence'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': (new Date(json['deleted_at'])),
    };
}
export function TrackSearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'uuid': value['uuid'],
        'artist': value['artist'],
        'album': value['album'],
        'title': value['title'],
        'bpm': value['bpm'],
        'key': value['key'],
        'mode': value['mode'],
        'keys': value['keys'],
        'popularity': value['popularity'],
        'acousticness': value['acousticness'],
        'danceability': value['danceability'],
        'energy': value['energy'],
        'instrumentalness': value['instrumentalness'],
        'liveness': value['liveness'],
        'loudness': value['loudness'],
        'speechiness': value['speechiness'],
        'valence': value['valence'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': ((value['deletedAt']).toISOString()),
    };
}
