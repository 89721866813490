import React, { useEffect, useState } from 'react';
import LoginButton from './login-button';
import { useDispatch } from '../../store/hooks'; // Typed dispatch
import {
  AppIdentifiers,
  getToken,
  setToken,
} from '../../store/slices/token-state.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import SpotifyService from '../../services/Spotify.service';
import style from './callback.module.scss';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonLabel,
  IonSpinner,
} from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

const Callback: React.FC = () => {
  const history = useHistory();
  let isFirstRun = true;
  const [redirectUrl, setRedirectUrl] = useState('');
  const token = useSelector(
    (state: RootState) => state.tokenState[AppIdentifiers.Spotify],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFirstRun || getToken(AppIdentifiers.Spotify).accessToken) {
      return;
    }
    isFirstRun = false;

    const fetchToken = async () => {
      // If we find a code, we're in a callback, do a token exchange
      const code = SpotifyService.getAuthCode();
      if (!code) {
        return;
      }
      handleTokenResponse(await SpotifyService.getToken(code));

      // Remove code from URL so we can refresh correctly.
      const url = new URL(window.location.href);
      url.searchParams.delete('code');

      // Redirect internally since we're done
      const redirect = window.localStorage.getItem('spotify_redirect');
      if (redirect !== null) {
        setRedirectUrl(redirect);
        setTimeout(() => {
          // You can also remove the item from localStorage after redirecting if it is no longer needed
          window.localStorage.removeItem('spotify_redirect');
          if (history) {
            history.push(redirect);
          } else {
            console.error('Navigate is not defined.');
          }
        }, 2500);
      }
    };
    const handleTokenResponse = (response: any) => {
      if (response.access_token) {
        console.log('expires_in', response);
        dispatch(
          setToken(
            AppIdentifiers.Spotify,
            response.access_token,
            response.refresh_token,
            Date.now() + Number(response.expires_in) * 1000,
          ),
        );
      }
    };
    fetchToken();
  }, []);

  return (
    <div className={style['callback-container']}>
      <IonCard className={style['callback-card']}>
        <IonCardHeader>
          <IonCardTitle>
            {!token.accessToken ? (
              <div>Login Failed</div>
            ) : (
              <div>Apps connected successfully</div>
            )}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {!token.accessToken ? (
            <>
              <div className={style['icon-container']}>
                <LoginButton />
              </div>
              <IonLabel>
                <div>Please try to sign in again.</div>
              </IonLabel>
            </>
          ) : (
            <>
              {redirectUrl ? (
                <>
                  <div className={style['icon-container']}>
                    <IonSpinner
                      name="circular"
                      className={style['spinner']}
                    ></IonSpinner>
                  </div>
                  <IonLabel>
                    <div>Redirecting you back to the app.</div>
                  </IonLabel>
                </>
              ) : (
                <>
                  <div className={style['icon-container']}>
                    <IonIcon icon={checkmarkCircleOutline} size="large" />
                  </div>
                  <IonLabel>
                    <div>You have signed in successfully!</div>
                  </IonLabel>
                </>
              )}
            </>
          )}
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default Callback;
