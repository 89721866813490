import { IonRouterLink } from '@ionic/react';
import { openExternalLink } from '../../utils/openExternalLink';

const Slide = ({ link, videoId, imgAlt }: any) => {
  return (
    <div className="slide-yt">
      <IonRouterLink onClick={() => openExternalLink(link)}>
        <img
          src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
          alt={imgAlt}
        />
      </IonRouterLink>
    </div>
  );
};

export default Slide;
