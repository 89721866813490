export const sha256 = (plain: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

export const base64encode = (arrayBuffer: ArrayBuffer) => {
  const byteArray = new Uint8Array(arrayBuffer);
  const byteString = Array.prototype.map
    .call(byteArray, (byte) => String.fromCharCode(byte))
    .join('');

  return btoa(byteString)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};
