import React, { useEffect, useState } from 'react';
import Pool from './Pool';
import './Pools.scss';
import { IonItemGroup, IonList } from '@ionic/react';
import ApiService from '../../services/Api.service';

// TODO: Implement mock data once api-client is ready
const mockData = [
  {
    Name: 'Late Night Record Pool',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1601060656621x227269681742446660/Screen%20Shot%202020-09-25%20at%201.17.32%20PM.png',
    SearchLink: 'https://latenightrecordpool.com/s/',
    SearchTitleOnly: true,
    Searchable: true,
    _id: '1601060649630x614826265849364500',
  },
  {
    Name: 'Direct Music Service',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1601060687273x430367878128120200/Screen%20Shot%202020-09-23%20at%203.16.01%20PM.png',
    SearchLink: 'https://www.directmusicservice.com/browse/filter/1?query=',
    Searchable: true,
    _id: '1601060682270x363768489294430200',
  },
  {
    Name: 'Crate Gang',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1601064051986x552934079519209300/Screen%20Shot%202020-09-25%20at%204.00.44%20PM.png',
    SearchLink: 'https://www.crategang.com/m/search.php?txtQuery=',
    SearchTitleOnly: false,
    Searchable: true,
    _id: '1601064029752x250421196571017200',
  },
  {
    Name: 'Heavy Hits',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1607203620052x180847918432250900/hh-black-sm.png',
    SearchLink: 'https://heavyhits.com/browse/#/search/&keyword=',
    Searchable: true,
    _id: '1607203623138x922028743167198300',
  },
  {
    Name: 'XtendaMix',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1615843536365x459759311090687700/161021349_914719655929835_8807081295401434018_n.jpg',
    SearchLink:
      'https://xtendamix.com/videos?genre=&order=artist&years=&keywords=',
    Searchable: true,
    _id: '1615843539405x734833294798868700',
  },
  {
    Name: 'BPM Supreme',
    Image: '',
    SearchLink: 'https://app.bpmsupreme.com/d/search?searchTerm=',
    Searchable: true,
    _id: '',
  },
  {
    Name: 'DJcity',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1617906833649x748323809235074300/2AbvxlrH2X.png',
    SearchLink: 'https://www.djcity.com/search?q=',
    SearchTitleOnly: true,
    Searchable: true,
    _id: '1617906807040x802701466984956400',
  },
  {
    Name: 'Digital Music Pool',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1618953578111x696945047957307800/Screen%20Shot%202021-04-20%20at%205.03.05%20PM.png',
    SearchLink: 'https://digitalmusicpool.com/search?sq=',
    SearchTitleOnly: true,
    Searchable: true,
    _id: '1618953589617x773594065578409100',
  },
  {
    Name: 'XMiX',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1619022930683x966474041936631800/Screen%20Shot%202021-04-21%20at%2012.35.18%20PM.png',
    SearchLink: 'http://www.xmixdigital.com/downloads.aspx?searchtext=',
    SearchTitleOnly: true,
    Searchable: true,
    _id: '1619022891203x610061316049270500',
  },
  {
    Name: 'Barbangerz',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1620275623762x817057156731177100/bb-banner.jpeg',
    SearchLink: 'https://www.barbangerz.com/browse.php?trsearch=',
    SearchTitleOnly: false,
    _id: '1620275429623x805631711437127700',
  },
  {
    Name: 'MyMP3Pool',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1636600400664x324780723261538050/Screen%20Shot%202021-11-10%20at%209.13.12%20PM.png',
    SearchLink: 'https://mp3poolonline.com/results?search=',
    SearchTitleOnly: false,
    _id: '1636600365358x479546222762262500',
  },
  {
    Name: 'Digital DJ Pool',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1643338460706x378197908941992260/download.png',
    SearchLink: 'https://digitaldjpool.com/record-pool/search?q=',
    SearchTitleOnly: false,
    _id: '1643338297674x653276762174128100',
  },
  {
    Name: 'ZIPDJ',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1646510567292x982533265116389600/Screen%20Shot%202022-03-05%20at%202.02.30%20PM.png',
    SearchLink: 'https://www.zipdj.com/app/search?q=',
    SearchTitleOnly: false,
    _id: '1646510561263x358580018195202050',
  },
  {
    Name: 'Club Killers',
    Image:
      '//s3.amazonaws.com/appforest_uf/f1646510770096x724480405463820500/Screen%20Shot%202022-03-05%20at%202.05.44%20PM.png',
    SearchLink:
      'https://www.clubkillers.com/store/index.php?_a=mp3Search&searchStr=',
    SearchTitleOnly: false,
    _id: '1646510751647x298673534426152960',
  },
  {
    Name: 'Headliner Music Club',
    SearchLink: 'https://headlinermusicclub.com/?post_type=audio&s=',
    SearchTitleOnly: true,
    _id: '1661448662865x926484638442192900',
  },
  {
    Name: 'Doing The Damage',
    SearchLink: 'https://doingthedamage.com/?s=',
    SearchTitleOnly: false,
    _id: '1661709907005x831151699543982100',
  },
  {
    Name: 'iDJPool',
    SearchLink: 'https://www.idjpool.com/downloads/?wpfb_s=',
    SearchTitleOnly: false,
    _id: '1675000245007x583065263952363500',
  },
  {
    Name: 'Urban DJ Pool',
    SearchLink: 'https://www.urbandjpool.com/downloads/?wpfb_s=',
    SearchTitleOnly: false,
    _id: '1675000487824x164375239099678720',
  },
  {
    Name: 'Country DJ Pool',
    SearchLink: 'https://www.countrydjpool.com/downloads/?wpfb_s=',
    SearchTitleOnly: false,
    _id: '1675000520156x993258815711084500',
  },
  {
    Name: 'Radio DJ Pool',
    SearchLink: 'https://www.radiodjpool.com/downloads/?wpfb_s=',
    SearchTitleOnly: false,
    _id: '1675000617584x978489110818193400',
  },
  {
    Name: 'MP3 For DJs',
    SearchLink: 'https://www.mp3fordjs.com/downloads/?wpfb_s=',
    SearchTitleOnly: false,
    _id: '1675000686871x645174501546131500',
  },
  {
    Name: 'Latino Music Pool',
    SearchLink: 'https://latinomusicpool.com/search?search=',
    SearchTitleOnly: false,
    _id: '1692104110757x438782837422555140',
  },
];

interface PoolsProps {
  artist: string;
  title: string;
}

const Pools: React.FC<PoolsProps> = ({ artist, title }) => {
  // State to store selected pools
  const [selectedPools, setSelectedPools] = useState<any[]>([]);
  const [searchablePools, setSearchablePools] = useState<string[]>([]);
  // Fetch selected pools from local storage on component mount
  useEffect(() => {
    const poolsFromStorage = localStorage.getItem('selectedPools');
    if (poolsFromStorage) {
      setSelectedPools(JSON.parse(poolsFromStorage));
    } else {
      setSelectedPools(mockData.map((pool) => pool.Name));
    }
  }, []);

  useEffect(() => {
    const chAPI = ApiService.getApiClient();
    chAPI.poolsControllerGetAllPools().then((poolList) => {
      setSearchablePools(poolList.pools);
    });
  }, []);

  return (
    <IonList id="pools-container">
      {mockData
        .filter((poolData) => selectedPools.includes(poolData.Name))
        .map((poolData) => {
          // Only render Pool if it's among the selected pools
          return (
            <Pool
              key={poolData._id}
              artist={artist}
              title={title}
              poolData={poolData}
              searchApi={searchablePools.includes(poolData.Name)}
            />
          );
        })}
    </IonList>
  );
};

export default Pools;
