/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KeysFromJSON, KeysToJSON, } from './Keys';
import { AlbumFromJSON, AlbumToJSON, } from './Album';
/**
 * Check if a given object implements the Track interface.
 */
export function instanceOfTrack(value) {
    if (!('artists' in value) || value['artists'] === undefined)
        return false;
    if (!('album' in value) || value['album'] === undefined)
        return false;
    if (!('key' in value) || value['key'] === undefined)
        return false;
    if (!('keys' in value) || value['keys'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('bpm' in value) || value['bpm'] === undefined)
        return false;
    if (!('durationMs' in value) || value['durationMs'] === undefined)
        return false;
    if (!('previewUrl' in value) || value['previewUrl'] === undefined)
        return false;
    return true;
}
export function TrackFromJSON(json) {
    return TrackFromJSONTyped(json, false);
}
export function TrackFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'artists': json['artists'],
        'album': AlbumFromJSON(json['album']),
        'key': json['key'],
        'keys': KeysFromJSON(json['keys']),
        'name': json['name'],
        'bpm': json['bpm'],
        'durationMs': json['duration_ms'],
        'previewUrl': json['preview_url'],
        'id': json['id'] == null ? undefined : json['id'],
    };
}
export function TrackToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'artists': value['artists'],
        'album': AlbumToJSON(value['album']),
        'key': value['key'],
        'keys': KeysToJSON(value['keys']),
        'name': value['name'],
        'bpm': value['bpm'],
        'duration_ms': value['durationMs'],
        'preview_url': value['previewUrl'],
        'id': value['id'],
    };
}
