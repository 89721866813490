import { TrackAvailabilityOptionDto } from '@cratehackers/api-client';
import { TrackDropdownItem } from './TrackDropdownItem';
import { IonItemGroup } from '@ionic/react';
import React from 'react';
import './TrackDropdown.scss';

interface TrackDropdownProps {
  artist: string;
  title: string;
  dropdownData: TrackAvailabilityOptionDto[];
}

export const TrackDropdown: React.FC<TrackDropdownProps> = ({
  artist,
  title,
  dropdownData,
}) => {
  return (
    <IonItemGroup className="dropdown-container">
      {dropdownData.map((dropdownItem) => {
        return (
          <TrackDropdownItem
            artist={artist}
            title={title}
            dropdownItem={dropdownItem}
          />
        );
      })}
    </IonItemGroup>
  );
};
