import React, { useState } from 'react';
import {
  IonPage,
  IonContent,
  IonButton,
  IonToast,
  IonIcon,
  IonRow,
  IonCol,
  IonGrid,
  IonTextarea,
} from '@ionic/react';
import {
  bulbOutline,
  checkmarkCircleOutline,
  refreshCircle,
} from 'ionicons/icons'; // Import the checkmark and refresh icons
import ApiService from '../../services/Api.service';
import { FeedbackControllerFeatureRequestRequest } from '@cratehackers/api-client';

import feedbackStyles from './feedback.module.scss';
import style from '../folder-flattener/folder-flattener.module.scss';

const FeatureRequestPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // State for showing the checkmark
  const [description, setDescription] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastColour, setToastColour] = useState('');
  const apiClient = ApiService.getApiClient();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const featureRequest: FeedbackControllerFeatureRequestRequest = {
        featureRequestDto: {
          description,
        },
      };

      await apiClient.feedbackControllerFeatureRequest(featureRequest);

      setShowSuccess(true); // Show success checkmark
      setTimeout(() => setShowSuccess(false), 10000); // Hide checkmark after 10 seconds
      clearForm(); // Clear the form
      setToastMessage(
        'Feature request has been sent! Thank you for helping make CrateHackersX better!',
      );
      setToastColour('success');
      setShowToast(true); // Show success toast
    } catch (error) {
      console.error('Error submitting feature request:', error);
      setToastMessage('An error occurred. Please try again later.');
      setToastColour('danger');
      setShowToast(true); // Show danger toast
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setDescription('');
  };

  return (
    <IonPage className={feedbackStyles['page']}>
      <IonContent fullscreen>
        <IonGrid className={feedbackStyles['grid']}>
          <IonRow className={feedbackStyles['header-row']}>
            <IonCol>
              <div className={style['page-header']}>
                <h1 className={style['title']}>
                  <IonIcon icon={bulbOutline} style={{ marginRight: '10px' }} />
                  Feature Request
                </h1>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={feedbackStyles['form-row']}>
            <IonCol size="12" sizeMd="6" offsetMd="0">
              <div className={feedbackStyles['form-field']}>
                <IonTextarea
                  className={feedbackStyles['input']}
                  labelPlacement="floating"
                  fill="outline"
                  autoGrow={true}
                  value={description}
                  onIonChange={(e) => setDescription(e.detail.value!)}
                  required
                >
                  <div slot="label">
                    Describe the feature you'd like to see implemented.
                  </div>
                </IonTextarea>
              </div>
              <IonButton
                expand="block"
                onClick={handleSubmit}
                disabled={isLoading} // Disable button while loading
                className={feedbackStyles['submit-button']}
              >
                {isLoading ? (
                  <IonIcon slot="start" icon={refreshCircle} />
                ) : showSuccess ? (
                  <IonIcon
                    slot="start"
                    icon={checkmarkCircleOutline}
                    color="success"
                  />
                ) : (
                  'Submit Feature Request'
                )}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {showToast && toastMessage && toastColour && (
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={10000} // 10 seconds
            color={toastColour}
            position="top"
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default FeatureRequestPage;
