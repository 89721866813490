import { IonIcon, IonInput, IonLabel } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import buildStyle from '../build-path.module.scss';
import style from './track.module.scss';
import { SearchControllerSearchRequest } from '@cratehackers/api-client';
import { iTrack } from '../../../../types/ITrack';
import ApiService from '../../../../services/Api.service';
import { iCrate } from '../../../../types/ICrate';

export default function TrackPath() {
  const [selectedTrack, setSelectedTrack] = useState<iTrack>();
  const [searchResults, setSearchResults] = useState<iTrack[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [crateName, setCrateName] = useState<string>('');
  const dispatch = useDispatch();
  const history = useHistory();
  const apiClient = ApiService.getApiClient();
  const [searchLoading, setSearchLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 2) {
      search(searchTerm);
    } else if (searchTerm.length == 0) {
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    setIsBtnDisabled(!crateName || !selectedTrack);
  }, [crateName, selectedTrack]);

  const buildCrate = () => {
    const newCrate: iCrate = {
      _id: '',
      Title: crateName,
      Description: 'Created by searching for: ' + searchTerm,
      Tracks: [selectedTrack as iTrack],
      createdFrom: 'Track',
    };

    return newCrate;
  };

  return (
    <>
      <div className={buildStyle.buildHeader}>
        <div>What should we name your crate?</div>
      </div>

      <div className={buildStyle['build-input-wrapper']}>
        <IonInput
          className={buildStyle['build-input']}
          labelPlacement="floating"
          fill="outline"
          value={crateName}
          onIonInput={(e: any) => {
            setCrateName(e.target.value);
          }}
        >
          <div slot="label">Crate Name</div>
        </IonInput>
      </div>

      <div className={buildStyle.linkContainer}>
        <IonLabel className="link-label">
          Search for an artist or track title
        </IonLabel>

        <div className={buildStyle['build-input-wrapper']}>
          <IonInput
            className={buildStyle['build-input']}
            labelPlacement="floating"
            fill="outline"
            value={searchTerm}
            onIonInput={(e: any) => {
              setSearchTerm(e.target.value);
            }}
          >
            <div slot="label">Artist - Title</div>
          </IonInput>
        </div>
      </div>

      <div className={style['track-results']}>
        {searchResults &&
          searchResults.map((result: any, index: number) => (
            <div
              key={index}
              className={`${style['result-item']} ${
                selectedTrack && selectedTrack?.ID === result.ID
                  ? style['result-item-active']
                  : ''
              }`}
              onClick={() => {
                setSelectedTrack(result);
              }}
            >
              <img src={result.AlbumCover} alt={result.Title} />
              <div className={style['result-label']}>
                <div className={style['title']}>{result.Title}</div>
                <div className={style['artist']}>{result.Artist}</div>
              </div>

              <div className={style['result-selected']}>
                {selectedTrack && selectedTrack?.ID === result.ID && (
                  <IonIcon icon={checkmarkCircle} size="large" />
                )}
              </div>
            </div>
          ))}
      </div>

      <BuildGenerateCrate
        buildCrateFn={buildCrate}
        isBtnDisabled={isBtnDisabled}
      />
    </>
  );

  async function search(term: string) {
    try {
      const requestSongs: SearchControllerSearchRequest = {
        searchDto: {
          term: term,
          objectType: 'track',
          offset: 0,
          additionalParams: {
            markets: [],
          },
          raw: false,
          limit: 8,
        },
      };

      setSearchLoading(true);

      apiClient
        .searchControllerSearch(requestSongs)
        .then((results: any) => {
          let tracks: iTrack[] = [];
          if (results.items.length > 0) {
            tracks = results.items.map(
              (track: any): iTrack => ({
                Artist: track?.artist?.name,
                Title: track?.title,
                AlbumCover: track?.album?.picture,
                Key_Camelot: track?.key,
                Key: track?.key,
                PreviewURL: track?.previewUrl,
                BPM: track?.bpm,
                ID: track?.uuid,
                keyMatches: {
                  energyBoost: track?.keys?.EnergyBoost,
                  energyDrop: track?.keys?.EnergyDrop,
                  moodChange: track?.keys?.MoodChange,
                  perfectMatch: track?.keys?.PerfectMatch,
                },
              }),
            );
          }
          setSearchResults(tracks);
          setSearchLoading(false);
        })
        .catch(() => {
          setSearchResults([]);
        });
    } catch (e) {
      console.log(e);
    }
  }
}
