/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ResetPasswordDto interface.
 */
export function instanceOfResetPasswordDto(value) {
    if (!('token' in value) || value['token'] === undefined)
        return false;
    if (!('password' in value) || value['password'] === undefined)
        return false;
    return true;
}
export function ResetPasswordDtoFromJSON(json) {
    return ResetPasswordDtoFromJSONTyped(json, false);
}
export function ResetPasswordDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'token': json['token'],
        'password': json['password'],
    };
}
export function ResetPasswordDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'token': value['token'],
        'password': value['password'],
    };
}
