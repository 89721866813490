import React, { useState } from 'react';
import { run } from '../../helper/electron';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import {
  chevronForward,
  chevronForwardCircleOutline,
  closeOutline,
} from 'ionicons/icons';
import { folders } from '../../components/Icons';
import style from './folder-flattener.module.scss';

export type FolderData = {
  path: string;
  dirSize: number;
  files: string[];
};

type SelectFolderProps = {
  callback: (data: any) => void;
};

const Folders: React.FC<SelectFolderProps> = ({ callback }) => {
  const [selectedFolders, setSelectedFolders] = useState<FolderData[]>([]);
  const [selectFoldersRunning, setSelectFoldersRunning] = useState(false);

  const selectFoldersButton = async () => {
    if (selectFoldersRunning) {
      return;
    }
    setSelectFoldersRunning(true);
    setTimeout(() => setSelectFoldersRunning(false), 1000);

    const removeDuplicates = (folders: FolderData[]) => {
      const uniquePaths = new Set<string>();
      return folders.filter((folder) => {
        if (!uniquePaths.has(folder.path)) {
          uniquePaths.add(folder.path);
          return true;
        }
        return false;
      });
    };

    const updateSelectedFolders = (newFolders: FolderData[]) => {
      setSelectedFolders((prevFolders) =>
        removeDuplicates([...prevFolders, ...newFolders]),
      );
    };

    const openDialog = (response: FolderData[]) => {
      // console.log('handleResponse:', response);
      const newFolders = response.filter(
        (folder) =>
          !selectedFolders.some(
            (selectedFolder) => selectedFolder.path === folder.path,
          ),
      );
      updateSelectedFolders(newFolders);
      setSelectFoldersRunning(false);
    };

    window.api?.once('open-directory-dialog', openDialog);
    run('open-directory-dialog', {
      options: [
        'openDirectory',
        'multiSelections',
        'createDirectory',
        'promptToCreate',
      ],
    });
  };

  const callbackButton = async () => {
    callback(selectedFolders);
  };

  const deleteFolder = (path: string) => {
    setSelectedFolders(
      selectedFolders.filter((folder) => folder.path !== path),
    );
  };

  const formatSize = (sizeInBytes: number): string => {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 1024) {
      const sizeInGB = sizeInMB / 1024;
      return sizeInGB.toFixed(2) + ' GB';
    }
    return sizeInMB.toFixed(2) + ' MB';
  };

  return (
    <>
      <div className={style['select-folder']}>
        <div className={'page-header'}>
          <h1>Select Folders To Flatten</h1>
          <div id="actions" className="button-group">
            <button
              className={'primary ' + style['select-folder-button']}
              onClick={selectFoldersButton}
            >
              <IonIcon className="ion-icon" icon={folders} /> Add Folders
            </button>
          </div>
        </div>
        {selectedFolders && (
          <>
            <IonRow key={'header'} className={style['table-header-container']}>
              <IonCol className={style['table-header']}>Folder</IonCol>
              <IonCol className={style['table-header-files']}>
                Total Files
              </IonCol>
              <IonCol className={style['table-header-size']}>Total Size</IonCol>
              <IonCol className={style['table-header-actions']}>Actions</IonCol>
            </IonRow>
            <div className={style['select-folder-list']}>
              {selectedFolders.map((folder, index) => {
                const pathArray = folder.path.split(/[\\/]/);
                const lastElement = pathArray.pop();
                const remainingPath = pathArray.join('/');
                return (
                  <IonRow key={index} className={style['table-container']}>
                    <IonCol className={style['folder-path']}>
                      <p className={style['path-subtext']}>{remainingPath}/</p>
                      <h3>{lastElement}</h3>
                    </IonCol>
                    <IonCol className={style['table-column-files']}>
                      <p>{folder.files.length}</p>
                    </IonCol>
                    <IonCol className={style['table-column-size']}>
                      <p>{formatSize(folder.dirSize)}</p>
                    </IonCol>
                    <IonCol className={style['table-column-action']}>
                      <button
                        className={'primary ' + style['remove-button']}
                        onClick={() => deleteFolder(folder.path)}
                      >
                        <IonIcon icon={closeOutline} /> Remove
                      </button>
                    </IonCol>
                  </IonRow>
                );
              })}
            </div>
          </>
        )}
      </div>
      <IonRow className={style['action-container']}>
        <IonCol
          size="12"
          sizeLg="6"
          offsetLg="6"
          className={`${style['align-center']} ${style['full-width']}`}
        >
          {selectedFolders.length > 0 && (
            <IonButton onClick={callbackButton}>
              Next
              <IonIcon icon={chevronForward} slot="end" />
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  );
};

export default Folders;
