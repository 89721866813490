import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

export enum AppIdentifiers {
  App = 'app',
  Spotify = 'spotify',
}

export interface TokenState {
  [key: string]: {
    accessToken: string | null;
    refreshToken: string | null;
    expiration: number | null;
  };
}

const getTokenFromLocalStorage = (
  tokenType: string,
): {
  accessToken: string | null;
  refreshToken: string | null;
  expiration: number | null;
} => {
  const tokenString = localStorage.getItem(`${tokenType}`);
  if (tokenString) {
    const tokenObj = JSON.parse(tokenString);
    return {
      accessToken: tokenObj.accessToken ?? null,
      refreshToken: tokenObj.refreshToken ?? null,
      expiration: Number(tokenObj.expiration) ?? null,
    };
  } else {
    return { accessToken: null, refreshToken: null, expiration: null };
  }
};

const initialState: TokenState = {
  app: getTokenFromLocalStorage('app'),
  spotify: getTokenFromLocalStorage('spotify'),
};

export const TokenStateSlice = createSlice({
  name: 'tokenState',
  initialState,
  reducers: {
    setTokenState: (
      state,
      action: PayloadAction<{
        type: string;
        accessToken: string | null;
        refreshToken: string | null;
        expiration: number | null;
      }>,
    ) => {
      const { type, accessToken, refreshToken, expiration } = action.payload;
      state[type] = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiration: expiration,
      };
    },
  },
});

export const { setTokenState } = TokenStateSlice.actions;

export const unsetToken =
  (type: string): AppThunk =>
  (dispatch) => {
    dispatch(setToken(type, null, null, null));
  };

export const setToken =
  (
    type: string,
    accessToken: string | null,
    refreshToken: string | null,
    expiration: number | null,
  ): AppThunk =>
  (dispatch) => {
    if (accessToken) {
      const tokenObject = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiration: expiration,
      };
      localStorage.setItem(`${type}`, JSON.stringify(tokenObject));
      dispatch(setTokenState({ type, accessToken, refreshToken, expiration }));
    } else {
      localStorage.removeItem(`${type}`);
      dispatch(
        setTokenState({
          type,
          accessToken: null,
          refreshToken: null,
          expiration: null,
        }),
      );
    }
  };

export interface TokenData {
  accessToken: string | null;
  refreshToken: string | null;
  expiration: number | null;
}
export const getToken = (type: string): TokenData => {
  const tokenString = localStorage.getItem(`${type}`);
  if (tokenString) {
    const tokenObj = JSON.parse(tokenString);
    return {
      accessToken: tokenObj.accessToken,
      refreshToken: tokenObj.refreshToken,
      expiration: Number(tokenObj.expiration),
    };
  } else {
    return { accessToken: null, refreshToken: null, expiration: null };
  }
};

export default TokenStateSlice.reducer;
