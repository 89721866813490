import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import {
  alertOutline,
  caretDownOutline,
  caretUpOutline,
  reorderThreeOutline,
  trashBinOutline,
} from 'ionicons/icons';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { iTrack } from '../../types/ITrack';
import Waveform from '../waveform/Waveform';
import './Tracks.scss';
import isElectron from '../../utils/isElectron';
import { run } from '../../helper/electron';
import sha256 from 'crypto-js/sha256';
import Pools from '../pools/Pools';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowMatcher,
  setSelectedTrackMatches,
  setTrackMatches,
} from '../../store/slices/track-matcher.slice';

import { TrackAvailabilityOptionDto } from '@cratehackers/api-client';
import { TrackDropdown } from '../trackDropdown/TrackDropdown';

interface ElectronFile extends File {
  path: string;
}

interface iLocalFile {
  filePath: string;
  id: number;
  importSources?: string;
  tags?: Record<string, any>;
  isVideo?: boolean;
  isSelected?: boolean;
}

export const Track: React.FC<
  iTrack & {
    trackNum?: number;
    dragHandleProps?: any;
    editable?: boolean;
    initiallyExpanded?: boolean;
    onExpandedChange?: () => void;
    onSelectionChange?: (ID: string, updatedMatches: iLocalFile[]) => void;
    resourceOptions: TrackAvailabilityOptionDto[];
    streamingOptions: TrackAvailabilityOptionDto[];
    purchaseOptions: TrackAvailabilityOptionDto[];
    cloudStorageOptions: TrackAvailabilityOptionDto[];
    showTrackInfo?: boolean;
  }
> = ({
  ID,
  Artist,
  Title,
  AlbumCover,
  duration,
  BPM,
  energy,
  danceability,
  Key_Camelot,
  valence,
  PreviewURL,
  trackNum,
  unmixable,
  dragHandleProps,
  editable,
  onSelectionChange,
  resourceOptions,
  purchaseOptions,
  streamingOptions,
  cloudStorageOptions,
  showTrackInfo = true,
  expanded = false,
}) => {
  const dispatch = useDispatch();
  const showMatcher = useSelector(selectShowMatcher);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [currentDropdown, setCurrentDropdown] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [matchedTracks, setMatchedTracks] = useState<iLocalFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const trackRef = useRef(null);
  const matchesLoadedRef = useRef(false);

  // Memoize the track ID to ensure stability
  const trackId = useMemo(
    () =>
      ID || sha256(JSON.stringify({ artist: Artist, title: Title })).toString(),
    [ID, Artist, Title],
  );

  // Create a stable listener reference
  const listenerRef = useRef<any>(null);

  // Update the dropdown handling
  const handleExpandedDropdownChange = (dropdown: string) => {
    if (dropdown === 'tracks') {
      setIsExpanded((expanded) => !expanded);
      loadTrackMatches();
    }
    setCurrentDropdown(dropdown === currentDropdown ? '' : dropdown);
  };

  // Update the selection handling
  const handleSelectionChange = (matchId: number, isSelected: boolean) => {
    setMatchedTracks((currentMatches) => {
      const updatedMatches = currentMatches.map((match) =>
        match.id === matchId ? { ...match, isSelected } : match,
      );

      // Update Redux with selected file paths
      const selectedPaths = updatedMatches
        .filter((match) => match.isSelected)
        .map((match) => match.filePath);

      dispatch(
        setSelectedTrackMatches({ trackId: ID, filePaths: selectedPaths }),
      );
      return updatedMatches;
    });
  };

  const handleMatches = useCallback(
    (newMatches: iLocalFile[]) => {
      if (
        newMatches &&
        JSON.stringify(newMatches) !== JSON.stringify(matchedTracks)
      ) {
        const processedMatches = newMatches.map((match) => {
          const parsedTags =
            typeof match.tags === 'string'
              ? JSON.parse(match.tags)
              : match.tags;
          return { ...match, tags: parsedTags, isSelected: false };
        });

        setMatchedTracks(processedMatches);
        dispatch(setTrackMatches({ trackId, files: processedMatches }));
        matchesLoadedRef.current = true;
      }
      setIsLoading(false);
      setCurrentDropdown('tracks');
      setIsExpanded(true);
    },
    [matchedTracks, trackId],
  );

  useEffect(() => {
    setIsExpanded(showMatcher);
  }, [showMatcher]);

  // Utility function to load track matches
  const loadTrackMatches = useCallback(() => {
    if (!isElectron()) {
      return;
    }

    if (!matchesLoadedRef.current && (Artist || Title)) {
      console.log(`Loading track matches for ${trackId}`);

      // Remove previous listener if it exists
      if (listenerRef.current) {
        window.api?.removeListener(
          `getTrackMatches:${trackId}`,
          listenerRef.current,
        );
      }

      // Create and store new listener
      listenerRef.current = handleMatches;
      window.api?.on(`getTrackMatches:${trackId}`, listenerRef.current);

      setIsLoading(true);
      run('getTrackMatches', { artist: Artist, title: Title, id: trackId });
    }
  }, [Artist, Title, trackId]);

  // Cleanup listeners when component unmounts or track changes
  useEffect(() => {
    return () => {
      if (listenerRef.current) {
        window.api?.removeListener(
          `getTrackMatches:${trackId}`,
          listenerRef.current,
        );
        listenerRef.current = null;
      }
    };
  }, [trackId]);

  // Reset state when track info changes
  useEffect(() => {
    matchesLoadedRef.current = false;
    setMatchedTracks([]);
    setIsLoading(false);

    // Clean up previous listener
    if (listenerRef.current) {
      window.api?.removeListener(
        `getTrackMatches:${trackId}`,
        listenerRef.current,
      );
      listenerRef.current = null;
    }
  }, [Artist, Title, trackId]);

  useEffect(() => {
    if (!ID) {
      ID = sha256(JSON.stringify({ artist: Artist, title: Title })).toString();
    }

    // Build the selectedTracks array by filtering matchedTracks based on isSelected value
    const selectedTracks = matchedTracks.filter((track) => track.isSelected);

    // Call onSelectionChange with the id and selectedTracks
    if (onSelectionChange) {
      onSelectionChange(ID, selectedTracks);
    }
  }, [matchedTracks]);

  // TODO: We should remember the selected matched track for each crates track
  // Effect to handle automatic checkbox check based on PreviewURL
  useEffect(() => {
    if (matchedTracks && PreviewURL) {
      matchedTracks.forEach((track) => {
        if (!track.isSelected && track.filePath === PreviewURL) {
          handleSelectionChange(track.id, true);
        }
      });
    }
  }, [matchedTracks, PreviewURL]);

  const addLocalFile = (filePath: string) => {
    const newTrack: iLocalFile = {
      filePath: filePath,
      id: new Date().getTime() * filePath.length, // Temp for now. Low collision
    };

    const newListener = window.api?.once(
      `my-music/scan-track:${newTrack.id}`,
      (response) => {
        const updatedTrack: iLocalFile = {
          ...response,
          isSelected: true,
        };
        handleMatches([updatedTrack]);
      },
    );
    run(`my-music/scan-track`, { track: newTrack });

    return () => {
      window.api.removeListener(
        `my-music/scan-track:${newTrack.id}`,
        newListener,
      );
    };
  };

  const handleTrackUpload = (e: any) => {
    const files = e.target.files;
    if (files.length) {
      Array.from(files).forEach((file) => {
        const electronFile = file as ElectronFile;
        if (!(electronFile && electronFile.path)) {
          return;
        }
        addLocalFile(electronFile.path);
      });
    }
  };

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLIonInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
    },
    [],
  );

  const handleDropAddFile = useCallback(
    (event: React.DragEvent<HTMLIonInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;

      if (files.length) {
        Array.from(files).forEach((file) => {
          const electronFile = file as ElectronFile;
          if (!(electronFile && electronFile.path)) {
            return;
          }
          addLocalFile(electronFile.path);
        });
      }
    },
    [],
  );

  const onDeleteTrack = useCallback(
    (event: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => {
      console.log('onDeleteTrack:', event);
      // TODO: Implement delete Track
      event.preventDefault();
      event.stopPropagation();
    },
    [],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && isExpanded && !matchesLoadedRef.current) {
          loadTrackMatches();
        }
      },
      {
        root: null,
        threshold: 0.1,
      },
    );

    if (trackRef.current) {
      observer.observe(trackRef.current);
    }

    return () => {
      if (trackRef.current) {
        observer.unobserve(trackRef.current);
      }
    };
  }, [Artist, Title, isExpanded, loadTrackMatches]);

  return (
    <>
      <IonRow
        class={`crate-track ${unmixable ? 'unmixable' : ''}`}
        data-id={ID}
        onDragOver={handleDragOver}
        onDrop={handleDropAddFile}
        ref={trackRef}
      >
        <IonGrid fixed={true}>
          {showTrackInfo && (
            <IonRow className="track-details">
              <IonCol size="12" sizeMd="6" sizeLg="5">
                <IonGrid>
                  <IonRow>
                    <IonCol size="auto" className="track-number-container">
                      <div {...dragHandleProps}>
                        {editable && (
                          <IonIcon
                            id="drag-handle"
                            color="primary"
                            icon={reorderThreeOutline}
                          />
                        )}
                      </div>
                      <div className="track-circle">
                        <IonText className="track-number" color="dark">
                          {trackNum}
                        </IonText>
                      </div>
                      {/*TODO: Add deleting to admin view eventually*/}
                      {editable && (
                        <div className="track-delete">
                          <IonIcon
                            className="track-delete-icon"
                            color="primary"
                            icon={trashBinOutline}
                            onClick={onDeleteTrack}
                          />
                        </div>
                      )}
                    </IonCol>
                    {AlbumCover && (
                      <IonCol size="auto">
                        <img
                          className="album-cover"
                          src={AlbumCover}
                          alt={'album cover for track'}
                        />
                      </IonCol>
                    )}
                    <IonCol>
                      <IonRow className="song-details-container">
                        <IonText color="dark">
                          <h1>{Title}</h1>
                        </IonText>
                      </IonRow>
                      <IonRow className="song-details-container">
                        <IonText color="medium">
                          <p>{Artist}</p>
                        </IonText>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>

              <IonCol size="12" sizeMd="6" sizeLg="4">
                <IonRow className="main-audio-feature-row">
                  {Key_Camelot !== undefined && Key_Camelot !== '' && (
                    <IonCol>
                      <IonText
                        className="track-data-spec-key audio-feature-container"
                        color="primary"
                      >
                        Key
                      </IonText>
                      <div className="audio-feature-container">
                        {Key_Camelot}
                      </div>
                    </IonCol>
                  )}
                  {BPM !== undefined && BPM !== 0 && (
                    <IonCol>
                      <IonText
                        className="track-data-spec-key audio-feature-container"
                        color="primary"
                      >
                        BPM
                      </IonText>
                      <div className="audio-feature-container">{BPM}</div>
                    </IonCol>
                  )}
                  {energy !== undefined && energy !== 0 && (
                    <IonCol>
                      <IonText
                        className="track-data-spec-key audio-feature-container"
                        color="primary"
                      >
                        Energy
                      </IonText>
                      <div className="audio-feature-container">{energy}</div>
                    </IonCol>
                  )}
                  {danceability !== undefined && danceability !== 0 && (
                    <IonCol>
                      <IonText
                        className="track-data-spec-key audio-feature-container"
                        color="primary"
                      >
                        Dance
                      </IonText>
                      <div className="audio-feature-container">
                        {danceability}
                      </div>
                    </IonCol>
                  )}
                  <IonCol>
                    {valence !== undefined && valence !== 0 && (
                      <>
                        <IonText
                          className="track-data-spec-key audio-feature-container"
                          color="primary"
                        >
                          Mood
                        </IonText>
                        <div className="audio-feature-container">{valence}</div>
                      </>
                    )}
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size="12" sizeLg="3">
                {PreviewURL ? (
                  <div className="center-content preview-container">
                    <IonCol className="waveform-preview">
                      <Waveform height={35} url={PreviewURL} />
                    </IonCol>
                  </div>
                ) : (
                  <div className="no-preview-text">
                    <IonText color="medium">No Preview Available</IonText>
                  </div>
                )}
              </IonCol>
            </IonRow>
          )}

          <IonRow className="actions" color="light">
            {isElectron() && (
              <IonCol size="auto" className="dropdown-button-container">
                <IonButton
                  onClick={() => handleExpandedDropdownChange('tracks')}
                  fill="clear"
                  className="dropdown-button-expander"
                >
                  Your Matching Files{' '}
                  {matchedTracks?.length > 0 && `(${matchedTracks.length})`}
                  {(!matchedTracks || matchedTracks.length === 0) &&
                  isLoading ? (
                    <IonSpinner slot="end" name="dots" />
                  ) : currentDropdown === 'tracks' && isExpanded ? (
                    <IonIcon
                      className="dropdown-collapse-carrot"
                      slot="end"
                      icon={caretUpOutline}
                    ></IonIcon>
                  ) : (
                    <IonIcon
                      className="dropdown-expand-carrot"
                      slot="end"
                      icon={caretDownOutline}
                    ></IonIcon>
                  )}
                </IonButton>
              </IonCol>
            )}
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('pools')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Pools
                {currentDropdown === 'pools' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('streaming')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Streaming
                {currentDropdown === 'streaming' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('purchase')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Purchase
                {currentDropdown === 'purchase' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('resources')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Resources
                {currentDropdown === 'resources' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            <IonCol size="auto" className="dropdown-button-container">
              <IonButton
                onClick={() => handleExpandedDropdownChange('cloud')}
                fill="clear"
                className="dropdown-button-expander"
              >
                Cloud
                {currentDropdown === 'cloud' ? (
                  <IonIcon
                    className="dropdown-collapse-carrot"
                    slot="end"
                    icon={caretUpOutline}
                  ></IonIcon>
                ) : (
                  <IonIcon
                    className="dropdown-expand-carrot"
                    slot="end"
                    icon={caretDownOutline}
                  ></IonIcon>
                )}
              </IonButton>
            </IonCol>
            {isElectron() && (
              <IonCol size="auto" className="dropdown-button-container">
                <IonButton
                  fill="clear"
                  className="dropdown-button-expander"
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  + Add Local File
                </IonButton>
                <input
                  ref={fileInputRef}
                  type={'file'}
                  style={{ display: 'none' }}
                  onChange={handleTrackUpload}
                  multiple
                />
              </IonCol>
            )}
          </IonRow>
          {currentDropdown === 'pools' && (
            <Pools artist={Artist} title={Title} />
          )}
          {currentDropdown === 'streaming' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={streamingOptions}
            />
          )}
          {currentDropdown === 'purchase' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={purchaseOptions}
            />
          )}
          {currentDropdown === 'resources' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={resourceOptions}
            />
          )}
          {currentDropdown === 'cloud' && (
            <TrackDropdown
              artist={Artist}
              title={Title}
              dropdownData={cloudStorageOptions}
            />
          )}
          {isElectron() && (
            <>
              <div
                className={`track-matcher-container ${currentDropdown === 'tracks' && isExpanded ? 'open' : ''}`}
              >
                {matchedTracks && matchedTracks.length > 0 ? (
                  matchedTracks.map((track) => (
                    <IonRow
                      key={track.id}
                      className="track-matcher-container open"
                    >
                      <IonCol
                        size="12"
                        sizeMd="6"
                        sizeLg="6"
                        className="check-box"
                      >
                        <IonGrid>
                          <IonRow>
                            <IonCol size="auto">
                              <IonCheckbox
                                className="matched-track-checkbox"
                                labelPlacement="end"
                                checked={
                                  track.isSelected ||
                                  (!track.isSelected &&
                                    track.filePath === PreviewURL)
                                }
                                onIonChange={(e) =>
                                  handleSelectionChange(
                                    track.id,
                                    e.detail.checked,
                                  )
                                }
                              />
                            </IonCol>
                            <IonCol className="matched-track-checkbox-label">
                              {/*TODO: A great place to support tag updating. Eg: Set the year, set the album, composer, etc*/}
                              {/*Just open a modal when you click on BPM/Key, and focus on those fields.*/}
                              <div className="ion-text-wrap">
                                {track.tags?.title && track.tags.title}
                                {track.tags?.artist?.length > 0 &&
                                  track.tags?.title &&
                                  ' - '}
                                {track.tags?.artist?.length > 0 &&
                                  track.tags?.artist.join(', ')}
                              </div>
                              <IonText color="medium">
                                <sub>{track.filePath}</sub>
                              </IonText>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol size="12" sizeMd="6" sizeLg="3">
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <IonRow>
                                <IonText color="primary">BPM</IonText>
                                <div className="audio-feature-container-small">
                                  {track.tags && track.tags.bpm
                                    ? track.tags.bpm
                                    : ''}
                                </div>
                              </IonRow>
                            </IonCol>
                            <IonCol>
                              <IonRow>
                                <IonText color="primary">Key</IonText>
                                <div className="audio-feature-container-small">
                                  {track.tags && track.tags.key
                                    ? track.tags.key
                                    : ''}
                                </div>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol
                        size="12"
                        sizeLg="3"
                        className="center-content preview-container"
                      >
                        <IonCol className="preview small-preview-container">
                          {track.filePath ? (
                            <Waveform height={20} url={track.filePath} />
                          ) : (
                            <IonLabel>No preview available</IonLabel>
                          )}
                        </IonCol>
                      </IonCol>
                    </IonRow>
                  ))
                ) : (
                  <IonRow>
                    <IonCol>
                      <div className="ion-text-wrap no-track-matches">
                        <IonIcon
                          aria-hidden="true"
                          slot="start"
                          src={alertOutline}
                        />
                        No matched tracks found. Click a local file, drag and
                        drop here, or use one of the other methods to find the
                        track.
                      </div>
                    </IonCol>
                  </IonRow>
                )}
              </div>
            </>
          )}
        </IonGrid>
      </IonRow>
    </>
  );
};
