import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { closeOutline } from 'ionicons/icons';
import { CrateGrid } from '../crates/crateGrid/CrateGrid';
import './SearchResults.scss';
import { iCrate } from '../../types/ICrate';
import { CondensedTrackView } from '../tracks/CondensedTrackView';
import { iTrack } from '../../types/ITrack';

interface SearchResultsProps {
  crateData: iCrate[];
  songData: iTrack[];
  handleItemClicked: () => void;
  handleClose: () => void;
  searchLoading: boolean;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  crateData,
  songData,
  handleItemClicked,
  handleClose,
  searchLoading,
}) => {
  return (
    <IonContent className="search-content ">
      <div id="search-page-header">
        <h1>Search Results</h1>
        <IonIcon
          id="close-icon"
          size="large"
          icon={closeOutline}
          aria-label="Close"
          onClick={handleClose}
        />
      </div>
      <IonGrid>
        {songData && <h2>Tracks</h2>}
        <IonRow>
          {songData.map((item, i) => (
            <IonCol size="12" size-lg="6" size-xl="3" key={i}>
              <CondensedTrackView key={i} {...item} handleClose={handleClose} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <h2>Crates</h2>
      {crateData && crateData.length > 0 ? (
        <>
          <CrateGrid
            categories={['Search']}
            crateData={crateData}
            onItemClicked={handleItemClicked}
            sectionHeader={'Search Results'}
          />
        </>
      ) : searchLoading ? (
        <IonSpinner>Searching...</IonSpinner>
      ) : (
        <IonText>
          There were no crates found with that criteria. Try searching by name,
          category, or hacker.
        </IonText>
      )}
    </IonContent>
  );
};

export default SearchResults;
