/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SpotifySearchDto interface.
 */
export function instanceOfSpotifySearchDto(value) {
    if (!('term' in value) || value['term'] === undefined)
        return false;
    return true;
}
export function SpotifySearchDtoFromJSON(json) {
    return SpotifySearchDtoFromJSONTyped(json, false);
}
export function SpotifySearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'term': json['term'],
        'offset': json['offset'] == null ? undefined : json['offset'],
        'markets': json['markets'] == null ? undefined : json['markets'],
        'raw': json['raw'] == null ? undefined : json['raw'],
    };
}
export function SpotifySearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'term': value['term'],
        'offset': value['offset'],
        'markets': value['markets'],
        'raw': value['raw'],
    };
}
