import React from 'react';
import { IonContent, IonPopover } from '@ionic/react';
import './VideoPopover.scss'; // Ensure to import the new CSS file

interface PopoverProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  videoSrc: string;
  title?: string;
  content?: React.ReactNode;
}

const VideoPopover: React.FC<PopoverProps> = ({
  isOpen,
  onDidDismiss,
  videoSrc,
  title,
  content,
}) => {
  return (
    <IonPopover
      className="video-popover"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="video-container">
        {title && <h2>{title}</h2>}
        <video src={videoSrc} autoPlay loop muted />
        {content && (
          <IonContent className={'video-content'}>{content}</IonContent>
        )}
      </div>
    </IonPopover>
  );
};

export default VideoPopover;
