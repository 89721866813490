import { createSlice } from '@reduxjs/toolkit';

export interface ThemeState {
  theme: 'light' | 'dark';
}

const initialState: ThemeState = {
  theme: 'dark', // Enforce Dark Mode

  // This should return .matches from matchMedia call
  // theme: window.matchMedia('(prefers-color-scheme: dark)').matches
  //   ? 'dark'
  //   : 'light',
};

export const ThemeStateSlice = createSlice({
  name: 'themeState',
  initialState: initialState,
  reducers: {
    toggleTheme: (state) => {
      // Force dark-mode
      document.body.classList.toggle('dark', true);

      // state.theme = state.theme === 'light' ? 'dark' : 'light';
      // document.body.classList.toggle('dark', state.theme === 'dark');
    },
  },
});

export const { toggleTheme } = ThemeStateSlice.actions;

export default ThemeStateSlice.reducer;
