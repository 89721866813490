import isElectron from './isElectron';
import { run } from '../helper/electron';

export const openExternalLink = (url: string) => {
  if (isElectron()) {
    run('openExternalLink', url);
  } else {
    window.open(url, '_blank');
  }
};
