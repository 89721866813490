import React, { useEffect, useState } from 'react';
import { RecordPool } from '../../types/iPool';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import { PoolSearchDto, PoolTrackDataDto } from '@cratehackers/api-client';

import './Pools.scss';
import { addOutline, closeOutline, download, key } from 'ionicons/icons';
import ApiService from '../../services/Api.service';
import { openExternalLink } from '../../utils/openExternalLink';

interface PoolProps {
  artist: string;
  title: string;
  poolData: RecordPool;
  searchApi?: boolean;
}

const Pool: React.FC<PoolProps> = ({
  artist,
  title,
  poolData,
  searchApi = false,
}) => {
  const [expand, setExpand] = useState(false);

  const getAndSanitizeLink = (): string => {
    const sanitizedArtist = artist.split(/[\s,&]+|vs|Ft|ft|and/i)[0];
    const titleMatch = title.match(/^[^\[\(\-]+/);
    const simplifiedTitle = titleMatch ? titleMatch[0].trim() : title.trim();

    const sanitizedTitle = simplifiedTitle
      .split(/\s+/) // Split by whitespace
      .map((word: string) => word.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '')) // Remove special characters at the start or end of each word
      .filter((word: string) => word.length > 0) // Keep only words that have alphanumeric characters left
      .join(' ')
      .trim(); // Join back into a single string
    return poolData.SearchTitleOnly
      ? `${poolData.SearchLink}${sanitizedTitle}`
      : `${poolData.SearchLink}${sanitizedTitle} ${sanitizedArtist}`;
  };

  const link = getAndSanitizeLink();

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [poolResults, setPoolResults] = useState<PoolTrackDataDto[]>([]);

  useEffect(() => {
    if (searchApi) {
      setLoading(true);
      const chAPI = ApiService.getApiClient();

      const query: PoolSearchDto = {
        artist: artist,
        title: title,
        poolName: poolData.Name,
      };
      chAPI
        .poolsControllerSearchPools({
          poolSearchDto: query,
        })
        .then((returnedData) => {
          setPoolResults(returnedData);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setError(true);
        });
    }
  }, [searchApi]);

  return (
    <IonList lines="none">
      {poolData.SearchLink && (
        <>
          <IonItem
            className="pool-name-text"
            onClick={() => openExternalLink(link)}
          >
            {loading ? (
              <IonSpinner />
            ) : (
              <IonButton
                fill="clear"
                style={{
                  visibility: poolResults.length > 0 ? 'visible' : 'hidden',
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setExpand(!expand);
                }}
              >
                <IonIcon icon={expand ? closeOutline : addOutline} />
              </IonButton>
            )}
            {poolData.Name}{' '}
            {poolResults && poolResults.length > 0 && `(${poolResults.length})`}
            {error && (
              <IonText color="warning">&nbsp;(Error Searching)</IonText>
            )}
          </IonItem>
          {expand && poolResults?.length && (
            <IonGrid>
              {poolResults.map((result, index) => (
                <IonRow>
                  <IonCol size=".5"></IonCol>
                  <IonCol size="11">
                    <IonItem
                      className="track-name-text"
                      key={index}
                      onClick={() => openExternalLink(link)} // TODO: Auto-download with id
                    >
                      <IonGrid>
                        <IonRow>
                          <IonText>
                            {result.title} - {result.artist}
                          </IonText>
                        </IonRow>
                        {result.additionalData && (
                          <IonRow>
                            <IonText color="medium">
                              {Object.entries(result.additionalData).map(
                                ([key, value]) => {
                                  if (key !== 'id') {
                                    return `${key.toUpperCase()}: ${value} `;
                                  }
                                },
                              )}
                            </IonText>
                          </IonRow>
                        )}
                      </IonGrid>
                    </IonItem>
                  </IonCol>
                  <IonCol size=".5"></IonCol>
                </IonRow>
              ))}
            </IonGrid>
          )}
        </>
      )}
    </IonList>
  );
};

export default Pool;
