import React, { useEffect, useState } from 'react';
import { IonIcon, IonPopover, IonSpinner } from '@ionic/react';
import { plus, arrowLeft, rotateLeft } from '../../components/Icons';
import { ellipsisVertical, trashOutline } from 'ionicons/icons';
import { on, once, run } from '../../helper/electron';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { GridReadyEvent } from 'ag-grid-community';
import './add-folder.scss';
import '../../components/aggrid/menu.scss';
import ProgressButton from '../../components/buttons/ProgressButton';

const AddFolder: React.FC = () => {
  const history = useHistory();
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [gridApi, setGridApi] = useState<any>();
  const [rowData, setRowData] = useState<FolderRecord[]>([]);
  const [isAddingFolders, setIsAddingFolders] = useState<boolean>(false);
  const [addFolderProgress, setAddFolderProgress] = useState(0);
  const [isRescanningFolders, setIsRescanningFolders] =
    useState<boolean>(false);
  const [rescanFoldersProgress, setRescanFoldersProgress] = useState(0);

  const ActionCellRenderer: React.FC<any> = (params: any) => {
    const [popoverState, setShowPopover] = useState({
      showPopover: false,
      event: undefined,
    });

    return (
      <div className="ion-icon-container">
        <IonIcon
          className="ion-icon"
          icon={ellipsisVertical}
          onClick={(e: any) => {
            e.persist();
            setShowPopover({ showPopover: true, event: e });
          }}
        />
        <IonPopover
          className="add-folder-popover"
          isOpen={popoverState.showPopover}
          event={popoverState.event}
          onDidDismiss={() =>
            setShowPopover({ showPopover: false, event: undefined })
          }
        >
          <div className="context-menu">
            <ul>
              {menuItems.map((item, index) => (
                <li key={index} onClick={() => item.action(params.data)}>
                  {item.icon && (
                    <IonIcon className="ion-icon" icon={item.icon} />
                  )}
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        </IonPopover>
      </div>
    );
  };

  const [columnDefs] = useState([
    { field: 'id', hide: true },
    {
      field: 'path',
      headerName: 'Folder Path',
      flex: 1,
      resizable: false,
      filter: true,
      sortable: true,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'action',
      headerName: '',
      cellRenderer: ActionCellRenderer,
      width: 100,
    },
  ]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const addFolder = async () => {
    // Check if another prompt is already open
    if (isAddingFolders) {
      return;
    }

    setIsAddingFolders(true);
    run('my-music/add-folder');
    on('my-music/add-folder', (response: any) => {
      // console.log('[ON] my-music/add-folder', response);

      if (response.total && response.numOfProcessed) {
        setAddFolderProgress((response.numOfProcessed / response.total) * 100);
      } else {
        setIsAddingFolders(false);
        run('my-music/get-folders');
      }
    });
  };

  const deleteFolder = async (data: any) => {
    console.log('my-music/delete-folder arg:', { folder: data });
    run('my-music/delete-folder', { folder: data });
    once('my-music/delete-folder', (response: any) => {
      console.log('[ON] my-music/delete-folder', response);
      run('my-music/get-folders');
    });
  };

  // TODO: It's possible to rescan folders concurrently, just click the icon while it loads
  const rescanFolders = async () => {
    // Check if another prompt is already open
    if (isRescanningFolders) {
      return;
    }

    setIsRescanningFolders(true);
    run('my-music/rescan-folders');
    on('my-music/rescan-folders', (response: any) => {
      // console.log('[ON] my-music/rescan-folders:', response);

      if (response.total && response.numOfProcessed) {
        setRescanFoldersProgress(
          (response.numOfProcessed / response.total) * 100,
        );
      } else {
        setIsRescanningFolders(false);
        run('my-music/get-folders');
      }
    });
  };

  const menuItems = [
    { label: 'Delete', icon: trashOutline, action: deleteFolder },
    {
      label: 'Re-Scan Folder(s)',
      icon: rotateLeft,
      action: rescanFolders,
    },
  ];

  type FolderRecord = {
    id: string;
    path: string;
    drive_id: string; // TODO: Reference a drive object, this way we can simply ask if it's mounted and show something in UI.
  };

  type ResponseGetMyMusicFolder = any & {
    data: FolderRecord[];
  };
  const getMyMusicFolders = (response: ResponseGetMyMusicFolder) => {
    console.log('[ON] my-music/get-folders:', response);
    setRowData(response);
    gridApi?.api?.autoSizeColumns(['path']);
  };

  useEffect(() => {
    const getFoldersListener = window.api?.on(
      'my-music/get-folders',
      getMyMusicFolders,
    );
    run('my-music/get-folders');

    // Cleanup listener when component unmounts
    return () => {
      window.api?.removeListener('my-music/get-folders', getFoldersListener);
    };
  }, []);

  return (
    <>
      <div className="page-header">
        <h1>
          My Music <span>/ Folders</span>
        </h1>
        <div id="actions" className="button-group">
          <button className="primary btn-transparent" onClick={handleGoBack}>
            <IonIcon className="ion-icon" icon={arrowLeft} /> Back
          </button>
          <ProgressButton
            id="add-folders-btn"
            className="primary"
            isLoading={isAddingFolders}
            progress={addFolderProgress}
            icon={plus}
            onClick={addFolder}
          >
            Add Folder
          </ProgressButton>
          <ProgressButton
            id="rescan-folders-btn"
            className="primary"
            isLoading={isRescanningFolders}
            progress={rescanFoldersProgress}
            icon={rotateLeft}
            onClick={rescanFolders}
          >
            Re-scan Folder(s)
          </ProgressButton>
        </div>
      </div>
      <div
        className={`ag-theme-alpine${
          theme === 'dark' ? '-dark' : ''
        } folder-grid`}
      >
        <AgGridReact
          className={`ag-theme-alpine${theme === 'dark' ? '-dark' : ''} grid`}
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="single" // Options - allows click selection of rows
          onGridReady={onGridReady}
          rowHeight={60}
          suppressScrollOnNewData={true}
        />
      </div>
    </>
  );
};

export default AddFolder;
