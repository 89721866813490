/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the TrackAvailabilityOptionDto interface.
 */
export function instanceOfTrackAvailabilityOptionDto(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('url' in value) || value['url'] === undefined)
        return false;
    return true;
}
export function TrackAvailabilityOptionDtoFromJSON(json) {
    return TrackAvailabilityOptionDtoFromJSONTyped(json, false);
}
export function TrackAvailabilityOptionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'url': json['url'],
    };
}
export function TrackAvailabilityOptionDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'url': value['url'],
    };
}
