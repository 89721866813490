import React, { useCallback, useEffect, useRef, useState } from 'react';
import buildStyle from '../build-path.module.scss';
import './file.scss';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import { iTrack } from '../../../../types/ITrack';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { iCrate } from '../../../../types/ICrate';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../../../../store/hooks';
import { settingsOutline } from 'ionicons/icons';

export default function FilePath() {
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [fileContent, setFileContent] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [crateName, setCrateName] = useState('');
  const [tracks, setTracks] = useState<iTrack[]>([]);
  const [chosenDelimiter, setChosenDelimiter] = useState<string>('');
  const [gridApi, setGridApi] = useState<any>();
  const [showDelimiterChange, setShowDelimiterChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [columnOptions, setColumnOptions] = useState<string[]>([]);
  const [artistColumn, setArtistColumn] = useState<string>('Artist');
  const [titleColumn, setTitleColumn] = useState<string>('Song');

  const history = useHistory();
  const dispatch = useDispatch();

  const delimiters: { [key: string]: string } = {
    Comma: ',',
    Hyphen: '-',
    Tab: '\t',
    Pipe: '|',
    Semicolon: ';',
  };

  const buildCrate = () => {
    const newCrate: iCrate = {
      _id: '',
      Title: crateName,
      Description: 'Created from Crate Hackers Crate Builder (file import)',
      Tracks: tracks,
      createdFrom: 'Text',
    };

    return newCrate;
  };

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLIonInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
    },
    [],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLIonInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;

      if (files.length) {
        const file = files[0];
        setFileUpload(file);
        processFile(file);
      }
    },
    [],
  );

  const processFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result;
      if (typeof content === 'string') {
        setFileContent(content);
        parseContent(content);
      } else {
        // TODO: Throw some type of error or display an error on screen for it not being readable
      }
    };
    reader.readAsText(file);
  };

  const parseContent = (content: string) => {
    const lines = content.split('\n').filter((line) => line);
    if (lines.length) {
      const firstLine = lines[0];
      const delimiter = detectDelimiter(firstLine);
      setChosenDelimiter(delimiter);

      const columns = firstLine.split(delimiter).map((col) => col.trim());
      setColumnOptions(columns);

      const newTracks = lines
        .slice(1)
        .map((line) => parseTrack(line, delimiter));
      // Filter out rows where the first and second columns match the artist and title columns
      const filteredTracks = newTracks.filter(
        (track) => track.Artist !== artistColumn && track.Title !== titleColumn,
      );
      setTracks(filteredTracks);
    }
  };

  const detectDelimiter = (line: string): string => {
    const delimiterValues = Object.keys(delimiters).map(
      (delimiterName: string) => delimiters[delimiterName],
    );
    return delimiterValues.find((d) => line.includes(d)) || ',';
  };

  const handleDelimiterChange = (newDelimiter: string) => {
    setChosenDelimiter(newDelimiter);
    if (fileContent) {
      parseContent(fileContent);
    }
  };

  useEffect(() => {
    if (fileContent) {
      parseContent(fileContent);
    } else {
      setTracks([]);
    }
  }, [chosenDelimiter, fileContent, artistColumn, titleColumn]);

  const parseTrack = (line: string, delimiter: string): iTrack => {
    const parts = line.split(delimiter).map((part) => part.trim());
    // Default to the first two columns if not yet mapped
    const artistIdx =
      columnOptions.indexOf(artistColumn) !== -1
        ? columnOptions.indexOf(artistColumn)
        : 0;
    const titleIdx =
      columnOptions.indexOf(titleColumn) !== -1
        ? columnOptions.indexOf(titleColumn)
        : 1;
    return { ID: '', Artist: parts[artistIdx], Title: parts[titleIdx] };
  };

  const changeMapping = () => {
    setShowModal(true);
  };

  const handleMappingSave = () => {
    setShowModal(false);

    // Re-parse the file content with the updated mapping
    if (fileContent) {
      parseContent(fileContent);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const [columnDefs] = useState([
    { field: 'ID', hide: true },
    {
      field: 'Artist',
      headerName: 'Artist',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: false,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
    {
      field: 'Title',
      headerName: 'Title',
      flex: 1,
      resizable: true,
      filter: false,
      sortable: false,
      editable: false,
      draggable: false,
      lockPosition: true,
      suppressMenu: true,
    },
  ]);

  return (
    <>
      <IonRow>
        <IonCol size="12">
          <div className={buildStyle.buildHeader}>
            <div>What should we name your crate?</div>
          </div>
          <IonInput
            className={buildStyle.buildInput}
            placeholder="Crate Name"
            labelPlacement="floating"
            fill="outline"
            value={crateName}
            onIonInput={(e: any) => setCrateName(e.target.value)}
          >
            <div slot="label">Crate Name</div>
          </IonInput>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol size="10.5">
          <IonInput
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className={buildStyle.buildInput}
            placeholder="Click browse to upload a file or drop the file here"
            labelPlacement="stacked"
            fill="outline"
            value={fileUpload?.name || ''}
            onChange={(e: any) => {
              if (e.target.value === '') {
                setFileUpload(null);
                fileInputRef!.current!.value = '';
              }
            }}
          >
            <div slot="label">File</div>
          </IonInput>
          <IonText color="medium" className="file-support-text">
            Supported file extensions: CSV
          </IonText>
        </IonCol>

        <IonCol offset=".25" size=".75" style={{ marginTop: '12px' }}>
          <IonButton
            className="text-primary-buttons"
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            Browse
          </IonButton>

          <input
            ref={fileInputRef}
            type={'file'}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </IonCol>
      </IonRow>

      {fileContent && (
        <>
          <IonRow>
            <IonCol size="12" sizeMd="6">
              {chosenDelimiter && (
                <IonText color="primary">
                  <h3>
                    Detected Delimiter:{' '}
                    {Object.keys(delimiters).find(
                      (key) => delimiters[key] === chosenDelimiter,
                    ) || 'None'}
                  </h3>
                </IonText>
              )}
            </IonCol>
            <IonCol>
              {/* Delimiter change functionality previously commented out */}
            </IonCol>
            <IonCol style={{ textAlign: 'right' }}>
              {tracks.length > 0 && (
                <IonButton
                  className="text-primary-buttons"
                  onClick={changeMapping}
                >
                  <IonIcon
                    icon={settingsOutline}
                    style={{ paddingRight: '8px' }}
                  />{' '}
                  Mapping
                </IonButton>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div
                className="ag-theme-alpine"
                style={{ height: 386, width: '100%' }}
              >
                {tracks.length > 0 && (
                  <AgGridReact
                    className={`ag-theme-alpine${
                      theme === 'dark' ? '-dark' : ''
                    }`}
                    rowData={tracks}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                  />
                )}
              </div>
            </IonCol>
          </IonRow>
        </>
      )}

      <BuildGenerateCrate
        buildCrateFn={buildCrate}
        isBtnDisabled={crateName === '' || tracks.length === 0}
      />

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        id={'column-mapping'}
      >
        <IonItem>
          <IonLabel>Artist Column</IonLabel>
          <IonSelect
            value={artistColumn}
            placeholder="Select Artists"
            onIonChange={(e) => setArtistColumn(e.detail.value)}
          >
            {columnOptions.map((col, index) => (
              <IonSelectOption key={index} value={col}>
                {col}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Title Column</IonLabel>
          <IonSelect
            value={titleColumn}
            placeholder="Select Titles"
            onIonChange={(e) => setTitleColumn(e.detail.value)}
          >
            {columnOptions.map((col, index) => (
              <IonSelectOption key={index} value={col}>
                {col}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonGrid>
          <IonRow>
            <IonCol className="button-col">
              <IonButton
                expand="block"
                className="mapping-button"
                color="light"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </IonButton>
            </IonCol>
            <IonCol className="button-col">
              <IonButton
                expand="block"
                className="mapping-button"
                onClick={handleMappingSave}
              >
                Save Mapping
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
    </>
  );

  function handleFileChange(e: any) {
    const file = e.target.files[0];
    setFileUpload(file);
    processFile(file);
  }
}
