export const instructionsSerato = (
  <div>
    <ol>
      <li>
        Open Crate Hackers and go to the export menu.
        <ul>
          <li>Pick a folder on your computer to save the crate (M3U file).</li>
          <li>
            Click Export. The crate is now saved in the folder you selected.
          </li>
        </ul>
      </li>
      <li>
        Open Serato DJ software.
        <ul>
          <li>Drag the M3U file into the left menu of Serato.</li>
        </ul>
      </li>
      <li>Your crate is now ready to use in Serato DJ.</li>
    </ol>
    <p>
      <b>Troubleshooting Tip:</b> Before exporting, check that the songs in the
      Track Matcher are selected. Without selected tracks, the M3U file won’t
      include any music.
    </p>
  </div>
);

export const instructionsRekordbox = (
  <div>
    <ol>
      <li>
        Open Crate Hackers and go to the export menu.
        <ul>
          <li>Pick a folder on your computer to save the crate (M3U file).</li>
          <li>
            Click Export. The crate is now saved in the folder you selected.
          </li>
        </ul>
      </li>
      <li>
        Open Rekordbox software.
        <ul>
          <li>Drag the M3U file into the left menu of Rekordbox.</li>
        </ul>
      </li>
      <li>Your crate is now ready to use in Rekordbox.</li>
    </ol>
    <p>
      <b>Troubleshooting Tip:</b> Before exporting, check that the songs in the
      Track Matcher are selected. Without selected tracks, the M3U file won’t
      include any music.
    </p>
  </div>
);

export const instructionsDenon = (
  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
    <ol className="space-y-4">
      <li>
        <strong>Export the Crate</strong>
        <ul className="ml-6 mt-2 space-y-1">
          <li>Open Crate Hackers and go to the export menu.</li>
          <li>
            Choose a folder on your computer to save the crate (M3U file).
          </li>
          <li>
            Click <strong>Export</strong>. The crate is now saved in the folder
            you selected.
          </li>
        </ul>
      </li>
      <li>
        <strong>Add to Apple Music</strong>
        <ul className="ml-6 mt-2 space-y-1">
          <li>Open Apple Music.</li>
          <li>
            <strong>Option 1:</strong> Drag the M3U file into the{' '}
            <strong>All Playlists</strong> section on the left side.
          </li>
          <li>
            <strong>Option 2:</strong> Go to the menu at the top, click{' '}
            <strong>File &gt; Import</strong>, and select the M3U file.
          </li>
        </ul>
      </li>
      <li>
        <strong>Open Denon Engine</strong>
        <ul className="ml-6 mt-2 space-y-1">
          <li>Launch Denon Engine DJ software.</li>
          <li>
            Go to <strong>Apple Music Library</strong>.
          </li>
          <li>
            Click <strong>Refresh Collection</strong>.
          </li>
        </ul>
      </li>
      <li>
        <strong>Perform</strong>
        <ul className="ml-6 mt-2">
          <li>Your crate is now ready in Denon Engine DJ for performance.</li>
        </ul>
      </li>
    </ol>
    <div className="mt-4">
      <strong>Troubleshooting Tip</strong>
      <p>
        Before exporting, check that the songs in the{' '}
        <strong>Track Matcher</strong> are selected. Without selected tracks,
        the M3U file won't include any music.
      </p>
    </div>
  </div>
);

export const instructionsDjay = (
  <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
    <ol>
      <li>
        Export the Crate
        <ul>
          <li>Open Crate Hackers and go to the export menu.</li>
          <li>
            Choose a folder on your computer to save the crate (M3U file).
          </li>
          <li>
            Click Export. The crate is now saved in the folder you selected.
          </li>
        </ul>
      </li>
      <li>
        Add to Apple Music
        <ul>
          <li>Open Apple Music.</li>
          <li>
            Option 1: Drag the M3U file into the All Playlists section on the
            left side.
          </li>
          <li>
            Option 2: Go to the menu at the top, click File &gtn; Import, and
            select the M3U file.
          </li>
        </ul>
      </li>
      <li>
        Open Algoriddim DJ
        <ul>
          <li>Launch Algoriddim DJ software.</li>
          <li>Navigate to the Apple Music Library section.</li>
          <li>Locate your crate (playlist) in the Apple Music Library.</li>
        </ul>
      </li>
      <li>
        Perform
        <ul>
          <li>Your crate is now ready to use in Algoriddim DJ.</li>
        </ul>
      </li>
    </ol>
  </div>
);
