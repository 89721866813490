import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from '@ionic/react';
import { useParams } from 'react-router';
import { Crate } from '../../../components/crates/crate/Crate';
import BuildProgressIndicator from '../../../components/myCrates/build-progress-indicator/build-progress-indicator';
import crateStyle from '../../create-crate/create-crate.module.scss';
import { myCrates as myCratesIcon } from '../../../components/Icons';
import React from 'react';

export default function GenerateCrate() {
  const { buildType } = useParams<{ buildType: string }>();
  const { crateId } = useParams<{ crateId: string }>();
  // const selectedCrate = useSelector(selectSelectedCrate);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonGrid style={{ height: '100%' }}>
          <IonRow>
            <IonCol sizeXs="4" sizeSm="12" sizeLg="3">
              <div className={crateStyle['page-header']}>
                <h1 className={crateStyle['title']}>
                  <IonIcon
                    icon={myCratesIcon}
                    style={{ marginRight: '10px' }}
                  />{' '}
                  Crate Builder
                </h1>
              </div>
            </IonCol>
            <IonCol sizeXs="8" sizeSm="12" sizeLg="7">
              <div className={crateStyle['step-header']}>
                <BuildProgressIndicator
                  currentStep={3}
                  firstStepName={`Start from ${buildType || ''}`}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow style={{ height: '100%' }}>
            <IonCol size="12">
              <Crate id={crateId} withTracks={true} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
