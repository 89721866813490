export const run = (channel: string, data?: any): void => {
  try {
    window.api.send(channel, data);
  } catch (error) {
    console.error('Post message failed:', error);
  }
};

export const on = (
  channel: string,
  listener: (...args: any[]) => void,
): void => {
  window.api?.on(channel, listener);
};

export const once = (
  channel: string,
  listener: (...args: any[]) => void,
): void => {
  window.api?.once(channel, listener);
};
