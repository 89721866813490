import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iTrack } from '../../types/ITrack';

interface TrackState {
  selectedTrack: iTrack | null;
}

const initialState: TrackState = {
  selectedTrack: null,
};

export const TrackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setSelectedTrack: (state, action: PayloadAction<iTrack>) => {
      state.selectedTrack = action.payload;
    },
    clearTrack: (state) => {
      state.selectedTrack = null;
    },
  },
});

export const { setSelectedTrack, clearTrack } = TrackSlice.actions;

export default TrackSlice.reducer;
