import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';

import React, { useCallback, useState } from 'react';

import './Index.scss';
import Feed from '../components/social/Feed';

const Index: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const handleScroll = useCallback((event: CustomEvent) => {
    setScrollPosition(event.detail.currentY);
  }, []);

  return (
    <IonPage id="home">
      <IonContent fullscreen scrollEvents onIonScroll={handleScroll}>
        <IonGrid>
          <IonRow className="feed-wrapper">
            <IonCol size="12">
              <Feed scrollPosition={scrollPosition} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Index;
