/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { BugReportDtoToJSON, CrateCategoryDtoFromJSON, CrateCategoryDtoToJSON, CrateSearchDtoToJSON, FeatureRequestDtoToJSON, ForgotPasswordDtoToJSON, GetCategoryDtoToJSON, GetCratesDtoFromJSON, GetSimpleCrateDtoFromJSON, KeyDtoToJSON, LoginEmailPasswordDtoToJSON, MyAlbumIdRequestDtoToJSON, MyPlaylistIdRequestDtoToJSON, MyPlaylistsRequestDtoToJSON, PaginatedTrackResponseFromJSON, PoolListDtoFromJSON, PoolSearchDtoToJSON, PoolTrackDataDtoFromJSON, RegisterDtoToJSON, ResetPasswordDtoToJSON, SearchDtoToJSON, SpotifySearchDtoToJSON, TrackFromJSON, TrackAvailabilityOptionsDtoFromJSON, TrackCrateSearchDtoToJSON, TrackSearchDtoFromJSON, UpdateCrateDtoFromJSON, UpdateCrateDtoToJSON, } from '../models/index';
/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     */
    authControllerForgotPasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['forgotPasswordDto'] == null) {
                throw new runtime.RequiredError('forgotPasswordDto', 'Required parameter "forgotPasswordDto" was null or undefined when calling authControllerForgotPassword().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth/forgot-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ForgotPasswordDtoToJSON(requestParameters['forgotPasswordDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerForgotPassword(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerForgotPasswordRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    authControllerLoginRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['loginEmailPasswordDto'] == null) {
                throw new runtime.RequiredError('loginEmailPasswordDto', 'Required parameter "loginEmailPasswordDto" was null or undefined when calling authControllerLogin().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth/login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginEmailPasswordDtoToJSON(requestParameters['loginEmailPasswordDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerLogin(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerLoginRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    authControllerRegisterRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['registerDto'] == null) {
                throw new runtime.RequiredError('registerDto', 'Required parameter "registerDto" was null or undefined when calling authControllerRegister().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth/register`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: RegisterDtoToJSON(requestParameters['registerDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerRegister(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerRegisterRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    authControllerResetPasswordRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['resetPasswordDto'] == null) {
                throw new runtime.RequiredError('resetPasswordDto', 'Required parameter "resetPasswordDto" was null or undefined when calling authControllerResetPassword().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth/reset-password`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ResetPasswordDtoToJSON(requestParameters['resetPasswordDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerResetPassword(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerResetPasswordRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    authControllerVerifyRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['token'] == null) {
                throw new runtime.RequiredError('token', 'Required parameter "token" was null or undefined when calling authControllerVerify().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/auth/verify/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters['token']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    authControllerVerify(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.authControllerVerifyRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    chartControllerGetCHChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling chartControllerGetCHChart().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/charts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    chartControllerGetCHChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.chartControllerGetCHChartRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    chartControllerGetCHChartsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/charts`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    chartControllerGetCHCharts(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.chartControllerGetCHChartsRaw(initOverrides);
        });
    }
    /**
     */
    crateControllerCreateCrateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['body'] == null) {
                throw new runtime.RequiredError('body', 'Required parameter "body" was null or undefined when calling crateControllerCreateCrate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['body'],
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerCreateCrate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerCreateCrateRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    crateControllerDeleteCrateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling crateControllerDeleteCrate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerDeleteCrate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerDeleteCrateRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    crateControllerFindCuratedCratesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['cursor'] != null) {
                queryParameters['cursor'] = requestParameters['cursor'];
            }
            if (requestParameters['archived'] != null) {
                queryParameters['archived'] = requestParameters['archived'];
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/curated`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCratesDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    crateControllerFindCuratedCrates(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.crateControllerFindCuratedCratesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    crateControllerFindHackathonRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/hackathon`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerFindHackathon(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerFindHackathonRaw(initOverrides);
        });
    }
    /**
     */
    crateControllerFindMarketplaceRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/marketplace`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerFindMarketplace(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerFindMarketplaceRaw(initOverrides);
        });
    }
    /**
     */
    crateControllerFindMyCratesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/my-crates`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerFindMyCrates(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerFindMyCratesRaw(initOverrides);
        });
    }
    /**
     */
    crateControllerFindOneRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling crateControllerFindOne().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerFindOne(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerFindOneRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    crateControllerFindStandardRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/standard`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    crateControllerFindStandard(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.crateControllerFindStandardRaw(initOverrides);
        });
    }
    /**
     */
    crateControllerGetCategoryByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['getCategoryDto'] == null) {
                throw new runtime.RequiredError('getCategoryDto', 'Required parameter "getCategoryDto" was null or undefined when calling crateControllerGetCategoryById().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/category`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: GetCategoryDtoToJSON(requestParameters['getCategoryDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CrateCategoryDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    crateControllerGetCategoryById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.crateControllerGetCategoryByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    crateControllerGetCrateCategoriesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/categories`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CrateCategoryDtoFromJSON));
        });
    }
    /**
     */
    crateControllerGetCrateCategories(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.crateControllerGetCrateCategoriesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    crateControllerGetCratesByCategoryRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['crateCategoryDto'] == null) {
                throw new runtime.RequiredError('crateCategoryDto', 'Required parameter "crateCategoryDto" was null or undefined when calling crateControllerGetCratesByCategory().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/category-crates`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CrateCategoryDtoToJSON(requestParameters['crateCategoryDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCratesDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    crateControllerGetCratesByCategory(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.crateControllerGetCratesByCategoryRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    crateControllerSearchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['crateSearchDto'] == null) {
                throw new runtime.RequiredError('crateSearchDto', 'Required parameter "crateSearchDto" was null or undefined when calling crateControllerSearch().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CrateSearchDtoToJSON(requestParameters['crateSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => GetCratesDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    crateControllerSearch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.crateControllerSearchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    crateControllerUpdateCrateRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling crateControllerUpdateCrate().');
            }
            if (requestParameters['updateCrateDto'] == null) {
                throw new runtime.RequiredError('updateCrateDto', 'Required parameter "updateCrateDto" was null or undefined when calling crateControllerUpdateCrate().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/crates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: UpdateCrateDtoToJSON(requestParameters['updateCrateDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCrateDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    crateControllerUpdateCrate(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.crateControllerUpdateCrateRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    feedbackControllerFeatureRequestRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['featureRequestDto'] == null) {
                throw new runtime.RequiredError('featureRequestDto', 'Required parameter "featureRequestDto" was null or undefined when calling feedbackControllerFeatureRequest().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/feedback/feature-request`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FeatureRequestDtoToJSON(requestParameters['featureRequestDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    feedbackControllerFeatureRequest(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.feedbackControllerFeatureRequestRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    feedbackControllerReportBugRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['bugReportDto'] == null) {
                throw new runtime.RequiredError('bugReportDto', 'Required parameter "bugReportDto" was null or undefined when calling feedbackControllerReportBug().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/feedback/bug-report`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: BugReportDtoToJSON(requestParameters['bugReportDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    feedbackControllerReportBug(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.feedbackControllerReportBugRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    keysControllerGetKeyMatchesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['keyDto'] == null) {
                throw new runtime.RequiredError('keyDto', 'Required parameter "keyDto" was null or undefined when calling keysControllerGetKeyMatches().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/keys/key-matches`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: KeyDtoToJSON(requestParameters['keyDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response);
        });
    }
    /**
     */
    keysControllerGetKeyMatches(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.keysControllerGetKeyMatchesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    magicPlaylistControllerGetSpotifyTokenRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/magicplaylist/magicPlaylistToken`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    magicPlaylistControllerGetSpotifyToken(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.magicPlaylistControllerGetSpotifyTokenRaw(initOverrides);
        });
    }
    /**
     */
    poolsControllerGetAllPoolsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/pools/pools`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => PoolListDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    poolsControllerGetAllPools(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.poolsControllerGetAllPoolsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    poolsControllerSearchPoolsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['poolSearchDto'] == null) {
                throw new runtime.RequiredError('poolSearchDto', 'Required parameter "poolSearchDto" was null or undefined when calling poolsControllerSearchPools().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/pools/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: PoolSearchDtoToJSON(requestParameters['poolSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PoolTrackDataDtoFromJSON));
        });
    }
    /**
     */
    poolsControllerSearchPools(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.poolsControllerSearchPoolsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    requestnowControllerGetChartRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling requestnowControllerGetChart().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/requestnow/charts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    requestnowControllerGetChart(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.requestnowControllerGetChartRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    requestnowControllerGetChartsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/requestnow/charts`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    requestnowControllerGetCharts(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.requestnowControllerGetChartsRaw(initOverrides);
        });
    }
    /**
     */
    searchControllerSearchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['searchDto'] == null) {
                throw new runtime.RequiredError('searchDto', 'Required parameter "searchDto" was null or undefined when calling searchControllerSearch().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SearchDtoToJSON(requestParameters['searchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTrackResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    searchControllerSearch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchControllerSearchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    smplControllerGetEventRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling smplControllerGetEvent().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/smpl/event/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    smplControllerGetEvent(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.smplControllerGetEventRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    smplControllerGetEventsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/smpl/events`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    smplControllerGetEvents(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.smplControllerGetEventsRaw(initOverrides);
        });
    }
    /**
     */
    smplControllerGetSMPLIntegrationRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/smpl/integration`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    smplControllerGetSMPLIntegration(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.smplControllerGetSMPLIntegrationRaw(initOverrides);
        });
    }
    /**
     */
    smplControllerIntegrateSMPLRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['body'] == null) {
                throw new runtime.RequiredError('body', 'Required parameter "body" was null or undefined when calling smplControllerIntegrateSMPL().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/smpl/integrate`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['body'],
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    smplControllerIntegrateSMPL(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.smplControllerIntegrateSMPLRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    spotifyControllerGetAlbumTracksRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['myAlbumIdRequestDto'] == null) {
                throw new runtime.RequiredError('myAlbumIdRequestDto', 'Required parameter "myAlbumIdRequestDto" was null or undefined when calling spotifyControllerGetAlbumTracks().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/spotify/album/get`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MyAlbumIdRequestDtoToJSON(requestParameters['myAlbumIdRequestDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    spotifyControllerGetAlbumTracks(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.spotifyControllerGetAlbumTracksRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    spotifyControllerGetGenresRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/spotify/genres`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    spotifyControllerGetGenres(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.spotifyControllerGetGenresRaw(initOverrides);
        });
    }
    /**
     */
    spotifyControllerGetMyPlaylistsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['myPlaylistsRequestDto'] == null) {
                throw new runtime.RequiredError('myPlaylistsRequestDto', 'Required parameter "myPlaylistsRequestDto" was null or undefined when calling spotifyControllerGetMyPlaylists().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/spotify/my/playlists`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MyPlaylistsRequestDtoToJSON(requestParameters['myPlaylistsRequestDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    spotifyControllerGetMyPlaylists(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.spotifyControllerGetMyPlaylistsRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    spotifyControllerGetPlaylistTracksRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['myPlaylistIdRequestDto'] == null) {
                throw new runtime.RequiredError('myPlaylistIdRequestDto', 'Required parameter "myPlaylistIdRequestDto" was null or undefined when calling spotifyControllerGetPlaylistTracks().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/spotify/playlist/get`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MyPlaylistIdRequestDtoToJSON(requestParameters['myPlaylistIdRequestDto']),
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    spotifyControllerGetPlaylistTracks(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.spotifyControllerGetPlaylistTracksRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    trackControllerGetCratesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['trackCrateSearchDto'] == null) {
                throw new runtime.RequiredError('trackCrateSearchDto', 'Required parameter "trackCrateSearchDto" was null or undefined when calling trackControllerGetCrates().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/track/crates`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: TrackCrateSearchDtoToJSON(requestParameters['trackCrateSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSimpleCrateDtoFromJSON));
        });
    }
    /**
     */
    trackControllerGetCrates(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.trackControllerGetCratesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    trackControllerGetTrackRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling trackControllerGetTrack().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/track/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TrackSearchDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    trackControllerGetTrack(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.trackControllerGetTrackRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    trackControllerGetTrackAvailabilityOptionsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/track/availability-options`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TrackAvailabilityOptionsDtoFromJSON(jsonValue));
        });
    }
    /**
     */
    trackControllerGetTrackAvailabilityOptions(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.trackControllerGetTrackAvailabilityOptionsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    trackControllerRecommendationSearchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['spotifySearchDto'] == null) {
                throw new runtime.RequiredError('spotifySearchDto', 'Required parameter "spotifySearchDto" was null or undefined when calling trackControllerRecommendationSearch().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/track/recommendation-search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SpotifySearchDtoToJSON(requestParameters['spotifySearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrackFromJSON));
        });
    }
    /**
     */
    trackControllerRecommendationSearch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.trackControllerRecommendationSearchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    trackControllerSearchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['spotifySearchDto'] == null) {
                throw new runtime.RequiredError('spotifySearchDto', 'Required parameter "spotifySearchDto" was null or undefined when calling trackControllerSearch().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/track/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SpotifySearchDtoToJSON(requestParameters['spotifySearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TrackFromJSON(jsonValue));
        });
    }
    /**
     */
    trackControllerSearch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.trackControllerSearchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    usersControllerGetProfileRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("JWT", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/users/profile`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    usersControllerGetProfile(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.usersControllerGetProfileRaw(initOverrides);
        });
    }
}
