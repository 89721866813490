import { DefaultApi, Configuration } from '@cratehackers/api-client';
import store from '../store/store';
import { AppIdentifiers } from '../store/slices/token-state.slice';
import { config } from '../config';

class ApiService {
  private static instance: DefaultApi;

  private static getAuthToken(): string {
    const appToken = store.getState().tokenState[AppIdentifiers.App];
    return appToken && appToken.accessToken ? appToken.accessToken : '';
  }

  public static getApiClient(): DefaultApi {
    const apiConfig = new Configuration({
      basePath: config.API_BASE_URL,
      accessToken: ApiService.getAuthToken(),
    });
    this.instance = new DefaultApi(apiConfig);
    return this.instance;
  }
}

export default ApiService;
