import React from 'react';
import styles from './progress-indicator.module.scss';

interface IStep {
  label: string;
  callback?: () => void;
}

interface IProgressIndicatorProps {
  steps: IStep[];
  currentStep: number;
}

export default function ProgressIndicator({
  steps,
  currentStep,
}: IProgressIndicatorProps) {
  const isStepCompleted = (stepIndex: number): boolean => {
    return currentStep >= stepIndex;
  };

  return (
    <div className={styles.progressIndicator}>
      {steps.map((step, index) => {
        const isCompleted = isStepCompleted(index + 1);

        return (
          <div
            key={index}
            className={styles.stepContainer}
            onClick={isCompleted ? step.callback : undefined}
            style={{
              cursor: isCompleted && step.callback ? 'pointer' : 'default',
            }}
          >
            <div
              className={`${styles.stepCircle} ${
                isCompleted ? styles.circleCompleted : ''
              }`}
            >
              {index + 1}
            </div>
            <div className={styles.stepLabel}>{step.label}</div>
          </div>
        );
      })}
    </div>
  );
}
