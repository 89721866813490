import React, { useCallback, useEffect, useState } from 'react';
import { SmplService } from '../../../../services/Smpl.service';
import { iSmplEvent } from '../../../../types/iSmplEvent';
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonSpinner,
  IonText,
} from '@ionic/react';
import BuildGenerateCrate from '../../build-generate-crate/build-generate-crate';
import buildStyle from '../build-path.module.scss';
import { iCrate } from '../../../../types/ICrate';

import './smpl.scss';

type Song = {
  title: string;
  artist: string;
};

type Folder = {
  folder_name: string;
  songs: Song[];
};

type EventReponse = {
  success: boolean;
  folders: Folder[];
};

export default function SmplPath() {
  const [selectedSmplItems, setSelectedSmplItems] = useState<any[]>([]);
  const [smplItems, setSmplItems] = useState<any[]>([]);
  const [smplIntegrated, setSmplIntegrated] = useState<boolean | null>(null);
  const [smplURL, setSmplURL] = useState('');
  const [urlIsValid, setURLIsValid] = useState(true);
  const [smplAPIKey, setSmplAPIKey] = useState('');
  const [integrationError, setIntegrationError] = useState<
    string | undefined
  >();
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [eventsData, setEventsData] = useState<Record<number, any>>({});
  const [openedEventId, setOpenedEventId] = useState<number | null>(null);
  const [selectedFolderUniqueId, setSelectedFolderUniqueId] =
    useState<string>('');

  const [selectedTracks, setSelectedTracks] = useState<Song[]>([]);

  const urlPattern = /^https:\/\/([a-z0-9]+[.])*smplsystem\.com\/smpl\/$/;

  const createTracks = (songList: Song[]) => {
    return songList.map((song) => {
      return {
        ID: '',
        Title: song.title,
        Artist: song.artist,
      };
    });
  };

  const deriveTitle = () => {
    return `${selectedFolderUniqueId.split('-')[1]} from ${selectedFolderUniqueId.split('-')[2]}`;
  };

  const buildCrateFn = () => {
    const crateData: iCrate = {
      _id: '',
      Title: deriveTitle(),
      Tracks: createTracks(selectedTracks),
    };

    return crateData;
  };

  const handleURLUpdate = (event: any) => {
    const newValue = event.target.value;
    setSmplURL(newValue);
    setURLIsValid(urlPattern.test(newValue));
  };

  // Create a new function to fetch and set SMPL events
  const fetchAndSetSmplEvents = useCallback(async () => {
    try {
      if (!eventsLoaded) {
        setEventsLoaded(true);
        const response = await SmplService.getSMPLEvents();

        if (!response.error) {
          const smplEvents = response.events.map((event: iSmplEvent) => ({
            ...event,
            name: event.event_title,
          }));

          setSmplItems(smplEvents);
        } else {
          setEventsLoaded(false);
          throw new Error(response.error);
        }
      }
    } catch (error: any) {
      setSmplIntegrated(false);
      setIntegrationError(error.message);
    }
  }, []);

  // Update the integrateSMPL function to use the new fetchAndSetSmplEvents function
  const integrateSMPL = useCallback(async () => {
    try {
      const response = await SmplService.integrateSMPL(smplAPIKey, smplURL);
      if (!response.error) {
        setSmplIntegrated(true);
        await fetchAndSetSmplEvents();
      } else {
        throw new Error(response.message.join(', '));
      }
    } catch (error: any) {
      setIntegrationError(error.message);
    }
  }, [smplAPIKey, smplURL, fetchAndSetSmplEvents]);

  // Update the useEffect hook
  useEffect(() => {
    const checkIntegrationAndFetchEvents = async () => {
      try {
        const data = await SmplService.getSMPLIntegration();
        if (!data.error) {
          setSmplIntegrated(true);
          await fetchAndSetSmplEvents();
        } else {
          throw new Error(data.error);
        }
      } catch (error: any) {
        setSmplIntegrated(false);
        // TODO: Toast error
      }
    };

    checkIntegrationAndFetchEvents();
  }, [fetchAndSetSmplEvents]);

  const getEvent = async (eventId: number) => {
    return await SmplService.getSMPLEvent(eventId);
  };

  useEffect(() => {
    if (openedEventId) {
      getEvent(openedEventId).then((data) => {
        let totalSongs = 0;
        if (data.folders) {
          data.folders.forEach((folder: Folder) => {
            totalSongs += folder.songs.length;
          });
        }
        setEventsData((prevMap) => {
          return {
            ...prevMap,
            [openedEventId]: { folders: data.folders, totalTracks: totalSongs },
          };
        });
      });
    }
  }, [openedEventId]);

  const handleClickFolder = (folderEventId: string) => {
    setSelectedFolderUniqueId((folder) => {
      if (folder === folderEventId) {
        setSelectedTracks([]);
        return '';
      }
      const selectedEvent = Number(folderEventId.split('-')[0]);
      const selectedFolderName = folderEventId.split('-')[1];
      let foundSongs: Song[] = [];

      if (eventsData[selectedEvent] && eventsData[selectedEvent].folders) {
        const foundFolder = eventsData[selectedEvent].folders.find(
          (folder: Folder) => folder.folder_name === selectedFolderName,
        );

        if (foundFolder) {
          foundSongs = foundFolder.songs;
        }
      }
      setSelectedTracks(foundSongs);
      return folderEventId;
    });
  };

  return (
    <>
      {smplIntegrated ? (
        <>
          <IonAccordionGroup
            onIonChange={(e) => setOpenedEventId(e.detail.value)}
          >
            {smplItems.map((event, index) => {
              return (
                <IonAccordion value={`${event.event_id}`} key={index}>
                  <IonItem slot="header" color="light">
                    <IonLabel>
                      {event.event_title}{' '}
                      {eventsData[event.event_id] &&
                        `(${eventsData[event.event_id].totalTracks} Tracks)`}
                    </IonLabel>
                  </IonItem>
                  <div className="ion-padding" slot="content">
                    {eventsData[event.event_id] ? (
                      <>
                        {eventsData[event.event_id].folders
                          ? eventsData[event.event_id].folders.map(
                              (folder: Folder) => {
                                const uniqueId = `${event.event_id}-${folder.folder_name}-${event.event_title}`;
                                return (
                                  <IonItem
                                    onClick={() => handleClickFolder(uniqueId)}
                                    className={`smpl-folder ${
                                      selectedFolderUniqueId === uniqueId
                                        ? 'selected'
                                        : ''
                                    }`}
                                  >{`${folder.folder_name} (${folder.songs.length} tracks)`}</IonItem>
                                );
                              },
                            )
                          : `No tracks found for ${event.event_title}`}
                      </>
                    ) : (
                      <IonItem lines="none">
                        <IonSpinner name="dots"></IonSpinner>
                        <IonLabel>Loading event data...</IonLabel>
                      </IonItem>
                    )}
                  </div>
                </IonAccordion>
              );
            })}
          </IonAccordionGroup>

          <BuildGenerateCrate
            buildCrateFn={buildCrateFn}
            isBtnDisabled={selectedTracks.length === 0}
          />
        </>
      ) : (
        <>
          <div className={buildStyle['build-header']}>
            <div>Let's Integrate SMPL</div>
            <small>
              Head over to your SMPL url and get your API Key and URL from the
              Admin {'>'} API page
            </small>
          </div>

          <IonInput
            className={buildStyle['build-input']}
            placeholder="SMPL URL"
            fill="outline"
            value={smplURL}
            type="url"
            onIonChange={(e: any) => handleURLUpdate(e)}
          />

          <IonInput
            className={buildStyle['build-input']}
            placeholder="SMPL API KEY"
            fill="outline"
            value={smplAPIKey}
            type="password"
            onIonChange={(e: any) => setSmplAPIKey(e.target.value)}
          />

          <IonButton
            disabled={!urlIsValid}
            size="default"
            onClick={integrateSMPL}
          >
            Integrate SMPL
          </IonButton>

          {integrationError && (
            <div>
              <IonText color="danger">
                ERROR: {integrationError.toUpperCase()}
              </IonText>
            </div>
          )}

          {!urlIsValid && (
            <IonText color="danger">
              <p>Please enter a valid SMPL system URL.</p>
            </IonText>
          )}
        </>
      )}
    </>
  );
}
