import store from '../store/store';
import { config } from '../config';
import { AppIdentifiers } from '../store/slices/token-state.slice';

export class SmplService {
  public static async integrateSMPL(
    api_key: string,
    url: string,
  ): Promise<any> {
    const appToken = store.getState().tokenState[AppIdentifiers.App];
    const response = await fetch(`${config.API_BASE_URL}/smpl/integrate`, {
      method: 'POST',
      body: JSON.stringify({ api_key: api_key, url: url }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${appToken.accessToken}`,
      },
    });
    return await response.json();
  }

  public static async getSMPLIntegration(): Promise<any> {
    const appToken = store.getState().tokenState[AppIdentifiers.App];
    const response = await fetch(`${config.API_BASE_URL}/smpl/integration`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${appToken.accessToken}`,
      },
    });
    return await response.json();
  }

  public static async getSMPLEvents(): Promise<any> {
    const appToken = store.getState().tokenState[AppIdentifiers.App];
    const response = await fetch(`${config.API_BASE_URL}/smpl/events`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${appToken.accessToken}`,
      },
    });
    return await response.json();
  }

  public static async getSMPLEvent(id: number): Promise<any> {
    const appToken = store.getState().tokenState[AppIdentifiers.App];
    const response = await fetch(`${config.API_BASE_URL}/smpl/event/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${appToken.accessToken}`,
      },
    });
    console.log(response);
    return await response.json();
  }
}
