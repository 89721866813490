import React from 'react';
import style from './folder-flattener.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

type FlattenProps = {
  response: any;
};

export default function Flatten({ response }: FlattenProps) {
  const theme = useSelector((state: RootState) => state.themeState.theme);
  const {
    state,
    message,
    totalProcessedFiles,
    totalProcessedSize,
    failedFiles,
  } = response;

  const columnDefs = [
    { headerName: 'File Path', field: 'current_path', flex: 1 },
    { headerName: 'Error Message', field: 'error', flex: 1 },
  ];

  return (
    <>
      <h2>Flatten Operation Summary</h2>
      <p>{message}</p>
      <p>
        Processed Files: {totalProcessedFiles} ({totalProcessedSize})
      </p>

      {failedFiles && failedFiles.length > 0 && (
        <>
          <h3>Files that failed to {response.action}</h3>
          <div
            className="ag-theme-alpine"
            style={{ height: 400, width: '100%' }}
          >
            <AgGridReact
              className={`ag-theme-alpine${theme === 'dark' ? '-dark' : ''} ${
                style['grid']
              }`}
              rowData={failedFiles}
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={{ resizable: true }}
              rowSelection="single" // Options - allows click selection of rows
              suppressScrollOnNewData={true}
            />
          </div>
        </>
      )}
    </>
  );
}
