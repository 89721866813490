import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iCrate } from '../../types/ICrate';
import { iTrack } from '../../types/ITrack';

interface CrateState {
  editable: boolean;
  selectedCrate: iCrate | null;
  createdFrom: string;
  draft: boolean;
}

const initialState: CrateState = {
  editable: false,
  selectedCrate: null,
  createdFrom: '',
  draft: false,
};

export const CrateSlice = createSlice({
  name: 'crate',
  initialState,
  reducers: {
    setSelectedCrate: (state, action: PayloadAction<iCrate>) => {
      state.selectedCrate = action.payload;
    },

    setCrateEditable: (state, action: PayloadAction<boolean>) => {
      state.editable = action.payload;
    },

    setTracks: (state, action: PayloadAction<iTrack[]>) => {
      if (state.selectedCrate) {
        state.selectedCrate.Tracks = action.payload;
      } else {
        state.selectedCrate = {
          // TODO: Framework for saving draft later
          Tracks: action.payload,
          Title: 'Draft Crate',
          _id: 'draft',
        };
      }
    },

    setSelectedCrateName: (state, action: PayloadAction<string>) => {
      if (state.selectedCrate) {
        state.selectedCrate.Title = action.payload;
      } else {
        state.selectedCrate = {
          _id: 'draft',
          Title: action.payload,
        };
      }
    },

    setCreatedFrom: (state, action: PayloadAction<string>) => {
      state.createdFrom = action.payload;
    },

    clearCrate: (state) => {
      state.selectedCrate = null;
      state.editable = false;
      state.createdFrom = '';
    },
  },
});

export const {
  setSelectedCrate,
  setCrateEditable,
  setCreatedFrom,
  clearCrate,
  setSelectedCrateName,
} = CrateSlice.actions;

export const selectSelectedCrate = (state: { crate: CrateState }) =>
  state.crate.selectedCrate;

export const selectCrateEditable = (state: { crate: CrateState }) =>
  state.crate.editable;

export const selectCreatedFrom = (state: { crate: CrateState }) =>
  state.crate.createdFrom;

export const selectCrateName = (state: { crate: CrateState }) =>
  state.crate.selectedCrate?.Title;

export default CrateSlice.reducer;
