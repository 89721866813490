import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRouterLink,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CrateService } from '../../../services/Crate.service';
import { DateUtilsService } from '../../../services/DateUtils.service';
import { iCrate } from '../../../types/ICrate';
import '../Crates.scss';
import { useHistory } from 'react-router-dom';

interface iCratesProps {
  categories: string[];
  limit?: number;
  sectionHeader?: string;
  crateData?: iCrate[];
  onItemClicked?: () => void;
}

export const CrateGrid: React.FC<iCratesProps> = ({
  categories = [],
  limit = 0,
  sectionHeader = '',
  crateData = [],
  onItemClicked,
}) => {
  const [crates, setCrates] = useState<iCrate[]>();
  const history = useHistory();

  useEffect(() => {
    setCrates(crateData);
  }, [crateData]);

  useEffect(() => {
    if (crateData.length > 0) {
      setCrates(crateData);
    } else {
      CrateService.fetchSelectedCrates(
        categories,
        (data) => {
          console.log('crate data:', data);
          if (data.length > 0) {
            if (limit > 0) {
              const limitedCrates = [];
              for (let i = 0; i < limit; i++) {
                limitedCrates.push(data[i]);
              }
              setCrates(limitedCrates);
            } else {
              setCrates(data);
            }
          }
        },
        limit,
        history,
      );
    }
  }, [limit]);

  return (
    <div className={'crate-grid'}>
      {limit !== 0 && (
        <IonText color="dark">
          <h2>{sectionHeader}</h2>
        </IonText>
      )}
      <IonGrid>
        <IonRow>
          {!crates && (
            <IonCol size="12" size-md="12" size-lg="12">
              <IonCard className="crate-grid-card loading">
                <IonSpinner />
                <IonCardHeader>
                  <IonCardTitle>Loading...</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
          )}
          {crates &&
            crates.length > 0 &&
            crates.map((crate) => (
              <IonCol
                size="6"
                size-xl="2.4"
                size-lg="3"
                size-md="4"
                size-sm="4"
              >
                <IonRouterLink routerLink={'/crate/' + crate?._id}>
                  <IonCard
                    onClick={onItemClicked}
                    data-playlistId={crate?.['Playlist ID']}
                    className="crate-grid-card"
                  >
                    <div className="crate-grid-card-image">
                      <img
                        className="crate-card-image"
                        alt={crate.Title}
                        src={
                          'https:' +
                          (crate?.Image || '').replace(/^https?:/, '')
                        }
                      />
                    </div>
                    <IonCardHeader>
                      <IonCardTitle>{crate.Title}</IonCardTitle>
                      <IonCardSubtitle>
                        {crate.Published &&
                          DateUtilsService.formatDate(crate.Published)}
                      </IonCardSubtitle>
                      {/*<IonCardSubtitle>Exclusive</IonCardSubtitle>*/}
                    </IonCardHeader>
                  </IonCard>
                </IonRouterLink>
              </IonCol>
            ))}
        </IonRow>
      </IonGrid>
    </div>
  );
};
