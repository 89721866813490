/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrackAvailabilityOptionDtoFromJSON, TrackAvailabilityOptionDtoToJSON, } from './TrackAvailabilityOptionDto';
/**
 * Check if a given object implements the TrackAvailabilityOptionsDto interface.
 */
export function instanceOfTrackAvailabilityOptionsDto(value) {
    if (!('streaming' in value) || value['streaming'] === undefined)
        return false;
    if (!('purchases' in value) || value['purchases'] === undefined)
        return false;
    if (!('cloudStorage' in value) || value['cloudStorage'] === undefined)
        return false;
    if (!('resources' in value) || value['resources'] === undefined)
        return false;
    return true;
}
export function TrackAvailabilityOptionsDtoFromJSON(json) {
    return TrackAvailabilityOptionsDtoFromJSONTyped(json, false);
}
export function TrackAvailabilityOptionsDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'streaming': (json['streaming'].map(TrackAvailabilityOptionDtoFromJSON)),
        'purchases': (json['purchases'].map(TrackAvailabilityOptionDtoFromJSON)),
        'cloudStorage': (json['cloudStorage'].map(TrackAvailabilityOptionDtoFromJSON)),
        'resources': (json['resources'].map(TrackAvailabilityOptionDtoFromJSON)),
    };
}
export function TrackAvailabilityOptionsDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'streaming': (value['streaming'].map(TrackAvailabilityOptionDtoToJSON)),
        'purchases': (value['purchases'].map(TrackAvailabilityOptionDtoToJSON)),
        'cloudStorage': (value['cloudStorage'].map(TrackAvailabilityOptionDtoToJSON)),
        'resources': (value['resources'].map(TrackAvailabilityOptionDtoToJSON)),
    };
}
