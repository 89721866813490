import dayjs from 'dayjs';
import { iCrate } from '../types/ICrate';
import RequestService from './Request.service';
import { History } from 'history';
import { config } from '../config';

interface ApiResponse {
  count: number;
  cursor?: number; // the cursor can be optional if not always present
  remaining: number;
  results: any[]; // if you can type the results further, replace `any[]` with the more specific type
}

export class CrateService {
  static CRATE_STANDARD = 'standard';
  static CRATE_HACKATHON = 'hackathon';
  static CRATE_MARKETPLACE = 'marketplace';
  static MY_CRATES = 'user';

  private static crateEndpoints: { [key: string]: string } = {
    [CrateService.CRATE_STANDARD]: 'crates/standard',
    [CrateService.CRATE_HACKATHON]: 'crates/hackathon',
    [CrateService.CRATE_MARKETPLACE]: 'crates/marketplace',
    [CrateService.MY_CRATES]: 'crates/my-crates',
  };

  public static async fetchCrate(
    id: string,
    callback: (data: iCrate) => void,
    history?: History,
  ) {
    const url = `${config.API_BASE_URL}/crates/${id}`;
    const data = await RequestService.fetch({ url, history });
    return callback(data);
  }

  public static async fetchCratesByType(
    crateType: string,
    callback: (data: any) => void,
    limit?: number,
    history?: History,
  ) {
    if (CrateService.crateEndpoints[crateType]) {
      const data = await CrateService.fetchCratesFromEndpoint(
        CrateService.crateEndpoints[crateType],
        limit,
        history,
      );
      return callback(data);
    }
    throw new Error(`Invalid crate type: ${crateType}`);
  }

  public static async fetchSelectedCrates(
    crateTypes: string[],
    callback: (data: any[]) => void,
    limit?: number,
    history?: History,
  ) {
    const fetchPromises = crateTypes.map((crateType) =>
      CrateService.fetchCratesByType(crateType, (d) => d, limit, history),
    );

    const results = await Promise.all(fetchPromises);
    const combinedResults = results.flat();
    callback(combinedResults);
  }

  public static async createMyCrate(crateData: iCrate) {
    // Destructure the _id property out and capture the rest of crateData
    const { _id, ...dataWithoutId } = crateData;
    dataWithoutId.Type = 'User'; // TODO: This is bad because the UI makes data decisions
    const url = `${config.API_BASE_URL}/crates`;
    const method = 'POST';
    const body = dataWithoutId;
    return await RequestService.fetch({
      url,
      method,
      body,
    });
  }

  // TODO: Change to followed crates
  public static fetchFollowedCratePosts(
    callback: (data: any) => void,
    id?: string,
  ) {
    this.fetchSelectedCrates(
      [CrateService.CRATE_STANDARD, CrateService.CRATE_HACKATHON],
      (data) => {
        const cratePostsWithType = data.map((crate) => ({
          ...crate,
          postType: 'crate',
        }));
        callback(cratePostsWithType);
      },
      50,
    );
  }

  private static async fetchCratesFromEndpoint(
    endpoint: string,
    limit?: number,
    history?: History,
  ): Promise<any[]> {
    let results: any[] = [];
    let cursor = 0;
    let counter = 0;

    while (cursor !== undefined) {
      const url = `${config.API_BASE_URL}/${endpoint}`;
      const method = 'GET';
      const data: ApiResponse = await RequestService.fetch({
        url,
        method,
        cursor,
        limit,
        history,
      }).catch((error: any) => {
        console.log(error);
      });

      if (data.results) {
        results = results.concat(data.results);
        counter += data.results.length;
      }

      if (data.cursor) {
        cursor = cursor + data.count; // Update the cursor value based on the API response
      }

      if (limit && counter >= limit) {
        results = results.slice(0, limit);
        break;
      }

      if (!data.remaining || data.remaining === 0) {
        break;
      }
    }

    return results;
  }

  private static async fetchStandardCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_STANDARD],
    );
  }

  private static async fetchHackathonCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_HACKATHON],
    );
  }

  private static async fetchMarketplaceCrates(): Promise<any[]> {
    return CrateService.fetchCratesFromEndpoint(
      CrateService.crateEndpoints[CrateService.CRATE_MARKETPLACE],
    );
  }
}
