/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CrateCategoryDto interface.
 */
export function instanceOfCrateCategoryDto(value) {
    if (!('category' in value) || value['category'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
export function CrateCategoryDtoFromJSON(json) {
    return CrateCategoryDtoFromJSONTyped(json, false);
}
export function CrateCategoryDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'category': json['Category'],
        'id': json['_id'],
        'cursor': json['cursor'] == null ? undefined : json['cursor'],
        'archived': json['archived'] == null ? undefined : json['archived'],
    };
}
export function CrateCategoryDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'Category': value['category'],
        '_id': value['id'],
        'cursor': value['cursor'],
        'archived': value['archived'],
    };
}
