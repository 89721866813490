import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { hammerOutline } from 'ionicons/icons';
import React from 'react';
import style from './FeatureInProgress.module.scss';

const FeatureInProgress: React.FC = () => {
  return (
    <div className={style['feature-in-progress-container']}>
      <IonCard className={style['feature-in-progress-card']}>
        <IonCardHeader>
          <IonCardTitle>Feature in Progress</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <div className={style['icon-container']}>
            <IonIcon icon={hammerOutline} size="large" />
          </div>
          <IonLabel>
            Thanks for your interest! This feature is still being worked on.
            Stay tuned for updates.
          </IonLabel>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default FeatureInProgress;
