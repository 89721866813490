import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonToast,
  IonToggle,
} from '@ionic/react';
import { run } from '../../../helper/electron';
import './Export.scss';
import buildStyle from '../../myCrates/build-paths/build-path.module.scss';
import {
  checkmarkOutline,
  folderOpenOutline,
  warningOutline,
} from 'ionicons/icons';
import VideoPopover from '../../videoPopover/VideoPopover';
import { exportPdfUtil } from '../../../utils/exportPdf';
import { iTrack } from '../../../types/ITrack';
import LoginButton from '../../spotify/login-button';
import SpotifyService from '../../../services/Spotify.service';
import {
  instructionsDenon,
  instructionsDjay,
  instructionsRekordbox,
  instructionsSerato,
} from './Instructions';
import isElectron from '../../../utils/isElectron';

interface FilePathMap {
  [trackId: string]: string[];
}

interface ICloudCrateLink {
  name: string;
  link: string;
}

interface ExportProps {
  crateName: string;
  tracks: FilePathMap;
  trackInfo: iTrack[];
  hacker: string;
  cloudCrateList: ICloudCrateLink[];
  crateId?: string;
  forceShowExport?: boolean;
}

interface Toast {
  id: number;
  header: string;
  message: string;
  success: boolean;
}

const Export: React.FC<ExportProps> = ({
  crateName,
  tracks,
  hacker,
  trackInfo,
  cloudCrateList,
  crateId,
  forceShowExport = false,
}) => {
  // We dont use this on non-desktop
  if (!isElectron()) {
    return <></>;
  }

  const [exportVirtualDJ, setExportVirtualDJ] = useState<boolean>(false);
  const [exportM3U, setExportM3U] = useState<boolean>(false);
  const [exportSpotify, setExportSpotify] = useState<boolean>(false);
  const [exportTidal, setExportTidal] = useState<boolean>(false);
  const [exportCrateName, setExportCrateName] = useState<string>('');
  const [exportPdf, setExportPdf] = useState<boolean>(false);
  const [m3uPath, setM3UPath] = useState<string>('');
  const [toastQueue, setToastQueue] = useState<Toast[]>([]);
  const [currentToast, setCurrentToast] = useState<Toast | null>(null);
  const [showExportAlert, setShowExportAlert] =
    useState<boolean>(forceShowExport);
  const filesToExport = Object.values(tracks).flat();
  const [spotifyExporting, setSpotifyExporting] = useState<boolean>(false);
  const [djayPopoverOpen, setDjayPopoverOpen] = useState<boolean>(false);
  const [denonPopoverOpen, setDenonPopoverOpen] = useState<boolean>(false);
  const [rekordboxPopoverOpen, setRekordboxPopoverOpen] =
    useState<boolean>(false);
  const [seratoPopoverOpen, setSeratoPopoverOpen] = useState<boolean>(false);

  const addToast = (header: string, message: string, success: boolean) => {
    const newToast: Toast = {
      id: Date.now() + Math.random(),
      header,
      message,
      success,
    };
    setToastQueue((prevQueue) => [...prevQueue, newToast]);
  };

  useEffect(() => {
    if (!currentToast && toastQueue.length > 0) {
      setCurrentToast(toastQueue[0]);
      setToastQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [toastQueue, currentToast]);

  const handleDismissToast = () => {
    setCurrentToast(null);
  };

  interface Directory {
    path: string; // Directory path
    files: string[]; // Array of file names or paths
    dirSize: number; // Total size of the directory in bytes
  }

  const selectDirectory = (pathType: string) => {
    const setPath = (folder: Directory[]) => {
      if (!folder || !folder[0] || !folder[0].path) {
        return;
      }
      const path = folder[0].path;
      if (pathType === 'm3u') {
        setM3UPath(path);
        localStorage.setItem('export_m3uPath', path);
      }
    };

    const newListener = window.api?.once('open-directory-dialog', setPath);
    run('open-directory-dialog', {
      options: ['openDirectory', 'createDirectory', 'promptToCreate'],
    });

    return () => {
      window.api.removeListener('open-directory-dialog', newListener);
    };
  };

  useEffect(() => {
    // Load Toggles
    setExportVirtualDJ(
      JSON.parse(localStorage.getItem('export_virtualDJ') || 'false'),
    );
    setExportM3U(JSON.parse(localStorage.getItem('export_m3u') || 'false'));
    setExportSpotify(
      JSON.parse(localStorage.getItem('export_spotify') || 'false'),
    );
    setExportTidal(JSON.parse(localStorage.getItem('export_tidal') || 'false'));

    setExportPdf(JSON.parse(localStorage.getItem('export_pdf') || 'false'));

    // Load Paths
    const loadedM3UPath = localStorage.getItem('export_m3uPath');
    if (loadedM3UPath) setM3UPath(loadedM3UPath);
  }, []);

  useEffect(() => {
    setExportCrateName(crateName);
  }, [crateName]);

  useEffect(() => {
    localStorage.setItem('export_virtualDJ', JSON.stringify(exportVirtualDJ));
    localStorage.setItem('export_m3u', JSON.stringify(exportM3U));
    localStorage.setItem('export_spotify', JSON.stringify(exportSpotify));
    localStorage.setItem('export_tidal', JSON.stringify(exportTidal));
    localStorage.setItem('export_pdf', JSON.stringify(exportPdf));
  }, [exportVirtualDJ, exportM3U, exportSpotify, exportTidal, exportPdf]);

  const promptForPath = (type: string) => {
    addToast(
      'Cannot Export',
      `Please set the ${type} path before exporting`,
      false,
    );
  };

  const promptSpotifyLogin = () => {
    addToast(
      'Cannot Export',
      `Please log in with Spotify before exporting`,
      false,
    );
  };

  const handleResult = (
    success: boolean,
    exportMethod: string,
    error?: string,
    successfulTracks?: iTrack[] | undefined,
    failedTracks?: iTrack[] | undefined,
  ) => {
    if (success) {
      addToast(
        'Crate Exported!',
        `${successfulTracks ? successfulTracks.length : filesToExport.length} tracks from this crate have been exported to ${exportMethod}`,
        true,
      );
    } else {
      if (exportMethod === 'Spotify' && error?.includes('Reauthorization')) {
        addToast(
          'Cannot Export',
          'Crate failed to export to Spotify. Please log out and log back in to try and fix this issue. If the problem persists, please contact support.',
          false,
        );
      } else {
        addToast(
          'Cannot Export',
          `Crate failed to export to ${exportMethod}.${error ? ` ${error}.` : ''} Try restarting your app, and contact support if problem persists.`,
          false,
        );
      }
    }
  };

  const handleExport = async () => {
    const exportMethods = [];
    if (exportTidal) exportMethods.push('Tidal');
    if (exportVirtualDJ && filesToExport && filesToExport.length != 0) {
      exportMethods.push('VirtualDJ');
    }
    if (exportM3U && filesToExport && filesToExport.length != 0) {
      exportMethods.push('M3U');
    }
    if (exportSpotify) exportMethods.push('Spotify');
    if (exportPdf) exportMethods.push('PDF');

    if (!exportMethods.length) {
      addToast(
        'Cannot Export',
        'Please select at least one export option',
        false,
      );
      return;
    }

    if (exportM3U && !m3uPath) {
      promptForPath('m3u');
      return;
    }

    if (exportSpotify && !SpotifyService.getAccessToken()) {
      promptSpotifyLogin();
      return;
    }

    for (const exportMethod of exportMethods) {
      let path = '';
      if (exportMethod === 'M3U') {
        path = m3uPath;
      }

      const methodElectronString = exportMethod.toLowerCase();
      const resultEventName = `exportCrate:${methodElectronString}`;

      // Handle special cases first
      if (methodElectronString === 'pdf') {
        try {
          exportPdfUtil(exportCrateName, trackInfo, hacker, cloudCrateList);
          handleResult(true, exportMethod);
        } catch (error) {
          handleResult(false, exportMethod);
        }
        continue;
      }

      if (methodElectronString === 'spotify') {
        setSpotifyExporting(true);
        try {
          const result = await SpotifyService.ExportCrateToSpotify(
            exportCrateName,
            trackInfo,
          );
          handleResult(
            result.success,
            exportMethod,
            result.success
              ? undefined
              : `${result.failedTracks.length} tracks failed to export`,
            result.successTracks,
            result.failedTracks,
          );
        } catch (error: any) {
          handleResult(false, exportMethod, error.message as string);
        } finally {
          setSpotifyExporting(false);
        }
        continue;
      }

      // Handle Electron-based exports
      try {
        // Define the listener function
        const listener = (result: any) => {
          handleResult(result?.success, exportMethod);
          // // Remove the listener using the same function reference
          // if (window?.api?.removeListener) {
          //   window.api.removeListener(resultEventName, listener);
          // }
        };

        // Add the listener
        if (window?.api?.on) {
          window.api.on(resultEventName, listener);
        }

        // Trigger the export
        run('exportCrate', {
          method: methodElectronString,
          title: exportCrateName,
          tracks: filesToExport,
          path: path,
        });
      } catch (error) {
        handleResult(
          false,
          exportMethod,
          error instanceof Error ? error.message : 'Export failed',
        );
      }
    }

    setShowExportAlert(false);
  };

  return (
    <>
      {/*TODO: Add a "Open Folder" button for some export types, like M3U*/}
      {currentToast && (
        <IonToast
          cssClass={'ch-toast'}
          isOpen={true}
          onDidDismiss={handleDismissToast}
          message={currentToast.message}
          color={currentToast.success ? 'success' : 'danger'}
          position="middle"
          icon={currentToast.success ? checkmarkOutline : warningOutline}
          header={currentToast.header}
          buttons={['Close']}
        />
      )}
      <IonButton onClick={() => setShowExportAlert(true)}>Export</IonButton>
      <IonModal
        className="export-modal"
        isOpen={showExportAlert}
        onDidDismiss={() => setShowExportAlert(false)}
      >
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className={'export-header'}>
                <div>Export Crate</div>
              </div>
              <IonInput
                className={buildStyle['build-input']}
                labelPlacement="floating"
                fill="outline"
                value={exportCrateName}
                onIonInput={(e: any) => {
                  setExportCrateName(e.detail.value!);
                }}
              >
                <div slot="label">Crate Name</div>
              </IonInput>
            </IonCol>
          </IonRow>

          {!filesToExport ||
            (filesToExport.length === 0 && (
              <IonRow style={{ padding: '20px' }}>
                <IonCol size="1">
                  <IonIcon
                    color="warning"
                    src={warningOutline}
                    style={{ fontSize: '2.2rem' }}
                  />
                </IonCol>
                <IonCol size="11">
                  <IonText color="warning">
                    No track matches have been selected. Please select the files
                    found on your computer for each track to use local file
                    exports.
                  </IonText>
                </IonCol>
              </IonRow>
            ))}

          <IonRow>
            {[
              {
                label: 'M3U',
                checked: exportM3U,
                onToggle: setExportM3U,
                button: true,
                path: 'm3u',
                selectedPath: m3uPath,
                disabled: filesToExport.length === 0 || !filesToExport,
              },
              {
                label: 'VirtualDJ',
                checked: exportVirtualDJ,
                onToggle: setExportVirtualDJ,
                button: false,
                path: '',
                disabled: filesToExport.length === 0 || !filesToExport,
              },
              {
                label: 'PDF',
                checked: exportPdf,
                onToggle: setExportPdf,
                path: 'pdf',
              },
              {
                label: 'Spotify',
                checked: exportSpotify,
                onToggle: setExportSpotify,
                disabled: false,
                running: spotifyExporting,
                login: (
                  <LoginButton
                    small={true}
                    redirectPath={`/crate/${crateId}/export`}
                  />
                ),
              },
              // {
              //   label: 'Tidal',
              //   checked: exportTidal,
              //   onToggle: setExportTidal,
              //   disabled: true,
              // },
            ].map(
              ({
                label,
                checked,
                onToggle,
                button,
                path,
                selectedPath,
                disabled = false,
                login = null,
                running = false,
              }) => (
                <IonCol size="12" key={label}>
                  <IonItem lines="none" className="ion-align-items-center">
                    <IonGrid>
                      <IonRow className="ion-align-items-center">
                        <IonCol size="5.5" className={'export-method-label'}>
                          {label === 'M3U' ? (
                            <>
                              {'Export to M3U, '}
                              <span
                                className={'popover-link'}
                                onClick={() => setDenonPopoverOpen(true)}
                              >
                                Denon Engine
                              </span>
                              {', '}
                              <span
                                className={'popover-link'}
                                onClick={() => setDjayPopoverOpen(true)}
                              >
                                Djay
                              </span>
                              {', '}
                              <span
                                className={'popover-link'}
                                onClick={() => setRekordboxPopoverOpen(true)}
                              >
                                Rekordbox
                              </span>
                              {', or '}
                              <span
                                className={'popover-link'}
                                onClick={() => setSeratoPopoverOpen(true)}
                              >
                                Serato
                              </span>
                            </>
                          ) : (
                            <IonLabel>{`Export to ${label}`}</IonLabel>
                          )}
                        </IonCol>
                        {!disabled && (
                          <IonCol size="1.5">
                            <IonToggle
                              checked={checked}
                              disabled={disabled}
                              onIonChange={(e) => onToggle(e.detail.checked)}
                            />
                          </IonCol>
                        )}
                        {disabled && (
                          <IonCol size="6">
                            Select local files to use this method
                          </IonCol>
                        )}
                        {button && checked && !disabled && (
                          <IonCol size="5" class="ion-text-center">
                            <IonButton
                              onClick={() => selectDirectory(path)}
                              fill="outline"
                              expand="block"
                              className="select-directory-button"
                            >
                              <IonIcon icon={folderOpenOutline} slot="start" />
                              Select {label} Path
                            </IonButton>
                          </IonCol>
                        )}
                        {login && checked && !disabled && !running && (
                          <IonCol size="5" class="ion-text-center">
                            {login}
                          </IonCol>
                        )}

                        {running && (
                          <IonCol size="5" className="ion-text-right">
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <IonSpinner />
                              <div>Exporting to {label}...</div>
                            </div>
                          </IonCol>
                        )}
                      </IonRow>
                      {selectedPath && checked && (
                        <IonRow>
                          <IonCol size="12" className="ion-padding-start">
                            <small>Selected Path: {selectedPath}</small>
                          </IonCol>
                        </IonRow>
                      )}
                    </IonGrid>
                  </IonItem>
                </IonCol>
              ),
            )}
          </IonRow>

          {/* Centered Export and Cancel Buttons */}
          <IonRow
            className="ion-justify-content-center ion-margin-top"
            style={{ marginTop: '20px' }}
          >
            <IonCol size="auto">
              <IonButton
                expand="block"
                className="mapping-button"
                color="light"
                onClick={() => setShowExportAlert(false)}
              >
                Cancel
              </IonButton>
            </IonCol>
            <IonCol size="auto" offset={'1'}>
              <IonButton color="primary" onClick={handleExport}>
                Export
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
      <VideoPopover
        isOpen={djayPopoverOpen}
        onDidDismiss={() => setDjayPopoverOpen(false)}
        videoSrc="./video/Export to djay.mp4"
        title={'How to Export to Djay'}
        content={instructionsDjay}
      />{' '}
      <VideoPopover
        isOpen={denonPopoverOpen}
        onDidDismiss={() => setDenonPopoverOpen(false)}
        videoSrc="./video/Export to Denon.mp4"
        title={'How to Export to Denon Engine'}
        content={instructionsDenon}
      />
      <VideoPopover
        isOpen={rekordboxPopoverOpen}
        onDidDismiss={() => setRekordboxPopoverOpen(false)}
        videoSrc="./video/Export to Rekordbox.mp4"
        title={'How to Export to Rekordbox'}
        content={instructionsRekordbox}
      />
      <VideoPopover
        isOpen={seratoPopoverOpen}
        onDidDismiss={() => setSeratoPopoverOpen(false)}
        videoSrc="./video/Export to Serato.mp4"
        title={'How to Export to Serato'}
        content={instructionsSerato}
      />
    </>
  );
};

export default Export;
