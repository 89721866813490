/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TrackSearchDtoFromJSON, TrackSearchDtoToJSON, } from './TrackSearchDto';
/**
 * Check if a given object implements the PaginatedTrackResponse interface.
 */
export function instanceOfPaginatedTrackResponse(value) {
    if (!('total' in value) || value['total'] === undefined)
        return false;
    if (!('offset' in value) || value['offset'] === undefined)
        return false;
    if (!('limit' in value) || value['limit'] === undefined)
        return false;
    if (!('items' in value) || value['items'] === undefined)
        return false;
    return true;
}
export function PaginatedTrackResponseFromJSON(json) {
    return PaginatedTrackResponseFromJSONTyped(json, false);
}
export function PaginatedTrackResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'total': json['total'],
        'offset': json['offset'],
        'limit': json['limit'],
        'items': (json['items'].map(TrackSearchDtoFromJSON)),
    };
}
export function PaginatedTrackResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'total': value['total'],
        'offset': value['offset'],
        'limit': value['limit'],
        'items': (value['items'].map(TrackSearchDtoToJSON)),
    };
}
