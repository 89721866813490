import React, { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonInput,
  IonButton,
  IonToast,
  IonIcon,
  IonRow,
  IonCol,
  IonGrid,
  IonTextarea,
} from '@ionic/react';
import {
  bugOutline,
  checkmarkCircleOutline,
  refreshCircle,
} from 'ionicons/icons'; // Import the checkmark and refresh icons
import ApiService from '../../services/Api.service';
import { FeedbackControllerReportBugRequest } from '@cratehackers/api-client';
import feedbackStyles from './feedback.module.scss';
import style from '../folder-flattener/folder-flattener.module.scss';
import isElectron from '../../utils/isElectron';
import { run } from '../../helper/electron';
import { config } from '../../config';

const BugReportPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // State for showing the checkmark
  const [description, setDescription] = useState('');
  const [os, setOs] = useState('');
  const [osVersion, setOsVersion] = useState('');
  const [osArch, setOsArch] = useState('');
  const [appVersion, setAppVersion] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastColour, setToastColour] = useState('');
  const apiClient = ApiService.getApiClient();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const bugReportRequest: FeedbackControllerReportBugRequest = {
        bugReportDto: {
          description,
          os,
          osVersion,
          osArch,
          appVersion,
        },
      };

      await apiClient.feedbackControllerReportBug(bugReportRequest);

      setShowSuccess(true); // Show success checkmark
      setTimeout(() => setShowSuccess(false), 10000); // Hide checkmark after 10 seconds
      clearForm(); // Clear the form
      setToastMessage(
        'Bug report has been sent! Thank you for helping make CrateHackersX better!',
      );
      setToastColour('success');
      setShowToast(true); // Show success toast
    } catch (error) {
      console.error('Error submitting bug report:', error);
      setToastMessage('An error occurred. Please try again later.');
      setToastColour('danger');
      setShowToast(true); // Show danger toast
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setDescription('');
  };

  useEffect(() => {
    if (isElectron()) {
      // Fetch App Version
      const appVersionListener = window.api?.on(
        'getAppVersion',
        (response: string) => {
          if (response) {
            setAppVersion(response);
          } else {
            console.error('App Version is not defined.');
          }
        },
      );
      run('getAppVersion');

      // Fetch OS information
      const osListener = window.api?.on(
        'getOSInfo',
        (response: { os: string; osVersion: string; osArch: string }) => {
          if (response) {
            setOs(response.os);
            setOsVersion(response.osVersion);
            setOsArch(response.osArch);
          } else {
            console.error('OS information is not defined.');
          }
        },
      );
      run('getOSInfo');

      // Cleanup listeners when component unmounts
      return () => {
        window.api?.removeListener('getAppVersion', appVersionListener);
        window.api?.removeListener('getOSInfo', osListener);
      };
    } else {
      // Set fallback values for non-Electron environments
      setAppVersion(config.VERSION || 'unknown');
      setOs('unknown');
      setOsVersion('unknown');
      setOsArch('unknown');
    }
  }, []);

  return (
    <IonPage className={feedbackStyles['page']}>
      <IonContent fullscreen>
        <IonGrid className={feedbackStyles['grid']}>
          <IonRow className={feedbackStyles['header-row']}>
            <IonCol>
              <div className={style['page-header']}>
                <h1 className={style['title']}>
                  <IonIcon icon={bugOutline} style={{ marginRight: '10px' }} />
                  Report A Bug
                </h1>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={feedbackStyles['form-row']}>
            <IonCol size="12" sizeMd="6" offsetMd="0">
              {isElectron() && (
                <>
                  <div className={feedbackStyles['form-field']}>
                    <IonInput
                      className={feedbackStyles['input']}
                      labelPlacement="floating"
                      fill="outline"
                      value={os}
                      readonly
                      onIonChange={(e) => setOs(e.detail.value!)}
                      required
                    >
                      <div slot="label">Operating System</div>
                    </IonInput>
                  </div>
                  <div className={feedbackStyles['form-field']}>
                    <IonInput
                      className={feedbackStyles['input']}
                      labelPlacement="floating"
                      fill="outline"
                      value={osVersion}
                      readonly
                      onIonChange={(e) => setOsVersion(e.detail.value!)}
                      required
                    >
                      <div slot="label">OS Version</div>
                    </IonInput>
                  </div>
                  <div className={feedbackStyles['form-field']}>
                    <IonInput
                      className={feedbackStyles['input']}
                      labelPlacement="floating"
                      fill="outline"
                      value={osArch}
                      readonly
                      onIonChange={(e) => setOsArch(e.detail.value!)}
                      required
                    >
                      <div slot="label">OS Platform</div>
                    </IonInput>
                  </div>
                </>
              )}
              <div className={feedbackStyles['form-field']}>
                <IonInput
                  className={feedbackStyles['input']}
                  labelPlacement="floating"
                  fill="outline"
                  value={appVersion}
                  readonly
                  onIonChange={(e) => setAppVersion(e.detail.value!)}
                  required
                >
                  <div slot="label">App Version</div>
                </IonInput>
              </div>
              <div className={feedbackStyles['form-field']}>
                <IonTextarea
                  className={feedbackStyles['input']}
                  labelPlacement="floating"
                  fill="outline"
                  autoGrow={true}
                  value={description}
                  onIonChange={(e) => setDescription(e.detail.value!)}
                  required
                >
                  <div slot="label">
                    Describe the bug you experienced. Be as descriptive as
                    possible, it will help us fix the bug.
                  </div>
                </IonTextarea>
              </div>
              <IonButton
                expand="block"
                onClick={handleSubmit}
                disabled={isLoading} // Disable button while loading
                className={feedbackStyles['submit-button']}
              >
                {isLoading ? (
                  <IonIcon slot="start" icon={refreshCircle} />
                ) : showSuccess ? (
                  <IonIcon
                    slot="start"
                    icon={checkmarkCircleOutline}
                    color="success"
                  />
                ) : (
                  'Submit Bug Report'
                )}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        {showToast && toastMessage && toastColour && (
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={10000} // 10 seconds
            color={toastColour}
            position="top"
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default BugReportPage;
