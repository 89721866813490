/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PoolTrackDataDto interface.
 */
export function instanceOfPoolTrackDataDto(value) {
    if (!('pool' in value) || value['pool'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('artist' in value) || value['artist'] === undefined)
        return false;
    if (!('additionalData' in value) || value['additionalData'] === undefined)
        return false;
    return true;
}
export function PoolTrackDataDtoFromJSON(json) {
    return PoolTrackDataDtoFromJSONTyped(json, false);
}
export function PoolTrackDataDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'pool': json['pool'],
        'title': json['title'],
        'artist': json['artist'],
        'additionalData': json['additionalData'],
    };
}
export function PoolTrackDataDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'pool': value['pool'],
        'title': value['title'],
        'artist': value['artist'],
        'additionalData': value['additionalData'],
    };
}
