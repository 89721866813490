/* tslint:disable */
/* eslint-disable */
/**
 * CrateHackers API
 * This is the thing that does data stuff.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Keys interface.
 */
export function instanceOfKeys(value) {
    if (!('camelot' in value) || value['camelot'] === undefined)
        return false;
    if (!('standard' in value) || value['standard'] === undefined)
        return false;
    if (!('relative' in value) || value['relative'] === undefined)
        return false;
    if (!('perfectMatch' in value) || value['perfectMatch'] === undefined)
        return false;
    if (!('energyBoost' in value) || value['energyBoost'] === undefined)
        return false;
    if (!('energyDrop' in value) || value['energyDrop'] === undefined)
        return false;
    if (!('moodChange' in value) || value['moodChange'] === undefined)
        return false;
    if (!('compatible' in value) || value['compatible'] === undefined)
        return false;
    return true;
}
export function KeysFromJSON(json) {
    return KeysFromJSONTyped(json, false);
}
export function KeysFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'camelot': json['Camelot'],
        'standard': json['Standard'],
        'relative': json['Relative'],
        'perfectMatch': json['PerfectMatch'],
        'energyBoost': json['EnergyBoost'],
        'energyDrop': json['EnergyDrop'],
        'moodChange': json['MoodChange'],
        'compatible': json['Compatible'],
    };
}
export function KeysToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'Camelot': value['camelot'],
        'Standard': value['standard'],
        'Relative': value['relative'],
        'PerfectMatch': value['perfectMatch'],
        'EnergyBoost': value['energyBoost'],
        'EnergyDrop': value['energyDrop'],
        'MoodChange': value['moodChange'],
        'Compatible': value['compatible'],
    };
}
